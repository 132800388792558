export default ([
  {
    title: 'Young Hare',
    artist: 'Albrecht Dürer',
    year: 1502
  },
  {
    title: 'Great Piece of Turf',
    artist: 'Albrecht Dürer',
    year: 1503
  },
  {
    title: 'Feast of the Rosary',
    artist: 'Albrecht Dürer',
    year: 1506
  },
  {
    title: 'Adam and Eve',
    artist: 'Albrecht Dürer',
    year: 1507
  },
  {
    title: 'Praying Hands',
    artist: 'Albrecht Dürer',
    year: 1508
  },
  {
    title: 'Saint Jerome',
    artist: 'Albrecht Dürer',
    year: 1514
  },
  {
    title: "Dürer's Rhinoceros",
    artist: 'Albrecht Dürer',
    year: 1515
  },
  {
    title: 'The Four Apostles',
    artist: 'Albrecht Dürer',
    year: 1526
  },
  {
    title: 'Madonna and Child with the Pear',
    artist: 'Albrecht Dürer',
    year: 1526
  },
  {
    title: 'Winter Fields',
    artist: 'Andrew Wyeth',
    year: 1942
  },
  {
    title: 'Winter 1946',
    artist: 'Andrew Wyeth',
    year: 1946
  },
  {
    title: 'Wind from the Sea',
    artist: 'Andrew Wyeth',
    year: 1947
  },
  {
    title: "Christina's World",
    artist: 'Andrew Wyeth',
    year: 1948
  },
  {
    title: 'November First',
    artist: 'Andrew Wyeth',
    year: 1950
  },
  {
    title: 'Trodden Weed',
    artist: 'Andrew Wyeth',
    year: 1951
  },
  {
    title: 'That Gentleman',
    artist: 'Andrew Wyeth',
    year: 1962
  },
  {
    title: 'Evening at Kuerners',
    artist: 'Andrew Wyeth',
    year: 1970
  },
  {
    title: 'Airborne',
    artist: 'Andrew Wyeth',
    year: 1996
  },
  {
    title: 'Marilyn Diptych',
    artist: 'Andy Warhol',
    year: 1962
  },
  {
    title: 'Dollar Sign',
    artist: 'Andy Warhol',
    year: 1962
  },
  {
    title: "Campbell's Soup Cans",
    artist: 'Andy Warhol',
    year: 1962
  },
  {
    title: '100 Cans',
    artist: 'Andy Warhol',
    year: 1962
  },
  {
    title: 'Ten Lizes',
    artist: 'Andy Warhol',
    year: 1963
  },
  {
    title: 'Eight Elvises',
    artist: 'Andy Warhol',
    year: 1963
  },
  {
    title: 'Liz Taylor',
    artist: 'Andy Warhol',
    year: 1964
  },
  {
    title: 'Shot Marilyns',
    artist: 'Andy Warhol',
    year: 1964
  },
  {
    title: "Campbell's Soup I",
    artist: 'Andy Warhol',
    year: 1968
  },
  {
    title: 'The Cardsharps',
    artist: 'Caravaggio',
    year: 1594
  },
  {
    title: 'The Fortune Teller',
    artist: 'Caravaggio',
    year: 1594
  },
  {
    title: 'The Musicians',
    artist: 'Caravaggio',
    year: 1595
  },
  {
    title: 'Judith Beheading Holofernes',
    artist: 'Caravaggio',
    year: 1599
  },
  {
    title: 'Narcissus',
    artist: 'Caravaggio',
    year: 1599
  },
  {
    title: 'The Calling of St Matthew',
    artist: 'Caravaggio',
    year: 1600
  },
  {
    title: 'Supper at Emmaus',
    artist: 'Caravaggio',
    year: 1601
  },
  {
    title: 'The Taking of Christ',
    artist: 'Caravaggio',
    year: 1602
  },
  {
    title: 'Saint Jerome Writing',
    artist: 'Caravaggio',
    year: 1606
  },
  {
    title: 'The Beheading of Saint John the Baptist',
    artist: 'Caravaggio',
    year: 1608
  },
  {
    title: 'The Monk by the Sea',
    artist: 'Caspar David Friedrich',
    year: 1810
  },
  {
    title: 'The Abbey in the Oakwood',
    artist: 'Caspar David Friedrich',
    year: 1810
  },
  {
    title: 'Winter Landscape',
    artist: 'Caspar David Friedrich',
    year: 1811
  },
  {
    title: 'Two Men by the Sea',
    artist: 'Caspar David Friedrich',
    year: 1817
  },
  {
    title: 'Wanderer Above the Sea of Fog',
    artist: 'Caspar David Friedrich',
    year: 1818
  },
  {
    title: 'Moonrise Over the Sea',
    artist: 'Caspar David Friedrich',
    year: 1822
  },
  {
    title: 'The Lonely Tree',
    artist: 'Caspar David Friedrich',
    year: 1822
  },
  {
    title: 'The Sea of Ice',
    artist: 'Caspar David Friedrich',
    year: 1824
  },
  {
    title: 'The Watzmann',
    artist: 'Caspar David Friedrich',
    year: 1825
  },
  {
    title: 'The Stages of Life',
    artist: 'Caspar David Friedrich',
    year: 1835
  },
  {
    title: 'Women in the Garden',
    artist: 'Claude Monet',
    year: 1866
  },
  {
    title: 'The Magpie',
    artist: 'Claude Monet',
    year: 1869
  },
  {
    title: 'The Beach at Trouville',
    artist: 'Claude Monet',
    year: 1870
  },
  {
    title: 'Impression, Sunrise',
    artist: 'Claude Monet',
    year: 1872
  },
  {
    title: 'Regattas at Argenteuil',
    artist: 'Claude Monet',
    year: 1872
  },
  {
    title: 'Boulevard des Capucines',
    artist: 'Claude Monet',
    year: 1873
  },
  {
    title: 'Poppies',
    artist: 'Claude Monet',
    year: 1873
  },
  {
    title: 'The Lunch',
    artist: 'Claude Monet',
    year: 1873
  },
  {
    title: 'Sailboat at Le Petit-Gennevilliers',
    artist: 'Claude Monet',
    year: 1874
  },
  {
    title: 'The Woman with a Parasol',
    artist: 'Claude Monet',
    year: 1875
  },
  {
    title: 'Beach in Pourville',
    artist: 'Claude Monet',
    year: 1882
  },
  {
    title: 'The Japanese Footbridge',
    artist: 'Claude Monet',
    year: 1899
  },
  {
    title: 'San Giorgio Maggiore',
    artist: 'Claude Monet',
    year: 1912
  },
  {
    title: 'Water Lillies',
    artist: 'Claude Monet',
    year: 1919
  },
  {
    title: 'The Three Musicians',
    artist: 'Diego Velázquez',
    year: 1618
  },
  {
    title: 'The Kitchen Maid',
    artist: 'Diego Velázquez',
    year: 1618
  },
  {
    title: 'The Triumph of Bacchus',
    artist: 'Diego Velázquez',
    year: 1628
  },
  {
    title: 'Portrait of a Man',
    artist: 'Diego Velázquez',
    year: 1630
  },
  {
    title: 'Prince Balthasar Carlos',
    artist: 'Diego Velázquez',
    year: 1635
  },
  {
    title: 'The Needlewoman',
    artist: 'Diego Velázquez',
    year: 1643
  },
  {
    title: 'Rokeby Venus',
    artist: 'Diego Velázquez',
    year: 1647
  },
  {
    title: 'Las Meninas',
    artist: 'Diego Velázquez',
    year: 1656
  },
  {
    title: 'Portrait of Innocent X',
    artist: 'Diego Velázquez',
    year: 1650
  },
  {
    title: 'Juan de Pareja',
    artist: 'Diego Velázquez',
    year: 1650
  },
  {
    title: 'The Absinthe Drinker',
    artist: 'Édouard Manet',
    year: 1859
  },
  {
    title: 'A Bar at the Foiles Bergere',
    artist: 'Édouard Manet',
    year: 1862
  },
  {
    title: 'Olympia',
    artist: 'Édouard Manet',
    year: 1863
  },
  {
    title: 'The Luncheon on the Grass',
    artist: 'Édouard Manet',
    year: 1863
  },
  {
    title: 'The Fifer',
    artist: 'Édouard Manet',
    year: 1866
  },
  {
    title: 'The Balcony',
    artist: 'Édouard Manet',
    year: 1868
  },
  {
    title: 'The Railway',
    artist: 'Édouard Manet',
    year: 1873
  },
  {
    title: 'Boating',
    artist: 'Édouard Manet',
    year: 1874
  },
  {
    title: 'Nana',
    artist: 'Édouard Manet',
    year: 1877
  },
  {
    title: 'The Sick Child',
    artist: 'Edvard Munch',
    year: 1886
  },
  {
    title: 'Melancholy',
    artist: 'Edvard Munch',
    year: 1892
  },
  {
    title: 'The Scream',
    artist: 'Edvard Munch',
    year: 1893
  },
  {
    title: 'Anxiety',
    artist: 'Edvard Munch',
    year: 1894
  },
  {
    title: 'Madonna',
    artist: 'Edvard Munch',
    year: 1894
  },
  {
    title: 'Vampire',
    artist: 'Edvard Munch',
    year: 1895
  },
  {
    title: 'Puberty',
    artist: 'Edvard Munch',
    year: 1895
  },
  {
    title: 'Separation',
    artist: 'Edvard Munch',
    year: 1896
  },
  {
    title: 'Near the Bed of Death',
    artist: 'Edvard Munch',
    year: 1896
  },
  {
    title: 'The Kiss',
    artist: 'Edvard Munch',
    year: 1897
  },
  {
    title: 'Girls on a Bridge',
    artist: 'Edvard Munch',
    year: 1900
  },
  {
    title: 'Automat',
    artist: 'Edward Hopper',
    year: 1927
  },
  {
    title: 'Two on the Aisle',
    artist: 'Edward Hopper',
    year: 1927
  },
  {
    title: 'Chop Suey',
    artist: 'Edward Hopper',
    year: 1929
  },
  {
    title: 'Early Sunday Morning',
    artist: 'Edward Hopper',
    year: 1930
  },
  {
    title: 'Ground Swell',
    artist: 'Edward Hopper',
    year: 1939
  },
  {
    title: 'Gas',
    artist: 'Edward Hopper',
    year: 1940
  },
  {
    title: 'Office at Night',
    artist: 'Edward Hopper',
    year: 1940
  },
  {
    title: 'Nighthawks',
    artist: 'Edward Hopper',
    year: 1942
  },
  {
    title: 'Summertime',
    artist: 'Edward Hopper',
    year: 1943
  },
  {
    title: 'Summer Evening',
    artist: 'Edward Hopper',
    year: 1947
  },
  {
    title: 'Sunlight in a Cafeteria',
    artist: 'Edward Hopper',
    year: 1958
  },
  {
    title: 'People in the Sun',
    artist: 'Edward Hopper',
    year: 1960
  },
  {
    title: 'The Passage of the Delaware',
    artist: 'Emanuel Leutze',
    year: 1819
  },
  {
    title: 'Bird Nesting',
    artist: 'Emanuel Leutze',
    year: 1837
  },
  {
    title: 'Columbus Before the Queen',
    artist: 'Emanuel Leutze',
    year: 1843
  },
  {
    title: 'Washington Crossing the Delaware',
    artist: 'Emanuel Leutze',
    year: 1851
  },
  {
    title: 'Washington Rallying the Troops at Monmouth',
    artist: 'Emanuel Leutze',
    year: 1854
  },
  {
    title: 'The Summons',
    artist: 'Emanuel Leutze',
    year: 1856
  },
  {
    title: 'Worthington Whittredge',
    artist: 'Emanuel Leutze',
    year: 1856
  },
  {
    title: 'Westward the Course of Empire Takes its Way',
    artist: 'Emanuel Leutze',
    year: 1860
  },
  {
    title: 'Nathaniel Hawthorne',
    artist: 'Emanuel Leutze',
    year: 1862
  },
  {
    title: 'The Barque of Dante',
    artist: 'Eugene Delacroix',
    year: 1822
  },
  {
    title: 'The Massacre at Chios',
    artist: 'Eugene Delacroix',
    year: 1824
  },
  {
    title: 'The Death of Sarandapalus',
    artist: 'Eugene Delacroix',
    year: 1827
  },
  {
    title: 'Liberty Leading the People',
    artist: 'Eugene Delacroix',
    year: 1830
  },
  {
    title: 'Women of Algiers in their Apartment',
    artist: 'Eugene Delacroix',
    year: 1834
  },
  {
    title: 'Medea',
    artist: 'Eugene Delacroix',
    year: 1838
  },
  {
    title: 'The Shipwreck of Don Juan',
    artist: 'Eugene Delacroix',
    year: 1840
  },
  {
    title: 'Christ on the Sea of Galilee',
    artist: 'Eugene Delacroix',
    year: 1854
  },
  {
    title: 'Lion Hunt',
    artist: 'Eugene Delacroix',
    year: 1855
  },
  {
    title: 'Nudes in the Forest',
    artist: 'Fernand Léger',
    year: 1910
  },
  {
    title: 'The Wedding',
    artist: 'Fernand Léger',
    year: 1911
  },
  {
    title: 'Contrast of Forms',
    artist: 'Fernand Léger',
    year: 1913
  },
  {
    title: 'Soldiers Playing Cards',
    artist: 'Fernand Léger',
    year: 1917
  },
  {
    title: 'Propellers 2nd State',
    artist: 'Fernand Léger',
    year: 1918
  },
  {
    title: 'The City',
    artist: 'Fernand Léger',
    year: 1919
  },
  {
    title: 'Woman with a Cat',
    artist: 'Fernand Léger',
    year: 1921
  },
  {
    title: 'Leaves and Shell',
    artist: 'Fernand Léger',
    year: 1927
  },
  {
    title: 'Keys (Composition)',
    artist: 'Fernand Léger',
    year: 1928
  },
  {
    title: 'Composition with the Two Parrots',
    artist: 'Fernand Léger',
    year: 1939
  },
  {
    title: 'Three Studies for Figures at the Base of a Crucifixion',
    artist: 'Francis Bacon',
    year: 1944
  },
  {
    title: 'Figure Study II',
    artist: 'Francis Bacon',
    year: 1946
  },
  {
    title: 'Head I',
    artist: 'Francis Bacon',
    year: 1948
  },
  {
    title: 'Head IV',
    artist: 'Francis Bacon',
    year: 1949
  },
  {
    title: 'Pope II',
    artist: 'Francis Bacon',
    year: 1951
  },
  {
    title: 'Dog',
    artist: 'Francis Bacon',
    year: 1952
  },
  {
    title: 'Study for a Portrait',
    artist: 'Francis Bacon',
    year: 1952
  },
  {
    title: 'Study of a Dog',
    artist: 'Francis Bacon',
    year: 1954
  },
  {
    title: 'Study for Portrait II',
    artist: 'Francis Bacon',
    year: 1955
  },
  {
    title: 'Study from Innocent X',
    artist: 'Francis Bacon',
    year: 1962
  },
  {
    title: 'The Parasol',
    artist: 'Francisco Goya',
    year: 1777
  },
  {
    title: 'The Blind Guitar Player',
    artist: 'Francisco Goya',
    year: 1778
  },
  {
    title: 'The Snowstorm',
    artist: 'Francisco Goya',
    year: 1786
  },
  {
    title: 'The Naked Maja',
    artist: 'Francisco Goya',
    year: 1797
  },
  {
    title: "Witches' Sabbath",
    artist: 'Francisco Goya',
    year: 1798
  },
  {
    title: 'La Maja Desnuda',
    artist: 'Francisco Goya',
    year: 1800
  },
  {
    title: 'The Colossus',
    artist: 'Francisco Goya',
    year: 1812
  },
  {
    title: 'The Madhouse',
    artist: 'Francisco Goya',
    year: 1812
  },
  {
    title: 'The Third of May 1808',
    artist: 'Francisco Goya',
    year: 1814
  },
  {
    title: 'Saturn Devouring his Son',
    artist: 'Francisco Goya',
    year: 1823
  },
  {
    title: 'Shrovetide Revellers',
    artist: 'Frans Hals',
    year: 1617
  },
  {
    title: 'Laughing Cavalier',
    artist: 'Frans Hals',
    year: 1624
  },
  {
    title: 'Laughing Boy',
    artist: 'Frans Hals',
    year: 1625
  },
  {
    title: 'St Luke',
    artist: 'Frans Hals',
    year: 1625
  },
  {
    title: 'Young Man with a Skull',
    artist: 'Frans Hals',
    year: 1626
  },
  {
    title: 'The Gypsy Girl',
    artist: 'Frans Hals',
    year: 1628
  },
  {
    title: 'The Merry Drinker',
    artist: 'Frans Hals',
    year: 1630
  },
  {
    title: 'Malle Babbe',
    artist: 'Frans Hals',
    year: 1633
  },
  {
    title: 'Meagre Company',
    artist: 'Frans Hals',
    year: 1637
  },
  {
    title: 'The Andes of Ecuador',
    artist: 'Frederic Edwin Church',
    year: 1855
  },
  {
    title: 'Niagara',
    artist: 'Frederic Edwin Church',
    year: 1857
  },
  {
    title: 'The Heart of the Andes',
    artist: 'Frederic Edwin Church',
    year: 1859
  },
  {
    title: 'Twilight in the Wilderness',
    artist: 'Frederic Edwin Church',
    year: 1860
  },
  {
    title: 'The Icebergs',
    artist: 'Frederic Edwin Church',
    year: 1861
  },
  {
    title: 'Cotopaxi',
    artist: 'Frederic Edwin Church',
    year: 1862
  },
  {
    title: 'Chimborazo',
    artist: 'Frederic Edwin Church',
    year: 1864
  },
  {
    title: 'Aurora Borealis',
    artist: 'Frederic Edwin Church',
    year: 1865
  },
  {
    title: 'Pichincha',
    artist: 'Frederic Edwin Church',
    year: 1867
  },
  {
    title: 'El Rio de Luz',
    artist: 'Frederic Edwin Church',
    year: 1877
  },
  {
    title: 'Henry Ford Hospital',
    artist: 'Frida Khalo',
    year: 1932
  },
  {
    title: 'The Frame',
    artist: 'Frida Khalo',
    year: 1938
  },
  {
    title: 'The Suicide of Dorothy Hale',
    artist: 'Frida Kahlo',
    year: 1938
  },
  {
    title: 'The Two Fridas',
    artist: 'Frida Khalo',
    year: 1939
  },
  {
    title: 'The Wounded Table',
    artist: 'Frida Khalo',
    year: 1940
  },
  {
    title: 'Me and My Parrots',
    artist: 'Frida Khalo',
    year: 1941
  },
  {
    title: 'Self-Portrait with Monkeys',
    artist: 'Frida Kahlo',
    year: 1943
  },
  {
    title: 'Roots',
    artist: 'Frida Khalo',
    year: 1943
  },
  {
    title: 'The Broken Column',
    artist: 'Frida Kahlo',
    year: 1944
  },
  {
    title: 'Without Hope',
    artist: 'Frida Khalo',
    year: 1945
  },
  {
    title: 'The Wounded Dear',
    artist: 'Frida Khalo',
    year: 1946
  },
  {
    title: 'Fur Traders Descending the Missouri',
    artist: 'George Caleb Bingham',
    year: 1845
  },
  {
    title: 'Raftsmen Playing Cards',
    artist: 'George Caleb Bingham',
    year: 1847
  },
  {
    title: 'Mississippi Boatman',
    artist: 'George Caleb Bingham',
    year: 1850
  },
  {
    title: "The Trappers' Return",
    artist: 'George Caleb Bingham',
    year: 1851
  },
  {
    title: 'The County Election',
    artist: 'George Caleb Bingham',
    year: 1852
  },
  {
    title: 'The Verdict of the People',
    artist: 'George Caleb Bingham',
    year: 1854
  },
  {
    title: 'Order No. 11',
    artist: 'George Caleb Bingham',
    year: 1868
  },
  {
    title: 'Washington Crossing the Delaware',
    artist: 'George Caleb Bingham',
    year: 1871
  },
  {
    title: "View of Pike's Peak",
    artist: 'George Caleb Bingham',
    year: 1872
  },
  {
    title: 'Bathers at Asnieres',
    artist: 'Georges Seurat',
    year: 1884
  },
  {
    title: 'The Seine at Courbevoie',
    artist: 'Georges Seurat',
    year: 1884
  },
  {
    title: 'La Luzerne, Saint-Denis',
    artist: 'Georges Seurat',
    year: 1885
  },
  {
    title: 'A Sunday Afternoon on the Island of La Grande Jatte',
    artist: 'Georges Seurat',
    year: 1886
  },
  {
    title: 'The Mouth of the Seine at Honfleur',
    artist: 'Georges Seurat',
    year: 1886
  },
  {
    title: 'Circus Sideshow',
    artist: 'Georges Seurat',
    year: 1888
  },
  {
    title: 'Gray Weather, Grande Jatte',
    artist: 'Georges Seurat',
    year: 1888
  },
  {
    title: 'The River Seine at La Grande-Jatte',
    artist: 'Georges Seurat',
    year: 1888
  },
  {
    title: 'The Channel of Gravelines',
    artist: 'Georges Seurat',
    year: 1890
  },
  {
    title: 'Blue Wave Maine',
    artist: "Georgia O'Keeffe",
    year: 1887
  },
  {
    title: 'Blue Line',
    artist: "Georgia O'Keeffe",
    year: 1919
  },
  {
    title: 'Light of Iris',
    artist: "Georgia O'Keeffe",
    year: 1924
  },
  {
    title: 'Black Iris',
    artist: "Georgia O'Keeffe",
    year: 1926
  },
  {
    title: 'Red Cannas',
    artist: "Georgia O'Keeffe",
    year: 1927
  },
  {
    title: 'Oriental Poppies',
    artist: "Georgia O'Keeffe",
    year: 1928
  },
  {
    title: "Cow's Skull: Red, White, and Blue",
    artist: "Georgia O'Keeffe",
    year: 1931
  },
  {
    title: 'Jimson Weed',
    artist: "Georgia O'Keeffe",
    year: 1936
  },
  {
    title: 'Red Hills with Flowers',
    artist: "Georgia O'Keeffe",
    year: 1937
  },
  {
    title: 'Hibiscus with Plumeria',
    artist: "Georgia O'Keeffe",
    year: 1939
  },
  {
    title: 'Woman with Plants',
    artist: 'Grant Wood',
    year: 1929
  },
  {
    title: 'American Gothic',
    artist: 'Grant Wood',
    year: 1930
  },
  {
    title: 'Stone City, Iowa',
    artist: 'Grant Wood',
    year: 1930
  },
  {
    title: 'Young Corn',
    artist: 'Grant Wood',
    year: 1931
  },
  {
    title: 'Landscape',
    artist: 'Grant Wood',
    year: 1931
  },
  {
    title: 'Fall Plowing',
    artist: 'Grant Wood',
    year: 1931
  },
  {
    title: 'Arbor Day',
    artist: 'Grant Wood',
    year: 1932
  },
  {
    title: 'Spring in Town',
    artist: 'Grant Wood',
    year: 1941
  },
  {
    title: 'Spring in the Country',
    artist: 'Grant Wood',
    year: 1941
  },
  {
    title: 'Music',
    artist: 'Gustav Klimt',
    year: 1895
  },
  {
    title: 'Pallas Athene',
    artist: 'Gustav Klimt',
    year: 1898
  },
  {
    title: 'Hope I',
    artist: 'Gustav Klimt',
    year: 1903
  },
  {
    title: 'Portrait of Adele Bloch-Bauer I',
    artist: 'Gustav Klimt',
    year: 1907
  },
  {
    title: 'The Kiss',
    artist: 'Gustav Klimt',
    year: 1908
  },
  {
    title: 'Danaë',
    artist: 'Gustav Klimt',
    year: 1908
  },
  {
    title: 'Hope II',
    artist: 'Gustav Klimt',
    year: 1908
  },
  {
    title: 'Tree of Life',
    artist: 'Gustav Klimt',
    year: 1909
  },
  {
    title: 'The Maiden',
    artist: 'Gustav Klimt',
    year: 1913
  },
  {
    title: 'Death and Life',
    artist: 'Gustav Klimt',
    year: 1915
  },
  {
    title: 'Portrait of a Lady',
    artist: 'Gustav Klimt',
    year: 1917
  },
  {
    title: 'Lady with Fan',
    artist: 'Gustav Klimt',
    year: 1918
  },
  {
    title: 'Venus and Armor',
    artist: 'Hans Holbein the Younger',
    year: 1525
  },
  {
    title: 'Lais Corinthiaca',
    artist: 'Hans Holbein the Younger',
    year: 1526
  },
  {
    title: 'Sir Thomas More and Family',
    artist: 'Hans Holbein the Younger',
    year: 1527
  },
  {
    title: 'Portrait of Sir Thomas More',
    artist: 'Hans Holbein the Younger',
    year: 1527
  },
  {
    title: 'Darmstadt Madonna',
    artist: 'Hans Holbein the Younger',
    year: 1528
  },
  {
    title: 'Portrait of Georg Giese',
    artist: 'Hans Holbein the Younger',
    year: 1532
  },
  {
    title: 'The Ambassadors',
    artist: 'Hans Holbein the Younger',
    year: 1533
  },
  {
    title: 'An Allegory of the Old and New Testaments',
    artist: 'Hans Holbein the Younger',
    year: 1535
  },
  {
    title: 'Portrait of Henry VIII',
    artist: 'Hans Holbein the Younger',
    year: 1537
  },
  {
    title: 'Harmony in Red',
    artist: 'Henri Matisse',
    year: 1908
  },
  {
    title: 'Dance',
    artist: 'Henri Matisse',
    year: 1910
  },
  {
    title: 'Music',
    artist: 'Henri Matisse',
    year: 1910
  },
  {
    title: 'Goldfish',
    artist: 'Henri Matisse',
    year: 1911
  },
  {
    title: "L'Atelier Rouge",
    artist: 'Henri Matisse',
    year: 1911
  },
  {
    title: 'Goldfish and Sculpture',
    artist: 'Henri Matisse',
    year: 1912
  },
  {
    title: 'The Dance II',
    artist: 'Henri Matisse',
    year: 1932
  },
  {
    title: 'Large Reclining Nude',
    artist: 'Henri Matisse',
    year: 1935
  },
  {
    title: 'Blue Nudes',
    artist: 'Henri Matisse',
    year: 1952
  },
  {
    title: 'Blue Nude II',
    artist: 'Henri Matisse',
    year: 1952
  },
  {
    title: 'The Snail',
    artist: 'Henri Matisse',
    year: 1953
  },
  {
    title: 'Carnival Evening',
    artist: 'Henri Rousseau',
    year: 1886
  },
  {
    title: 'Self-Portrait',
    artist: 'Henri Rousseau',
    year: 1890
  },
  {
    title: 'Boy on the Rocks',
    artist: 'Henri Rousseau',
    year: 1895
  },
  {
    title: 'The Sleeping Gypsy',
    artist: 'Henri Rousseau',
    year: 1897
  },
  {
    title: 'Nude and Bear',
    artist: 'Henri Rousseau',
    year: 1901
  },
  {
    title: 'The Snake Charmer',
    artist: 'Henri Rousseau',
    year: 1907
  },
  {
    title: 'The Repast of the Lion',
    artist: 'Henri Rousseau',
    year: 1907
  },
  {
    title: 'The Dream',
    artist: 'Henri Rousseau',
    year: 1910
  },
  {
    title: 'Exotic Landscape',
    artist: 'Henri Rousseau',
    year: 1910
  },
  {
    title: 'The Last Judgment',
    artist: 'Hieronymus Bosch',
    year: 1482
  },
  {
    title: 'The Wayfarer',
    artist: 'Hieronymus Bosch',
    year: 1500
  },
  {
    title: 'Ship of Fools',
    artist: 'Hieronymus Bosch',
    year: 1500
  },
  {
    title: 'The Last Judgment',
    artist: 'Hieronymus Bosch',
    year: 1500
  },
  {
    title: 'Paradise and Hell',
    artist: 'Hieronymus Bosch',
    year: 1510
  },
  {
    title: 'The Garden of Earthly Delights',
    artist: 'Hieronymus Bosch',
    year: 1515
  },
  {
    title: 'The Haywain Triptych',
    artist: 'Hieronymus Bosch',
    year: 1516
  },
  {
    title: 'Death and the Miser',
    artist: 'Hieronymus Bosch',
    year: 1516
  },
  {
    title: 'Christ in Limbo',
    artist: 'Hieronymus Bosch',
    year: 1550
  },
  {
    title: 'Rainstorm Beneath the Summit',
    artist: 'Hokusai',
    year: 1823
  },
  {
    title: 'Cherry Blossoms and Warbler',
    artist: 'Hokusai',
    year: 1827
  },
  {
    title: 'The Great Wave off Kanagawa',
    artist: 'Hokusai',
    year: 1831
  },
  {
    title: 'Kirifuri Waterfall on Mount Kurokami',
    artist: 'Hokusai',
    year: 1831
  },
  {
    title: 'Fuji, Mountains in Clear Weather',
    artist: 'Hokusai',
    year: 1831
  },
  {
    title: 'Fine Wind, Clear Morning',
    artist: 'Hokusai',
    year: 1832
  },
  {
    title: 'Kajikazawa in Kai Province',
    artist: 'Hokusai',
    year: 1832
  },
  {
    title: 'The Waterwheel at Onden',
    artist: 'Hokusai',
    year: 1832
  },
  {
    title: 'Oceans of Wisdom',
    artist: 'Hokusai',
    year: 1834
  },
  {
    title: 'Wave',
    artist: 'Hokusai',
    year: 1849
  },
  {
    title: 'Fishermen at Sea',
    artist: 'J.M.W. Turner',
    year: 1796
  },
  {
    title: 'The Shipwreck',
    artist: 'J.M.W. Turner',
    year: 1805
  },
  {
    title: 'Dido Building Carthage',
    artist: 'J.M.W. Turner',
    year: 1815
  },
  {
    title: 'The Battle of Trafalgar',
    artist: 'J.M.W. Turner',
    year: 1824
  },
  {
    title: 'Chichester Canal',
    artist: 'J.M.W. Turner',
    year: 1828
  },
  {
    title: 'Fort Vimieux',
    artist: 'J.M.W. Turner',
    year: 1831
  },
  {
    title: 'Regulus',
    artist: 'J.M.W. Turner',
    year: 1837
  },
  {
    title: 'The Fighting Temeraire',
    artist: 'J.M.W. Turner',
    year: 1839
  },
  {
    title: 'Rain, Steam, and Speed',
    artist: 'J.M.W. Turner',
    year: 1844
  },
  {
    title: 'Mural',
    artist: 'Jackson Pollock',
    year: 1943
  },
  {
    title: 'Full Fathom Five',
    artist: 'Jackson Pollock',
    year: 1947
  },
  {
    title: 'Number 23',
    artist: 'Jackson Pollock',
    year: 1948
  },
  {
    title: 'Number 5',
    artist: 'Jackson Pollock',
    year: 1948
  },
  {
    title: 'Number 1',
    artist: 'Jackson Pollock',
    year: 1950
  },
  {
    title: 'Number 28',
    artist: 'Jackson Pollock',
    year: 1950
  },
  {
    title: 'Autumn Rhythm. Number 30, 1950',
    artist: 'Jackson Pollock',
    year: 1950
  },
  {
    title: 'Convergence',
    artist: 'Jackson Pollock',
    year: 1952
  },
  {
    title: 'Blue Poles',
    artist: 'Jackson Pollock',
    year: 1952
  },
  {
    title: 'Greyed Rainbow',
    artist: 'Jackson Pollock',
    year: 1953
  },
  {
    title: 'The Death of Seneca',
    artist: 'Jacques-Louis David',
    year: 1773
  },
  {
    title: 'Patroclus',
    artist: 'Jacques-Louis David',
    year: 1780
  },
  {
    title: 'Oath of Horatii',
    artist: 'Jacques-Louis David',
    year: 1784
  },
  {
    title: 'The Death of Socrates',
    artist: 'Jacques-Louis David',
    year: 1787
  },
  {
    title: 'The Lictors Bring to Brutus the Bodies of His Sons',
    artist: 'Jacques-Louis David',
    year: 1789
  },
  {
    title: 'The Tennis Court Oath',
    artist: 'Jacques-Louis David',
    year: 1791
  },
  {
    title: 'The Death of Marat',
    artist: 'Jacques-Louis David',
    year: 1793
  },
  {
    title: 'Napoleon Crossing the Alps',
    artist: 'Jacques-Louis David',
    year: 1805
  },
  {
    title: 'Sappho and Phaon',
    artist: 'Jacques-Louis David',
    year: 1809
  },
  {
    title: 'Love and Psyche',
    artist: 'Jacques-Louis David',
    year: 1817
  },
  {
    title: 'The Anger of Achilles',
    artist: 'Jacques-Louis David',
    year: 1819
  },
  {
    title: 'Rotherhithe',
    artist: 'James Abbott McNeill Whistler',
    year: 1860
  },
  {
    title: 'Wapping',
    artist: 'James Abbott McNeill Whistler',
    year: 1864
  },
  {
    title: "Whistler's Mother",
    artist: 'James Abbott McNeill Whistler',
    year: 1871
  },
  {
    title: 'Nocturne: Blue and Silver: Chelsea',
    artist: 'James Abbott McNeill Whistler',
    year: 1871
  },
  {
    title: 'Nocturne: Blue and Gold: Southampton Water',
    artist: 'James Abbott McNeill Whistler',
    year: 1872
  },
  {
    title: 'Nocturne in Black and Gold: The Falling Rocket',
    artist: 'James Abbott McNeill Whistler',
    year: 1875
  },
  {
    title: 'Nocturne: Blue and Gold: Old Battersea Bridge',
    artist: 'James Abbott McNeill Whistler',
    year: 1875
  },
  {
    title: 'The Peacock Room',
    artist: 'James Abbott McNeill Whistler',
    year: 1877
  },
  {
    title: 'Dordrecth',
    artist: 'James Abbott McNeill Whistler',
    year: 1886
  },
  {
    title: 'Adoration of the Lamb',
    artist: 'Jan van Eyck',
    year: 1429
  },
  {
    title: 'The Soldiers of Christ',
    artist: 'Jan van Eyck',
    year: 1430
  },
  {
    title: 'Leal Souvenir',
    artist: 'Jan van Eyck',
    year: 1432
  },
  {
    title: 'Ince Hall Madonna',
    artist: 'Jan van Eyck',
    year: 1433
  },
  {
    title: 'Arnolfini Portrait',
    artist: 'Jan van Eyck',
    year: 1434
  },
  {
    title: 'Annunciation',
    artist: 'Jan van Eyck',
    year: 1436
  },
  {
    title: 'Lucca Madonna',
    artist: 'Jan van Eyck',
    year: 1436
  },
  {
    title: 'Saint Barbara',
    artist: 'Jan van Eyck',
    year: 1437
  },
  {
    title: 'Madonna at the Fountain',
    artist: 'Jan van Eyck',
    year: 1439
  },
  {
    title: 'The Embarkation for Cythera',
    artist: 'Jean-Antoine Watteau',
    year: 1717
  },
  {
    title: 'The Scale of Love',
    artist: 'Jean-Antoine Watteau',
    year: 1717
  },
  {
    title: 'Les Plaisirs du Bal',
    artist: 'Jean-Antoine Watteau',
    year: 1717
  },
  {
    title: 'The Charmes of Life',
    artist: 'Jean-Antoine Watteau',
    year: 1718
  },
  {
    title: 'Gilles',
    artist: 'Jean-Antoine Watteau',
    year: 1718
  },
  {
    title: 'Pierrot',
    artist: 'Jean-Antoine Watteau',
    year: 1719
  },
  {
    title: 'The Pleasures of Love',
    artist: 'Jean-Antoine Watteau',
    year: 1719
  },
  {
    title: 'The Feast of Love',
    artist: 'Jean-Antoine Watteau',
    year: 1719
  },
  {
    title: 'Mezzetin',
    artist: 'Jean-Antoine Watteau',
    year: 1720
  },
  {
    title: 'The Italian Comedians',
    artist: 'Jean-Antoine Watteau',
    year: 1720
  },
  {
    title: 'The Musical Contest',
    artist: 'Jean-Honoré Fragonard',
    year: 1755
  },
  {
    title: 'The Bathers',
    artist: 'Jean-Honoré Fragonard',
    year: 1765
  },
  {
    title: 'The Swing',
    artist: 'Jean-Honoré Fragonard',
    year: 1767
  },
  {
    title: "Blind Man's Bluff",
    artist: 'Jean-Honoré Fragonard',
    year: 1769
  },
  {
    title: 'A Young Girl Reading',
    artist: 'Jean-Honoré Fragonard',
    year: 1770
  },
  {
    title: 'The Love Letter',
    artist: 'Jean-Honoré Fragonard',
    year: 1773
  },
  {
    title: "L'Amoire",
    artist: 'Jean-Honoré Fragonard',
    year: 1778
  },
  {
    title: 'The Lock',
    artist: 'Jean-Honoré Fragonard',
    year: 1779
  },
  {
    title: 'The Fountain of Love',
    artist: 'Jean-Honoré Fragonard',
    year: 1785
  },
  {
    title: 'The Procuress',
    artist: 'Johannes Vermeer',
    year: 1656
  },
  {
    title: 'The Milkmaid',
    artist: 'Johannes Vermeer',
    year: 1657
  },
  {
    title: 'The Little Street',
    artist: 'Johannes Vermeer',
    year: 1658
  },
  {
    title: 'The Glass of Wine',
    artist: 'Johannes Vermeer',
    year: 1660
  },
  {
    title: 'View of Delft',
    artist: 'Johannes Vermeer',
    year: 1661
  },
  {
    title: 'The Concert',
    artist: 'Johannes Vermeer',
    year: 1664
  },
  {
    title: 'The Girl with the Pearl Earring',
    artist: 'Johannes Vermeer',
    year: 1665
  },
  {
    title: 'The Art of Painting',
    artist: 'Johannes Vermeer',
    year: 1668
  },
  {
    title: 'The Astronomer',
    artist: 'Johannes Vermeer',
    year: 1668
  },
  {
    title: 'The Lacemaker',
    artist: 'Johannes Vermeer',
    year: 1670
  },
  {
    title: 'Stoke-by-Nayland',
    artist: 'John Constable',
    year: 1815
  },
  {
    title: 'Wivenhoe Park',
    artist: 'John Constable',
    year: 1816
  },
  {
    title: 'Stratford Mill',
    artist: 'John Constable',
    year: 1820
  },
  {
    title: 'The Hay Wain',
    artist: 'John Constable',
    year: 1821
  },
  {
    title: 'The Lock',
    artist: 'John Constable',
    year: 1824
  },
  {
    title: 'The Cornfield',
    artist: 'John Constable',
    year: 1826
  },
  {
    title: 'The Vale of Dedham',
    artist: 'John Constable',
    year: 1828
  },
  {
    title: 'Hadleigh Castle',
    artist: 'John Constable',
    year: 1829
  },
  {
    title: 'Cloud Study',
    artist: 'John Constable',
    year: 1835
  },
  {
    title: 'Head of a Capri Girl',
    artist: 'John Singer Sargent',
    year: 1878
  },
  {
    title: 'El Jaleo',
    artist: 'John Singer Sargent',
    year: 1882
  },
  {
    title: 'Spanish Dancer',
    artist: 'John Singer Sargent',
    year: 1882
  },
  {
    title: 'Portrait of Madame X',
    artist: 'John Singer Sargent',
    year: 1884
  },
  {
    title: 'Carnation, Lily, Lily, Rose',
    artist: 'John Singer Sargent',
    year: 1886
  },
  {
    title: 'Ellen Terry as Lady Macbeth',
    artist: 'John Singer Sargent',
    year: 1889
  },
  {
    title: 'Elsie Palmer',
    artist: 'John Singer Sargent',
    year: 1890
  },
  {
    title: 'Lady Agnew of Lochnaw',
    artist: 'John Singer Sargent',
    year: 1892
  },
  {
    title: 'Gassed',
    artist: 'John Singer Sargent',
    year: 1903
  },
  {
    title: 'Nonchaloir (Repose)',
    artist: 'John Singer Sargent',
    year: 1911
  },
  {
    title: 'Luna Park',
    artist: 'Joseph Stella',
    year: 1913
  },
  {
    title: 'Futurist Composition',
    artist: 'Joseph Stella',
    year: 1914
  },
  {
    title: 'Der Rosenkavalier',
    artist: 'Joseph Stella',
    year: 1914
  },
  {
    title: 'Spring (The Procession)',
    artist: 'Joseph Stella',
    year: 1916
  },
  {
    title: 'Brooklyn Bridge',
    artist: 'Joseph Stella',
    year: 1920
  },
  {
    title: 'The Red Hat',
    artist: 'Joseph Stella',
    year: 1924
  },
  {
    title: 'By Products Plants',
    artist: 'Joseph Stella',
    year: 1926
  },
  {
    title: 'Flowers, Italy',
    artist: 'Joseph Stella',
    year: 1931
  },
  {
    title: 'Metropolitan Port',
    artist: 'Joseph Stella',
    year: 1937
  },
  {
    title: 'Annunciation',
    artist: 'Leonardo da Vinci',
    year: 1472
  },
  {
    title: 'The Baptism of Christ',
    artist: 'Leonardo da Vinci',
    year: 1475
  },
  {
    title: 'Benois Madonna',
    artist: 'Leonardo da Vinci',
    year: 1478
  },
  {
    title: 'Adoration of the Magi',
    artist: 'Leonardo da Vinci',
    year: 1481
  },
  {
    title: 'Lady with an Ermine',
    artist: 'Leonardo da Vinci',
    year: 1489
  },
  {
    title: 'Vitruvian Man',
    artist: 'Leonardo da Vinci',
    year: 1490
  },
  {
    title: 'The Last Supper',
    artist: 'Leonardo da Vinci',
    year: 1498
  },
  {
    title: 'Salvator Mundi',
    artist: 'Leonardo da Vinci',
    year: 1500
  },
  {
    title: 'Mona Lisa',
    artist: 'Leonardo da Vinci',
    year: 1503
  },
  {
    title: 'Bacchus',
    artist: 'Leonardo da Vinci',
    year: 1515
  },
  {
    title: 'Paradis, Adam and Eve',
    artist: 'Marcel Duchamp',
    year: 1910
  },
  {
    title: 'Yvonne and Magdeleine Torn in Tatters',
    artist: 'Marcel Duchamp',
    year: 1911
  },
  {
    title: 'The Chess Players',
    artist: 'Marcel Duchamp',
    year: 1911
  },
  {
    title: 'Sad Young Man in a Train',
    artist: 'Marcel Duchamp',
    year: 1911
  },
  {
    title: 'Young Girl and Man in Spring',
    artist: 'Marcel Duchamp',
    year: 1911
  },
  {
    title: 'The Passage from Virgin to Bride',
    artist: 'Marcel Duchamp',
    year: 1912
  },
  {
    title: 'Nude Descending a Staircase, No. 2',
    artist: 'Marcel Duchamp',
    year: 1912
  },
  {
    title: 'L.H.O.O.Q.',
    artist: 'Marcel Duchamp',
    year: 1919
  },
  {
    title: 'The Tribute Money',
    artist: 'Masaccio',
    year: 1425
  },
  {
    title: 'Expulsion from the Garden of Eden',
    artist: 'Masaccio',
    year: 1425
  },
  {
    title: 'Distribution of Alms and Death of Ananias',
    artist: 'Masaccio',
    year: 1425
  },
  {
    title: 'Portrait of a Young Man',
    artist: 'Masaccio',
    year: 1425
  },
  {
    title: 'Crucifixion',
    artist: 'Masaccio',
    year: 1426
  },
  {
    title: 'Saint Andrew',
    artist: 'Masaccio',
    year: 1426
  },
  {
    title: 'St. Peter Healing the Sick with His Shadow',
    artist: 'Masaccio',
    year: 1427
  },
  {
    title: 'Adoration of the Magi',
    artist: 'Masaccio',
    year: 1428
  },
  {
    title: 'Holy Trinity',
    artist: 'Masaccio',
    year: 1428
  },
  {
    title: 'The Entombment',
    artist: 'Michelangelo',
    year: 1501
  },
  {
    title: 'Battle of Cascina',
    artist: 'Michelangelo',
    year: 1504
  },
  {
    title: 'Delphic Sibyl',
    artist: 'Michelangelo',
    year: 1509
  },
  {
    title: 'The Fall and Expulsion from Garden of Eden',
    artist: 'Michelangelo',
    year: 1510
  },
  {
    title: 'The Creation of Adam',
    artist: 'Michelangelo',
    year: 1512
  },
  {
    title: 'The Deluge',
    artist: 'Michelangelo',
    year: 1512
  },
  {
    title: 'Sistine Chapel Celing',
    artist: 'Michelangelo',
    year: 1512
  },
  {
    title: 'Venus and Cupid',
    artist: 'Michelangelo',
    year: 1533
  },
  {
    title: 'Last Judgment',
    artist: 'Michelangelo',
    year: 1541
  },
  {
    title: 'The Prophet Jonah',
    artist: 'Michelangelo',
    year: 1545
  },
  {
    title: 'The Crucifixion of St. Peter',
    artist: 'Michelangelo',
    year: 1550
  },
  {
    title: 'La Vie',
    artist: 'Pablo Picasso',
    year: 1903
  },
  {
    title: 'The Old Guitarist',
    artist: 'Pablo Picasso',
    year: 1904
  },
  {
    title: "Les Demoiselles d'Avignon",
    artist: 'Pablo Picasso',
    year: 1907
  },
  {
    title: 'Portrait of Ambroise Vollard',
    artist: 'Pablo Picasso',
    year: 1910
  },
  {
    title: 'Three Musicians',
    artist: 'Pablo Picasso',
    year: 1921
  },
  {
    title: 'The Kiss',
    artist: 'Pablo Picasso',
    year: 1925
  },
  {
    title: 'La Rêve',
    artist: 'Pablo Picasso',
    year: 1932
  },
  {
    title: 'Girl Before a Mirror',
    artist: 'Pablo Picasso',
    year: 1932
  },
  {
    title: 'Guernica',
    artist: 'Pablo Picasso',
    year: 1937
  },
  {
    title: 'The Weeping Woman',
    artist: 'Pablo Picasso',
    year: 1937
  },
  {
    title: 'Portrait of Dora Maar',
    artist: 'Pablo Picasso',
    year: 1937
  },
  {
    title: 'Women of Algiers (Version O)',
    artist: 'Pablo Picasso',
    year: 1955
  },
  {
    title: 'Crucifixion with Two Angels',
    artist: 'Paolo Uccello',
    year: 1423
  },
  {
    title: 'St. George Slaying the Dragon',
    artist: 'Paolo Uccello',
    year: 1432
  },
  {
    title: 'The Adoration of the Magi',
    artist: 'Paolo Uccello',
    year: 1435
  },
  {
    title: 'Head of Prophet',
    artist: 'Paolo Uccello',
    year: 1443
  },
  {
    title: 'Creation of Adam',
    artist: 'Paolo Uccello',
    year: 1445
  },
  {
    title: 'The Battle of San Romano',
    artist: 'Paolo Uccello',
    year: 1460
  },
  {
    title: 'The Adoration of the Child',
    artist: 'Paolo Uccello',
    year: 1463
  },
  {
    title: 'Saint George and the Dragon',
    artist: 'Paolo Uccello',
    year: 1470
  },
  {
    title: 'The Hunt in the Forest',
    artist: 'Paolo Uccello',
    year: 1470
  },
  {
    title: 'Avenue at Chantilly',
    artist: 'Paul Cézanne',
    year: 1888
  },
  {
    title: 'Pierrot and Harlequin',
    artist: 'Paul Cézanne',
    year: 1888
  },
  {
    title: 'Boy in a Red Vest',
    artist: 'Paul Cézanne',
    year: 1889
  },
  {
    title: 'Curtain, Jug, and Fruit',
    artist: 'Paul Cézanne',
    year: 1894
  },
  {
    title: 'The Basket of Apples',
    artist: 'Paul Cézanne',
    year: 1895
  },
  {
    title: 'The Card Players',
    artist: 'Paul Cézanne',
    year: 1892
  },
  {
    title: 'Apples and Oranges',
    artist: 'Paul Cézanne',
    year: 1900
  },
  {
    title: 'The Bathers',
    artist: 'Paul Cézanne',
    year: 1905
  },
  {
    title: 'Vision After the Sermon',
    artist: 'Paul Gauguin',
    year: 1888
  },
  {
    title: 'The Yellow Christ',
    artist: 'Paul Gauguin',
    year: 1889
  },
  {
    title: 'Tahitian Women on the Beach',
    artist: 'Paul Gauguin',
    year: 1891
  },
  {
    title: 'Hail Mary',
    artist: 'Paul Gauguin',
    year: 1891
  },
  {
    title: 'When Will You Marry?',
    artist: 'Paul Gauguin',
    year: 1892
  },
  {
    title: 'Arearea',
    artist: 'Paul Gauguin',
    year: 1892
  },
  {
    title: 'The Seed of Areoi',
    artist: 'Paul Gauguin',
    year: 1892
  },
  {
    title: 'The Siesta',
    artist: 'Paul Gauguin',
    year: 1893
  },
  {
    title: 'Day of the God',
    artist: 'Paul Gauguin',
    year: 1894
  },
  {
    title: 'Nevermore',
    artist: 'Paul Gauguin',
    year: 1897
  },
  {
    title: 'La Grenouillere',
    artist: 'Pierre-August Renoir',
    year: 1869
  },
  {
    title: 'The Skiff',
    artist: 'Pierre-August Renoir',
    year: 1875
  },
  {
    title: 'Bal du moulin de la Galette',
    artist: 'Pierre-August Renoir',
    year: 1876
  },
  {
    title: 'A Bouquet of Roses',
    artist: 'Pierre-August Renoir',
    year: 1879
  },
  {
    title: 'Luncheon of the Boating Party',
    artist: 'Pierre-August Renoir',
    year: 1881
  },
  {
    title: 'Dance at Bougival',
    artist: 'Pierre-August Renoir',
    year: 1883
  },
  {
    title: 'Dance in the Country',
    artist: 'Pierre-August Renoir',
    year: 1883
  },
  {
    title: 'Dance in the City',
    artist: 'Pierre-August Renoir',
    year: 1883
  },
  {
    title: 'The Umbrellas',
    artist: 'Pierre-August Renoir',
    year: 1886
  },
  {
    title: 'The Large Bathers',
    artist: 'Pierre-August Renoir',
    year: 1887
  },
  {
    title: 'Composition in Colour A',
    artist: 'Piet Mondrian',
    year: 1917
  },
  {
    title: 'Composition with Gray and Light Brown',
    artist: 'Piet Mondrian',
    year: 1918
  },
  {
    title: 'Composition A',
    artist: 'Piet Mondrian',
    year: 1923
  },
  {
    title: 'Composition No. III',
    artist: 'Piet Mondrian',
    year: 1929
  },
  {
    title: 'Composition with Red, Blue and Yellow',
    artist: 'Piet Mondrian',
    year: 1930
  },
  {
    title: 'New York City I',
    artist: 'Piet Mondrian',
    year: 1942
  },
  {
    title: 'Composition No. 10',
    artist: 'Piet Mondrian',
    year: 1942
  },
  {
    title: 'Broadway Boogie-Woogie',
    artist: 'Piet Mondrian',
    year: 1943
  },
  {
    title: 'Trafalgar Square',
    artist: 'Piet Mondrian',
    year: 1943
  },
  {
    title: 'Resurrection of Christ',
    artist: 'Raphael',
    year: 1502
  },
  {
    title: 'Vision of a Knight',
    artist: 'Raphael',
    year: 1504
  },
  {
    title: 'Madonna del Prato',
    artist: 'Raphael',
    year: 1506
  },
  {
    title: 'The Deposition',
    artist: 'Raphael',
    year: 1507
  },
  {
    title: 'Disputation of the Holy Sacrament',
    artist: 'Raphael',
    year: 1510
  },
  {
    title: 'The School of Athens',
    artist: 'Raphael',
    year: 1511
  },
  {
    title: 'The Sistine Madonna',
    artist: 'Raphael',
    year: 1512
  },
  {
    title: 'Portrait of a Young Man',
    artist: 'Raphael',
    year: 1514
  },
  {
    title: 'Transfiguration',
    artist: 'Raphael',
    year: 1520
  },
  {
    title: 'The Music Party',
    artist: 'Rembrandt',
    year: 1626
  },
  {
    title: 'The Raising of Lazarus',
    artist: 'Rembrandt',
    year: 1632
  },
  {
    title: 'The Anatomy Lesson of Dr. Nicolaes Tulp',
    artist: 'Rembrandt',
    year: 1632
  },
  {
    title: 'The Storm on the Sea of Galilee',
    artist: 'Rembrandt',
    year: 1633
  },
  {
    title: "Belshazzar's Feast",
    artist: 'Rembrandt',
    year: 1635
  },
  {
    title: 'Sacrifice of Isaac',
    artist: 'Rembrandt',
    year: 1635
  },
  {
    title: 'The Night Watch',
    artist: 'Rembrandt',
    year: 1642
  },
  {
    title: 'Bathsheba at Her Bath',
    artist: 'Rembrandt',
    year: 1654
  },
  {
    title: 'The Jewish Bride',
    artist: 'Rembrandt',
    year: 1665
  },
  {
    title: 'The Return of the Prodigal Son',
    artist: 'Rembrandt',
    year: 1665
  },
  {
    title: 'The Menaced Assassin',
    artist: 'René Magritte',
    year: 1927
  },
  {
    title: 'The Lovers',
    artist: 'René Magritte',
    year: 1928
  },
  {
    title: 'The False Mirror',
    artist: 'René Magritte',
    year: 1928
  },
  {
    title: 'The Treachery of Images',
    artist: 'René Magritte',
    year: 1929
  },
  {
    title: 'The Human Condition',
    artist: 'René Magritte',
    year: 1935
  },
  {
    title: 'Not to Be Reproduced',
    artist: 'René Magritte',
    year: 1937
  },
  {
    title: 'Time Transfixed',
    artist: 'René Magritte',
    year: 1938
  },
  {
    title: 'Personal Values',
    artist: 'René Magritte',
    year: 1952
  },
  {
    title: 'Golconda',
    artist: 'René Magritte',
    year: 1953
  },
  {
    title: 'The Son of Man',
    artist: 'René Magritte',
    year: 1964
  },
  {
    title: 'Man in a Bowler Hat',
    artist: 'René Magritte',
    year: 1964
  },
  {
    title: 'Twenty Golden Orbs',
    artist: 'Robert Indiana',
    year: 1959
  },
  {
    title: 'The American Dream, I',
    artist: 'Robert Indiana',
    year: 1961
  },
  {
    title: 'Fire Bridge',
    artist: 'Robert Indiana',
    year: 1965
  },
  {
    title: 'LOVE',
    artist: 'Robert Indiana',
    year: 1967
  },
  {
    title: 'The Garden of Love',
    artist: 'Robert Indiana',
    year: 1982
  },
  {
    title: 'Numbers 1-9 (2)',
    artist: 'Robert Indiana',
    year: 1983
  },
  {
    title: 'Amor',
    artist: 'Robert Indiana',
    year: 1984
  },
  {
    title: 'The Metamorphosis of Norma Jean',
    artist: 'Robert Indiana',
    year: 1998
  },
  {
    title: 'Picasso, The American Dream',
    artist: 'Robert Indiana',
    year: 1998
  },
  {
    title: 'Tivka',
    artist: 'Robert Indiana',
    year: 2011
  },
  {
    title: 'Mother of God',
    artist: 'Robert Rauschenberg',
    year: 1950
  },
  {
    title: 'Automobile Tire Print',
    artist: 'Robert Rauschenberg',
    year: 1953
  },
  {
    title: 'Untitled (Gold Painting)',
    artist: 'Robert Rauschenberg',
    year: 1953
  },
  {
    title: 'Collection',
    artist: 'Robert Rauschenberg',
    year: 1955
  },
  {
    title: 'Rebus',
    artist: 'Robert Rauschenberg',
    year: 1955
  },
  {
    title: 'Factum I',
    artist: 'Robert Rauschenberg',
    year: 1957
  },
  {
    title: 'Canyon',
    artist: 'Robert Rauschenberg',
    year: 1959
  },
  {
    title: 'Retroactive I',
    artist: 'Robert Rauschenberg',
    year: 1963
  },
  {
    title: 'Autobiography',
    artist: 'Robert Rauschenberg',
    year: 1968
  },
  {
    title: 'Factory Workers',
    artist: 'Romare Bearden',
    year: 1942
  },
  {
    title: 'Patchwork Quilt',
    artist: 'Romare Bearden',
    year: 1970
  },
  {
    title: 'The Block',
    artist: 'Romare Bearden',
    year: 1971
  },
  {
    title: 'Roots',
    artist: 'Romare Bearden',
    year: 1977
  },
  {
    title: 'Louisiana Serenade',
    artist: 'Romare Bearden',
    year: 1979
  },
  {
    title: 'Out Chorus',
    artist: 'Romare Bearden',
    year: 1980
  },
  {
    title: 'Mecklenburg Autumn',
    artist: 'Romare Bearden',
    year: 1980
  },
  {
    title: 'Pepper Jelly Lady',
    artist: 'Romare Bearden',
    year: 1980
  },
  {
    title: 'Piano Lesson',
    artist: 'Romare Bearden',
    year: 1983
  },
  {
    title: 'Cubist Self-Portrait',
    artist: 'Salvador Dalí',
    year: 1923
  },
  {
    title: 'The Great Masturbator',
    artist: 'Salvador Dalí',
    year: 1929
  },
  {
    title: 'The Enigma of Desire',
    artist: 'Salvador Dalí',
    year: 1929
  },
  {
    title: 'The Persistence of Memory',
    artist: 'Salvador Dalí',
    year: 1931
  },
  {
    title: 'Forgotten Horizon',
    artist: 'Salvador Dalí',
    year: 1936
  },
  {
    title: 'Burning Giraffe',
    artist: 'Salvador Dalí',
    year: 1937
  },
  {
    title: 'Geopoliticus Child Watching the Birth of the New Man',
    artist: 'Salvador Dalí',
    year: 1943
  },
  {
    title: 'The Elephants',
    artist: 'Salvador Dalí',
    year: 1948
  },
  {
    title: 'Galatea of the Spheres',
    artist: 'Salvador Dalí',
    year: 1952
  },
  {
    title: 'Living Still Life',
    artist: 'Salvador Dalí',
    year: 1956
  },
  {
    title: 'Lincoln in Dalivision',
    artist: 'Salvador Dalí',
    year: 1976
  },
  {
    title: 'Adoration of the Magi',
    artist: 'Sandro Botticelli',
    year: 1476
  },
  {
    title: 'Madonna of the Book',
    artist: 'Sandro Botticelli',
    year: 1480
  },
  {
    title: 'Primavera',
    artist: 'Sandro Botticelli',
    year: 1482
  },
  {
    title: 'Pallas and the Centaur',
    artist: 'Sandro Botticelli',
    year: 1482
  },
  {
    title: 'Venus and Mars',
    artist: 'Sandro Botticelli',
    year: 1483
  },
  {
    title: 'The Birth of Venus',
    artist: 'Sandro Botticelli',
    year: 1486
  },
  {
    title: 'Calumny of Apelles',
    artist: 'Sandro Botticelli',
    year: 1495
  },
  {
    title: 'The Mystical Nativity',
    artist: 'Sandro Botticelli',
    year: 1501
  },
  {
    title: 'The Story of Virginia',
    artist: 'Sandro Botticelli',
    year: 1504
  },
  {
    title: 'The Charging Chasseur',
    artist: 'Théodore Géricault',
    year: 1812
  },
  {
    title: 'The Wounded Cuirassier',
    artist: 'Théodore Géricault',
    year: 1814
  },
  {
    title: 'Boxers',
    artist: 'Théodore Géricault',
    year: 1818
  },
  {
    title: 'The Raft of the Medusa',
    artist: 'Théodore Géricault',
    year: 1819
  },
  {
    title: 'Anatomical Pieces',
    artist: 'Théodore Géricault',
    year: 1819
  },
  {
    title: 'Portrait Study',
    artist: 'Théodore Géricault',
    year: 1819
  },
  {
    title: 'Three Lovers',
    artist: 'Théodore Géricault',
    year: 1820
  },
  {
    title: 'Portrait of a Kleptomaniac',
    artist: 'Théodore Géricault',
    year: 1822
  },
  {
    title: 'Insane Woman',
    artist: 'Théodore Géricault',
    year: 1822
  },
  {
    title: 'Conversation in a Park',
    artist: 'Thomas Gainsborough',
    year: 1746
  },
  {
    title: 'Cornard Wood',
    artist: 'Thomas Gainsborough',
    year: 1748
  },
  {
    title: 'Mr. and Mrs. Robert Andrews',
    artist: 'Thomas Gainsborough',
    year: 1750
  },
  {
    title: 'John Plampin',
    artist: 'Thomas Gainsborough',
    year: 1755
  },
  {
    title: "The Painter's Daughter",
    artist: 'Thomas Gainsborough',
    year: 1756
  },
  {
    title: 'Lady Alston',
    artist: 'Thomas Gainsborough',
    year: 1762
  },
  {
    title: 'The Harvest Wagon',
    artist: 'Thomas Gainsborough',
    year: 1767
  },
  {
    title: 'The Blue Boy',
    artist: 'Thomas Gainsborough',
    year: 1770
  },
  {
    title: 'The Market Cart',
    artist: 'Thomas Gainsborough',
    year: 1786
  },
  {
    title: 'Sacred and Profane',
    artist: 'Titian',
    year: 1515
  },
  {
    title: 'The Worship of Venus',
    artist: 'Titian',
    year: 1518
  },
  {
    title: 'Man with a Glove',
    artist: 'Titian',
    year: 1522
  },
  {
    title: 'Bacchus Ariadne',
    artist: 'Titian',
    year: 1523
  },
  {
    title: 'Pesaro Madonna',
    artist: 'Titian',
    year: 1526
  },
  {
    title: 'Venus of Urbino',
    artist: 'Titian',
    year: 1534
  },
  {
    title: 'Sisyphus',
    artist: 'Titian',
    year: 1549
  },
  {
    title: 'The Rape of Europa',
    artist: 'Titian',
    year: 1562
  },
  {
    title: 'Allegory of Prudence',
    artist: 'Titian',
    year: 1570
  },
  {
    title: 'Pieta',
    artist: 'Titian',
    year: 1575
  },
  {
    title: 'The Potato Eaters',
    artist: 'Vincent van Gogh',
    year: 1885
  },
  {
    title: 'Cafe Terrace at Night',
    artist: 'Vincent van Gogh',
    year: 1888
  },
  {
    title: 'The Night Cafe',
    artist: 'Vincent van Gogh',
    year: 1888
  },
  {
    title: 'The Red Vinyard',
    artist: 'Vincent van Gogh',
    year: 1888
  },
  {
    title: 'The Yellow House',
    artist: 'Vincent van Gogh',
    year: 1888
  },
  {
    title: 'Fishing Boats on the Beach at Les Saintes-Maries-de-la-Mer',
    artist: 'Vincent van Gogh',
    year: 1888
  },
  {
    title: 'The Olive Trees',
    artist: 'Vincent van Gogh',
    year: 1889
  },
  {
    title: 'The Starry Night',
    artist: 'Vincent van Gogh',
    year: 1889
  },
  {
    title: 'Irises',
    artist: 'Vincent van Gogh',
    year: 1889
  },
  {
    title: 'Wheat Field with Cypresses',
    artist: 'Vincent van Gogh',
    year: 1889
  },
  {
    title: 'Branches with Almond Blossom',
    artist: 'Vincent van Gogh',
    year: 1890
  },
  {
    title: 'Still Life with Irises',
    artist: 'Vincent van Gogh',
    year: 1890
  },
  {
    title: 'The Blue Rider',
    artist: 'Wassily Kandinsky',
    year: 1903
  },
  {
    title: 'Color Study: Squares with Concentric Circles',
    artist: 'Wassily Kandinsky',
    year: 1913
  },
  {
    title: 'Black Lines',
    artist: 'Wassily Kandinsky',
    year: 1913
  },
  {
    title: 'Circles in a Circle',
    artist: 'Wassily Kandinsky',
    year: 1923
  },
  {
    title: 'On White II',
    artist: 'Wassily Kandinsky',
    year: 1923
  },
  {
    title: 'Black and Violet',
    artist: 'Wassily Kandinsky',
    year: 1923
  },
  {
    title: 'Composition 8',
    artist: 'Wassily Kandinsky',
    year: 1923
  },
  {
    title: 'Yellow-Red-Blue',
    artist: 'Wassily Kandinsky',
    year: 1925
  },
  {
    title: 'Several Circles',
    artist: 'Wassily Kandinsky',
    year: 1926
  },
  {
    title: 'Composition 9',
    artist: 'Wassily Kandinsky',
    year: 1936
  },
  {
    title: 'St. Francis Preaching',
    artist: 'Giotto di Bondone',
    year: 1299
  },
  {
    title: 'Dante Alighieri',
    artist: 'Giotto di Bondone',
    year: 1300
  },
  {
    title: 'Lamentation',
    artist: 'Giotto di Bondone',
    year: 1305
  },
  {
    title: 'The Ascension',
    artist: 'Giotto di Bondone',
    year: 1305
  },
  {
    title: 'Nativity',
    artist: 'Giotto di Bondone',
    year: 1305
  },
  {
    title: 'The Flight into Egypt',
    artist: 'Giotto di Bondone',
    year: 1306
  },
  {
    title: 'Kiss of Judas',
    artist: 'Giotto di Bondone',
    year: 1306
  },
  {
    title: 'Last Supper',
    artist: 'Giotto di Bondone',
    year: 1306
  },
  {
    title: 'Raising of Lazarus',
    artist: 'Giotto di Bondone',
    year: 1306
  },
  {
    title: 'Pavonia',
    artist: 'Sir Frederich Leighton',
    year: 1859
  },
  {
    title: 'Winding the Skein',
    artist: 'Sir Frederich Leighton',
    year: 1878
  },
  {
    title: 'Biondina',
    artist: 'Sir Frederich Leighton',
    year: 1879
  },
  {
    title: 'Light of the Harem',
    artist: 'Sir Frederich Leighton',
    year: 1880
  },
  {
    title: 'Wedded',
    artist: 'Sir Frederich Leighton',
    year: 1882
  },
  {
    title: 'Music Lesson',
    artist: 'Sir Frederich Leighton',
    year: 1884
  },
  {
    title: 'The Bath of Psyche',
    artist: 'Sir Frederich Leighton',
    year: 1890
  },
  {
    title: 'Perseus and Andromeda',
    artist: 'Sir Frederich Leighton',
    year: 1891
  },
  {
    title: 'Flaming June',
    artist: 'Sir Frederich Leighton',
    year: 1895
  },
  {
    title: 'Jupiter and Thetis',
    artist: 'Jean Auguste Dominique Ingres',
    year: 1811
  },
  {
    title: 'The Dream of Ossian',
    artist: 'Jean Auguste Dominique Ingres',
    year: 1813
  },
  {
    title: 'Grande Odalisque',
    artist: 'Jean Auguste Dominique Ingres',
    year: 1814
  },
  {
    title: 'Paolo and Francesca',
    artist: 'Jean Auguste Dominique Ingres',
    year: 1819
  },
  {
    title: 'Niccolo Paganini',
    artist: 'Jean Auguste Dominique Ingres',
    year: 1819
  },
  {
    title: 'The Vow of Louis XIII',
    artist: 'Jean Auguste Dominique Ingres',
    year: 1824
  },
  {
    title: 'The Blessing Christ',
    artist: 'Jean Auguste Dominique Ingres',
    year: 1834
  },
  {
    title: 'Madame Moitessier',
    artist: 'Jean Auguste Dominique Ingres',
    year: 1851
  },
  {
    title: 'The Source',
    artist: 'Jean Auguste Dominique Ingres',
    year: 1856
  },
  {
    title: 'Avila Morning',
    artist: 'Diego Rivera',
    year: 1908
  },
  {
    title: 'The Arsenal',
    artist: 'Diego Rivera',
    year: 1928
  },
  {
    title: 'Frozen Assets',
    artist: 'Diego Rivera',
    year: 1931
  },
  {
    title: 'The Rivals',
    artist: 'Diego Rivera',
    year: 1931
  },
  {
    title: 'The Flower Carrier',
    artist: 'Diego Rivera',
    year: 1935
  },
  {
    title: 'The History of Mexico',
    artist: 'Diego Rivera',
    year: 1935
  },
  {
    title: 'Portrait of Lupe Marin',
    artist: 'Diego Rivera',
    year: 1938
  },
  {
    title: 'Pan American Unity',
    artist: 'Diego Rivera',
    year: 1940
  },
  {
    title: 'The Watermelons',
    artist: 'Diego Rivera',
    year: 1957
  },
  {
    title: 'The Pair Oared Shell',
    artist: 'Thomas Eakins',
    year: 1872
  },
  {
    title: 'The Gross Clinic',
    artist: 'Thomas Eakins',
    year: 1875
  },
  {
    title: 'Baby at Play',
    artist: 'Thomas Eakins',
    year: 1876
  },
  {
    title: 'The Writing Master',
    artist: 'Thomas Eakins',
    year: 1882
  },
  {
    title: 'Arcadia',
    artist: 'Thomas Eakins',
    year: 1883
  },
  {
    title: 'The Swimming Hole',
    artist: 'Thomas Eakins',
    year: 1885
  },
  {
    title: 'The Agnew Clinic',
    artist: 'Thomas Eakins',
    year: 1889
  },
  {
    title: 'Taking the Count',
    artist: 'Thomas Eakins',
    year: 1898
  },
  {
    title: 'The Concert Singer',
    artist: 'Thomas Eakins',
    year: 1892
  },
  {
    title: 'The Sower',
    artist: 'Jean-François Millet',
    year: 1850
  },
  {
    title: 'Woman Baking Bread',
    artist: 'Jean-François Millet',
    year: 1854
  },
  {
    title: 'The Potato Harvest',
    artist: 'Jean-François Millet',
    year: 1855
  },
  {
    title: 'The Gleaners',
    artist: 'Jean-François Millet',
    year: 1857
  },
  {
    title: 'The Angelus',
    artist: 'Jean-François Millet',
    year: 1859
  },
  {
    title: 'The Beakful',
    artist: 'Jean-François Millet',
    year: 1860
  },
  {
    title: 'Spring at Barbizon',
    artist: 'Jean-François Millet',
    year: 1873
  },
  {
    title: 'Haystacks: Autumn',
    artist: 'Jean-François Millet',
    year: 1874
  },
  {
    title: 'Le Semeur',
    artist: 'Jean-François Millet',
    year: 1875
  },
  {
    title: 'The Last Supper',
    artist: 'El Greco',
    year: 1568
  },
  {
    title: 'Annunciation',
    artist: 'El Greco',
    year: 1576
  },
  {
    title: 'San Sebastian',
    artist: 'El Greco',
    year: 1578
  },
  {
    title: 'The Holy Trinity',
    artist: 'El Greco',
    year: 1579
  },
  {
    title: 'Christ Carrying the Cross',
    artist: 'El Greco',
    year: 1580
  },
  {
    title: 'Self-Portrait',
    artist: 'El Greco',
    year: 1600
  },
  {
    title: 'View of Toledo',
    artist: 'El Greco',
    year: 1600
  },
  {
    title: 'Resurrection',
    artist: 'El Greco',
    year: 1600
  },
  {
    title: 'Nativity',
    artist: 'El Greco',
    year: 1604
  },
  {
    title: 'Laocoon',
    artist: 'El Greco',
    year: 1614
  },
  {
    title: 'The Battle between Lent and Carnival',
    artist: 'Pieter Bruegel the Elder',
    year: 1559
  },
  {
    title: "Children's Games",
    artist: 'Pieter Bruegel the Elder',
    year: 1560
  },
  {
    title: 'Landscape with the Fall of Icarus',
    artist: 'Pieter Bruegel the Elder',
    year: 1560
  },
  {
    title: 'Dull Gret',
    artist: 'Pieter Bruegel the Elder',
    year: 1562
  },
  {
    title: 'The Triumph of Death',
    artist: 'Pieter Bruegel the Elder',
    year: 1562
  },
  {
    title: 'The Tower of Babel',
    artist: 'Pieter Bruegel the Elder',
    year: 1563
  },
  {
    title: 'The Harvesters',
    artist: 'Pieter Bruegel the Elder',
    year: 1565
  },
  {
    title: 'The Hay Harvest',
    artist: 'Pieter Bruegel the Elder',
    year: 1565
  },
  {
    title: 'The Peasant Wedding',
    artist: 'Pieter Bruegel the Elder',
    year: 1567
  },
  {
    title: 'The Peasant Dance',
    artist: 'Pieter Bruegel the Elder',
    year: 1567
  },
  {
    title: 'Hunters in the Snow',
    artist: 'Pieter Bruegel the Elder',
    year: 1565
  },
  {
    title: 'Interior',
    artist: 'Edgar Degas',
    year: 1869
  },
  {
    title: 'Orchestra Musicians',
    artist: 'Edgar Degas',
    year: 1872
  },
  {
    title: 'A Cotton Office in New Orleans',
    artist: 'Edgar Degas',
    year: 1873
  },
  {
    title: 'The Ballet Class',
    artist: 'Edgar Degas',
    year: 1874
  },
  {
    title: 'Dance Class',
    artist: 'Edgar Degas',
    year: 1874
  },
  {
    title: 'The Millinery Shop',
    artist: 'Edgar Degas',
    year: 1879
  },
  {
    title: 'Place de la Concorde',
    artist: 'Edgar Degas',
    year: 1879
  },
  {
    title: 'Dancers Bending Down',
    artist: 'Edgar Degas',
    year: 1885
  },
  {
    title: 'Combing the Hair',
    artist: 'Edgar Degas',
    year: 1895
  },
  {
    title: 'The Blue Dancers',
    artist: 'Edgar Degas',
    year: 1897
  },
  {
    title: 'The Yerres, Rain',
    artist: 'Gustave Caillebotte',
    year: 1875
  },
  {
    title: 'The Floor Scrapers',
    artist: 'Gustave Caillebotte',
    year: 1875
  },
  {
    title: "Le Pont de l'Europe",
    artist: 'Gustave Caillebotte',
    year: 1876
  },
  {
    title: 'Man at the Window',
    artist: 'Gustave Caillebotte',
    year: 1876
  },
  {
    title: 'Pair Street in Rainy Weather',
    artist: 'Gustave Caillebotte',
    year: 1877
  },
  {
    title: 'Skiffs on the Yerres',
    artist: 'Gustave Caillebotte',
    year: 1877
  },
  {
    title: 'Oarsmen',
    artist: 'Gustave Caillebotte',
    year: 1877
  },
  {
    title: 'Boating on the Yerres',
    artist: 'Gustave Caillebotte',
    year: 1877
  },
  {
    title: 'The Orange Trees',
    artist: 'Gustave Caillebotte',
    year: 1878
  },
  {
    title: 'Rising Road',
    artist: 'Gustave Caillebotte',
    year: 1881
  },
  {
    title: 'By the Sea',
    artist: 'Gustave Caillebotte',
    year: 1894
  },
  {
    title: 'The Magic Circle',
    artist: 'John William Waterhouse',
    year: 1886
  },
  {
    title: 'The Lady of Shalott',
    artist: 'John William Waterhouse',
    year: 1888
  },
  {
    title: 'Pandora',
    artist: 'John William Waterhouse',
    year: 1898
  },
  {
    title: 'A Mermaid',
    artist: 'John William Waterhouse',
    year: 1900
  },
  {
    title: 'Destiny',
    artist: 'John William Waterhouse',
    year: 1900
  },
  {
    title: 'The Crystal Ball',
    artist: 'John William Waterhouse',
    year: 1902
  },
  {
    title: 'Windflowers',
    artist: 'John William Waterhouse',
    year: 1902
  },
  {
    title: 'Boreas',
    artist: 'John William Waterhouse',
    year: 1903
  },
  {
    title: 'Echo and Narcissus',
    artist: 'John William Waterhouse',
    year: 1903
  },
  {
    title: 'The Sorceress',
    artist: 'John William Waterhouse',
    year: 1911
  },
  {
    title: 'Miranda - The Tempest',
    artist: 'John William Waterhouse',
    year: 1916
  },
  {
    title: 'Lilith',
    artist: 'John Maler Collier',
    year: 1887
  },
  {
    title: 'Priestess of Delphi',
    artist: 'John Maler Collier',
    year: 1891
  },
  {
    title: 'The Laboratory',
    artist: 'John Maler Collier',
    year: 1895
  },
  {
    title: 'Lady Godiva',
    artist: 'John Maler Collier',
    year: 1897
  },
  {
    title: 'The Sinner',
    artist: 'John Maler Collier',
    year: 1904
  },
  {
    title: 'Eve',
    artist: 'John Maler Collier',
    year: 1911
  },
  {
    title: 'Angela McInnes',
    artist: 'John Maler Collier',
    year: 1914
  },
  {
    title: 'The Water Nymph',
    artist: 'John Maler Collier',
    year: 1923
  },
  {
    title: 'Sleeping Beauty',
    artist: 'John Maler Collier',
    year: 1929
  },
  {
    title: 'Study for the City',
    artist: 'Robert Delaunay',
    year: 1910
  },
  {
    title: 'Champ de Mars: The Red Tower',
    artist: 'Robert Delaunay',
    year: 1912
  },
  {
    title: 'Rhythm',
    artist: 'Robert Delaunay',
    year: 1912
  },
  {
    title: 'The Three Graces',
    artist: 'Robert Delaunay',
    year: 1912
  },
  {
    title: 'The City of Paris',
    artist: 'Robert Delaunay',
    year: 1912
  },
  {
    title: 'Window',
    artist: 'Robert Delaunay',
    year: 1912
  },
  {
    title: 'Portuguese Woman',
    artist: 'Robert Delaunay',
    year: 1916
  },
  {
    title: 'The Cardiff Team',
    artist: 'Robert Delaunay',
    year: 1922
  },
  {
    title: 'The Joy of LIfe',
    artist: 'Robert Delaunay',
    year: 1930
  },
  {
    title: 'Rhythms',
    artist: 'Robert Delaunay',
    year: 1934
  },
  {
    title: 'A Reading from Homer',
    artist: 'Sir Lawrence Alma-Tadema',
    year: 1885
  },
  {
    title: 'Unconscious Rivals',
    artist: 'Sir Lawrence Alma-Tadema',
    year: 1893
  },
  {
    title: 'The Coign of Vantage',
    artist: 'Sir Lawrence Alma-Tadema',
    year: 1895
  },
  {
    title: 'A Difference of Opinion',
    artist: 'Sir Lawrence Alma-Tadema',
    year: 1896
  },
  {
    title: 'Among the Ruins',
    artist: 'Sir Lawrence Alma-Tadema',
    year: 1904
  },
  {
    title: 'Ask Me No More',
    artist: 'Sir Lawrence Alma-Tadema',
    year: 1906
  },
  {
    title: 'A Favourite Custom',
    artist: 'Sir Lawrence Alma-Tadema',
    year: 1909
  },
  {
    title: 'Hopeful',
    artist: 'Sir Lawrence Alma-Tadema',
    year: 1909
  },
  {
    title: 'Summer Offering',
    artist: 'Sir Lawrence Alma-Tadema',
    year: 1911
  },
  {
    title: 'Stag Night at Sharkeys',
    artist: 'George Bellows',
    year: 1909
  },
  {
    title: 'Men of the Docks',
    artist: 'George Bellows',
    year: 1912
  },
  {
    title: 'Matinicus',
    artist: 'George Bellows',
    year: 1916
  },
  {
    title: 'Dance in a Madhouse',
    artist: 'George Bellows',
    year: 1917
  },
  {
    title: 'The Germans Arrive',
    artist: 'George Bellows',
    year: 1918
  },
  {
    title: 'The White Hope',
    artist: 'George Bellows',
    year: 1921
  },
  {
    title: 'A Knock-Out',
    artist: 'George Bellows',
    year: 1921
  },
  {
    title: 'The Crowd',
    artist: 'George Bellows',
    year: 1923
  },
  {
    title: 'Dempsey and Firpo',
    artist: 'George Bellows',
    year: 1924
  },
  {
    title: 'Poker Game',
    artist: 'Cassius Marcellus Coolidge',
    year: 1894
  },
  {
    title: 'A Friend in Need',
    artist: 'Cassius Marcellus Coolidge',
    year: 1903
  },
  {
    title: 'His Station and Four Aces',
    artist: 'Cassius Marcellus Coolidge',
    year: 1903
  },
  {
    title: 'Poker Sympathy',
    artist: 'Cassius Marcellus Coolidge',
    year: 1903
  },
  {
    title: 'One to Tie Two to Win',
    artist: 'Cassius Marcellus Coolidge',
    year: 1903
  },
  {
    title: 'The Reunion',
    artist: 'Cassius Marcellus Coolidge',
    year: 1903
  },
  {
    title: 'Sitting Up with a Sick Friend',
    artist: 'Cassius Marcellus Coolidge',
    year: 1905
  },
  {
    title: 'A Waterloo',
    artist: 'Cassius Marcellus Coolidge',
    year: 1906
  },
  {
    title: 'Looks Like Four of a Kind',
    artist: 'Cassius Marcellus Coolidge',
    year: 1910
  },
  {
    title: 'Dante and Virgil In Hell',
    artist: 'William-Adolphe Bouguereu',
    year: 1850
  },
  {
    title: 'Nymphs and Satyr',
    artist: 'William-Adolphe Bouguereu',
    year: 1873
  },
  {
    title: 'The Birth of Venus',
    artist: 'William-Adolphe Bouguereu',
    year: 1879
  },
  {
    title: 'Dawn',
    artist: 'William-Adolphe Bouguereu',
    year: 1881
  },
  {
    title: 'The Youth of Bacchus',
    artist: 'William-Adolphe Bouguereu',
    year: 1884
  },
  {
    title: 'The First Mourning',
    artist: 'William-Adolphe Bouguereu',
    year: 1888
  },
  {
    title: 'The Shepherdess',
    artist: 'William-Adolphe Bouguereu',
    year: 1889
  },
  {
    title: 'The Bohemian',
    artist: 'William-Adolphe Bouguereu',
    year: 1890
  },
  {
    title: 'Les Oreades',
    artist: 'William-Adolphe Bouguereu',
    year: 1902
  },
  {
    title: 'Rainy Day, Boston',
    artist: 'Childe Hassam',
    year: 1885
  },
  {
    title: 'Geraniums',
    artist: 'Childe Hassam',
    year: 1888
  },
  {
    title: 'Moonrise at Sunset',
    artist: 'Childe Hassam',
    year: 1892
  },
  {
    title: 'Evening',
    artist: 'Childe Hassam',
    year: 1907
  },
  {
    title: 'The Water Garden',
    artist: 'Childe Hassam',
    year: 1909
  },
  {
    title: 'Flags on the Waldorf',
    artist: 'Childe Hassam',
    year: 1916
  },
  {
    title: 'The Avenue in the Rain',
    artist: 'Childe Hassam',
    year: 1917
  },
  {
    title: 'Allies Day, May 1917',
    artist: 'Childe Hassam',
    year: 1917
  },
  {
    title: 'Flags, Fifth Avenue',
    artist: 'Childe Hassam',
    year: 1918
  },
  {
    title: 'Pair of Lovers in the Forest',
    artist: 'Albrecht Altdorfer',
    year: 1511
  },
  {
    title: 'The Death of the Virgin',
    artist: 'Albrecht Altdorfer',
    year: 1513
  },
  {
    title: 'The Victory of Charlemagne',
    artist: 'Albrecht Altdorfer',
    year: 1518
  },
  {
    title: 'Landscape with a Footbridge',
    artist: 'Albrecht Altdorfer',
    year: 1520
  },
  {
    title: 'Saint George in the Forest',
    artist: 'Albrecht Altdorfer',
    year: 1520
  },
  {
    title: 'Susanna in the Bath',
    artist: 'Albrecht Altdorfer',
    year: 1526
  },
  {
    title: 'Susanna and the Elders',
    artist: 'Albrecht Altdorfer',
    year: 1526
  },
  {
    title: 'The Battle of Alexander at Issus',
    artist: 'Albrecht Altdorfer',
    year: 1529
  },
  {
    title: 'Carpenter',
    artist: 'Albrecht Altdorfer',
    year: 1538
  },
  {
    title: 'The Hermitage at Pontoise',
    artist: 'Camille Pissarro',
    year: 1867
  },
  {
    title: 'The Crystal Palace',
    artist: 'Camille Pissarro',
    year: 1871
  },
  {
    title: 'Vegetable Garden',
    artist: 'Camille Pissarro',
    year: 1878
  },
  {
    title: 'The Little Country Maid',
    artist: 'Camille Pissarro',
    year: 1882
  },
  {
    title: 'Sunset at Eragny',
    artist: 'Camille Pissarro',
    year: 1890
  },
  {
    title: 'The Place du Havre, Paris',
    artist: 'Camille Pissarro',
    year: 1893
  },
  {
    title: 'Autumn, Poplars',
    artist: 'Camille Pissarro',
    year: 1894
  },
  {
    title: 'Boulevard Montmartre Spring',
    artist: 'Camille Pissarro',
    year: 1897
  },
  {
    title: 'Sunset, Rouen',
    artist: 'Camille Pissarro',
    year: 1898
  },
  {
    title: 'Hay Harvest at Eragny',
    artist: 'Camille Pissarro',
    year: 1901
  },
  {
    title: 'Le Pont Neuf',
    artist: 'Camille Pissarro',
    year: 1902
  },
  {
    title: 'Masterpiece',
    artist: 'Roy Lichtenstein',
    year: 1962
  },
  {
    title: 'Spray',
    artist: 'Roy Lichtenstein',
    year: 1962
  },
  {
    title: 'Crying Girl',
    artist: 'Roy Lichtenstein',
    year: 1963
  },
  {
    title: 'In the Car',
    artist: 'Roy Lichtenstein',
    year: 1963
  },
  {
    title: 'Hopeless',
    artist: 'Roy Lichtenstein',
    year: 1963
  },
  {
    title: 'Whaam!',
    artist: 'Roy Lichtenstein',
    year: 1963
  },
  {
    title: 'Happy Tears',
    artist: 'Roy Lichtenstein',
    year: 1964
  },
  {
    title: 'I Know...Brad',
    artist: 'Roy Lichtenstein',
    year: 1964
  },
  {
    title: 'Girl with Hair Ribbon',
    artist: 'Roy Lichtenstein',
    year: 1965
  },
  {
    title: 'Stepping Out',
    artist: 'Roy Lichtenstein',
    year: 1978
  },
  {
    title: 'Cornfield at Ewell',
    artist: 'William Holman Hunt',
    year: 1849
  },
  {
    title: 'Claudio and Isabella',
    artist: 'William Holman Hunt',
    year: 1850
  },
  {
    title: 'The Hireling Shepherd',
    artist: 'William Holman Hunt',
    year: 1851
  },
  {
    title: 'Our English Coasts',
    artist: 'William Holman Hunt',
    year: 1852
  },
  {
    title: 'The Sphinx at Gizeh',
    artist: 'William Holman Hunt',
    year: 1854
  },
  {
    title: 'The Light of the World',
    artist: 'William Holman Hunt',
    year: 1854
  },
  {
    title: 'The Scapegoat',
    artist: 'William Holman Hunt',
    year: 1856
  },
  {
    title: 'The Shadow of Death',
    artist: 'William Holman Hunt',
    year: 1873
  },
  {
    title: 'The Lady of Shalott',
    artist: 'William Holman Hunt',
    year: 1905
  },
  {
    title: 'Worn Out',
    artist: 'Hans Andersen Brendekilde',
    year: 1889
  },
  {
    title: 'A Wooded Path in Autumn',
    artist: 'Hans Andersen Brendekilde',
    year: 1902
  },
  {
    title: 'Village Scene in the Early Spring',
    artist: 'Hans Andersen Brendekilde',
    year: 1910
  },
  {
    title: 'At the Garden Bank',
    artist: 'Hans Andersen Brendekilde',
    year: 1913
  },
  {
    title: 'Have You Seen My Kitten',
    artist: 'Hans Andersen Brendekilde',
    year: 1914
  },
  {
    title: 'Landscape',
    artist: 'Hans Andersen Brendekilde',
    year: 1915
  },
  {
    title: 'Home for Dinner',
    artist: 'Hans Andersen Brendekilde',
    year: 1917
  },
  {
    title: 'Afternoon Work',
    artist: 'Hans Andersen Brendekilde',
    year: 1918
  },
  {
    title: 'A Fountain in Rome',
    artist: 'Hans Andersen Brendekilde',
    year: 1922
  },
  {
    title: 'Breakfast in Sora',
    artist: 'Peder Severin Krøyer',
    year: 1880
  },
  {
    title: 'Frederikke Tuxen',
    artist: 'Peder Severin Krøyer',
    year: 1882
  },
  {
    title: 'Hip, Hip, Hurrah!',
    artist: 'Peder Severin Krøyer',
    year: 1888
  },
  {
    title: 'Summer Evening at Skagen',
    artist: 'Peder Severin Krøyer',
    year: 1892
  },
  {
    title: 'Roses',
    artist: 'Peder Severin Krøyer',
    year: 1893
  },
  {
    title: 'Marie in the Garden',
    artist: 'Peder Severin Krøyer',
    year: 1895
  },
  {
    title: 'Holger Drachman',
    artist: 'Peder Severin Krøyer',
    year: 1895
  },
  {
    title: 'The Benzon Daughters',
    artist: 'Peder Severin Krøyer',
    year: 1897
  },
  {
    title: 'Hunters of Skagen',
    artist: 'Peder Severin Krøyer',
    year: 1898
  },
  {
    title: 'Aristoteles',
    artist: 'Francesco Paolo Hayez',
    year: 1811
  },
  {
    title: 'Lacocoon',
    artist: 'Francesco Paolo Hayez',
    year: 1812
  },
  {
    title: 'Bathsheba',
    artist: 'Francesco Paolo Hayez',
    year: 1827
  },
  {
    title: 'Bathing Nymphs',
    artist: 'Francesco Paolo Hayez',
    year: 1831
  },
  {
    title: 'Bagnante',
    artist: 'Francesco Paolo Hayez',
    year: 1844
  },
  {
    title: 'The Sicilian Vespers',
    artist: 'Francesco Paolo Hayez',
    year: 1846
  },
  {
    title: 'The Kiss',
    artist: 'Francesco Paolo Hayez',
    year: 1859
  },
  {
    title: 'Odalisque with Book',
    artist: 'Francesco Paolo Hayez',
    year: 1866
  },
  {
    title: 'Odalisque',
    artist: 'Francesco Paolo Hayez',
    year: 1867
  },
  {
    title: 'Scull',
    artist: 'Jean-Michel Basquiat',
    year: 1981
  },
  {
    title: 'Poison Oasis',
    artist: 'Jean-Michel Basquiat',
    year: 1981
  },
  {
    title: 'Warrior',
    artist: 'Jean-Michel Basquiat',
    year: 1982
  },
  {
    title: 'In Italian',
    artist: 'Jean-Michel Basquiat',
    year: 1983
  },
  {
    title: 'Hollywood Africans',
    artist: 'Jean-Michel Basquiat',
    year: 1983
  },
  {
    title: 'Crown',
    artist: 'Jean-Michel Basquiat',
    year: 1983
  },
  {
    title: 'Flexible',
    artist: 'Jean-Michel Basquiat',
    year: 1984
  },
  {
    title: 'Riding with Death',
    artist: 'Jean-Michel Basquiat',
    year: 1988
  },
  {
    title: 'In the Loge',
    artist: 'Mary Cassatt',
    year: 1878
  },
  {
    title: 'The Cup of Tea',
    artist: 'Mary Cassatt',
    year: 1879
  },
  {
    title: 'Lilacs in a Window',
    artist: 'Mary Cassatt',
    year: 1879
  },
  {
    title: 'Self Portrait',
    artist: 'Mary Cassatt',
    year: 1880
  },
  {
    title: 'Child in a Straw Hat',
    artist: 'Mary Cassatt',
    year: 1886
  },
  {
    title: 'The Boating Party',
    artist: 'Mary Cassatt',
    year: 1893
  },
  {
    title: 'Young Mother Sewing',
    artist: 'Mary Cassatt',
    year: 1900
  },
  {
    title: 'Sara Holding a Cat',
    artist: 'Mary Cassatt',
    year: 1908
  },
  {
    title: 'The Veteran in a New Field',
    artist: 'Winslow Homer',
    year: 1865
  },
  {
    title: 'Snap the Whip',
    artist: 'Winslow Homer',
    year: 1872
  },
  {
    title: 'Breezing Up (A Fair Wind)',
    artist: 'Winslow Homer',
    year: 1876
  },
  {
    title: 'The Cotton Pickers',
    artist: 'Winslow Homer',
    year: 1876
  },
  {
    title: 'The Life Line',
    artist: 'Winslow Homer',
    year: 1884
  },
  {
    title: 'The Herring Net',
    artist: 'Winslow Homer',
    year: 1885
  },
  {
    title: 'Eight Bells',
    artist: 'Winslow Homer',
    year: 1886
  },
  {
    title: 'The Fox Hunt',
    artist: 'Winslow Homer',
    year: 1893
  },
  {
    title: 'Northeaster',
    artist: 'Winslow Homer',
    year: 1895
  },
  {
    title: 'Movement in Squares',
    artist: 'Bridget Riley',
    year: 1961
  },
  {
    title: 'Hesitate',
    artist: 'Bridget Riley',
    year: 1962
  },
  {
    title: 'Blaze 1',
    artist: 'Bridget Riley',
    year: 1962
  },
  {
    title: 'Fragment 2',
    artist: 'Bridget Riley',
    year: 1965
  },
  {
    title: 'Cataract 3',
    artist: 'Bridget Riley',
    year: 1967
  },
  {
    title: 'Achean',
    artist: 'Bridget Riley',
    year: 1981
  },
  {
    title: 'RA 2',
    artist: 'Bridget Riley',
    year: 1981
  },
  {
    title: 'Nataraja',
    artist: 'Bridget Riley',
    year: 1993
  },
  {
    title: 'The Cock Fight',
    artist: 'Jean-Léon Gérôme',
    year: 1846
  },
  {
    title: 'The Slave Market',
    artist: 'Jean-Léon Gérôme',
    year: 1866
  },
  {
    title: 'The Death of Caesar',
    artist: 'Jean-Léon Gérôme',
    year: 1867
  },
  {
    title: 'Bashi-Bazouk',
    artist: 'Jean-Léon Gérôme',
    year: 1869
  },
  {
    title: 'Moorish Bath',
    artist: 'Jean-Léon Gérôme',
    year: 1870
  },
  {
    title: 'Public Prayer in the Mosque of Amr, Cairo',
    artist: 'Jean-Léon Gérôme',
    year: 1871
  },
  {
    title: 'Pollice Verso',
    artist: 'Jean-Léon Gérôme',
    year: 1872
  },
  {
    title: 'Pool in a Harem',
    artist: 'Jean-Léon Gérôme',
    year: 1875
  },
  {
    title: 'Pygmalion and Galatea',
    artist: 'Jean-Léon Gérôme',
    year: 1890
  },
  {
    title: 'The Carpet Merchant',
    artist: 'Jean-Léon Gérôme',
    year: 1892
  },
  {
    title: 'Mountains and Sea',
    artist: 'Helen Frankenthaler',
    year: 1952
  },
  {
    title: "Jacob's Ladder",
    artist: 'Helen Frankenthaler',
    year: 1957
  },
  {
    title: 'Round Trip',
    artist: 'Helen Frankenthaler',
    year: 1957
  },
  {
    title: "Small's Paradise",
    artist: 'Helen Frankenthaler',
    year: 1964
  },
  {
    title: 'Provincetown',
    artist: 'Helen Frankenthaler',
    year: 1964
  },
  {
    title: 'Persian Garden',
    artist: 'Helen Frankenthaler',
    year: 1966
  },
  {
    title: 'Cameo',
    artist: 'Helen Frankenthaler',
    year: 1980
  },
  {
    title: 'Grey Fireworks',
    artist: 'Helen Frankenthaler',
    year: 2000
  },
  {
    title: 'Snow Pines',
    artist: 'Helen Frankenthaler',
    year: 2004
  },
  {
    title: 'Fallen Angel',
    artist: 'Alexandre Cabanel',
    year: 1847
  },
  {
    title: 'Albayde',
    artist: 'Alexandre Cabanel',
    year: 1848
  },
  {
    title: 'The Death of Moses',
    artist: 'Alexandre Cabanel',
    year: 1851
  },
  {
    title: 'Florentine Poet',
    artist: 'Alexandre Cabanel',
    year: 1861
  },
  {
    title: 'The Birth of Venus',
    artist: 'Alexandre Cabanel',
    year: 1863
  },
  {
    title: 'Napoleon III',
    artist: 'Alexandre Cabanel',
    year: 1865
  },
  {
    title: 'Echo',
    artist: 'Alexandre Cabanel',
    year: 1874
  },
  {
    title: 'Samson and Delilah',
    artist: 'Alexandre Cabanel',
    year: 1878
  },
  {
    title: 'Phaedra',
    artist: 'Alexandre Cabanel',
    year: 1880
  },
  {
    title: 'Weaning the Calves',
    artist: 'Rosa Bonheur',
    year: 1849
  },
  {
    title: 'Ploughing in the Nivernais',
    artist: 'Rosa Bonheur',
    year: 1849
  },
  {
    title: 'Going to Market',
    artist: 'Rosa Bonheur',
    year: 1851
  },
  {
    title: 'The Horse Fair',
    artist: 'Rosa Bonheur',
    year: 1853
  },
  {
    title: 'A Limier Briquet Hound',
    artist: 'Rosa Bonheur',
    year: 1856
  },
  {
    title: 'Highland Raid',
    artist: 'Rosa Bonheur',
    year: 1860
  },
  {
    title: 'Sheep by the Sea',
    artist: 'Rosa Bonheur',
    year: 1865
  },
  {
    title: 'The Legend of the Wolves',
    artist: 'Rosa Bonheur',
    year: 1899
  },
  {
    title: 'Isabella',
    artist: 'John Everett Millais',
    year: 1849
  },
  {
    title: 'Mariana',
    artist: 'John Everett Millais',
    year: 1851
  },
  {
    title: 'A Huguenot',
    artist: 'John Everett Millais',
    year: 1852
  },
  {
    title: 'Ophelia',
    artist: 'John Everett Millais',
    year: 1852
  },
  {
    title: 'The Order of Release',
    artist: 'John Everett Millais',
    year: 1853
  },
  {
    title: 'The Blind Girl',
    artist: 'John Everett Millais',
    year: 1856
  },
  {
    title: 'Autumn Leaves',
    artist: 'John Everett Millais',
    year: 1856
  },
  {
    title: 'Peace Concluded',
    artist: 'John Everett Millais',
    year: 1856
  },
  {
    title: 'The Black Brunswicker',
    artist: 'John Everett Millais',
    year: 1860
  },
  {
    title: 'The Fortune Teller',
    artist: 'Ghada Amer',
    year: 2008
  },
  {
    title: 'You My Love',
    artist: 'Ghada Amer',
    year: 2011
  },
  {
    title: 'Unfriending Camelia',
    artist: 'Ghada Amer',
    year: 2011
  },
  {
    title: 'The Sea Witch',
    artist: 'Ghada Amer',
    year: 2011
  },
  {
    title: 'Test 5',
    artist: 'Ghada Amer',
    year: 2013
  },
  {
    title: 'The Rainbow Girl',
    artist: 'Ghada Amer',
    year: 2014
  },
  {
    title: 'You are a Lady',
    artist: 'Ghada Amer',
    year: 2015
  },
  {
    title: 'Sisters',
    artist: 'Ghada Amer',
    year: 2017
  },
  {
    title: 'My Nympheas #2',
    artist: 'Ghada Amer',
    year: 2018
  },
  {
    title: 'Silenus Drunk',
    artist: 'Anthony van Dyck',
    year: 1620
  },
  {
    title: 'The Betrayal of Christ',
    artist: 'Anthony van Dyck',
    year: 1620
  },
  {
    title: 'The Lomellini Family',
    artist: 'Anthony van Dyck',
    year: 1627
  },
  {
    title: 'Rinaldo and Armida',
    artist: 'Anthony van Dyck',
    year: 1629
  },
  {
    title: 'Samson and Delilah',
    artist: 'Anthony van Dyck',
    year: 1630
  },
  {
    title: 'Charles I in Three Positions',
    artist: 'Anthony van Dyck',
    year: 1635
  },
  {
    title: 'Charles I at the Hunt',
    artist: 'Anthony van Dyck',
    year: 1635
  },
  {
    title: 'Cupid and Psyche',
    artist: 'Anthony van Dyck',
    year: 1638
  },
  {
    title: 'Portrait of Olivia Porter',
    artist: 'Anthony van Dyck',
    year: 1640
  },
  {
    title: 'Charles I',
    artist: 'Anthony van Dyck',
    year: 1641
  },
  {
    title: 'Azure Grotto. Naples',
    artist: 'Ivan Aivazovsky',
    year: 1841
  },
  {
    title: 'The Ninth Wave',
    artist: 'Ivan Aivazovsky',
    year: 1850
  },
  {
    title: 'Shipwreck',
    artist: 'Ivan Aivazovsky',
    year: 1854
  },
  {
    title: 'View of Tiflis',
    artist: 'Ivan Aivazovsky',
    year: 1868
  },
  {
    title: 'The Rainbow',
    artist: 'Ivan Aivazovsky',
    year: 1873
  },
  {
    title: 'The Black Sea at Night',
    artist: 'Ivan Aivazovsky',
    year: 1879
  },
  {
    title: 'The Black Sea',
    artist: 'Ivan Aivazovsky',
    year: 1881
  },
  {
    title: 'Ship in the Stormy Sea',
    artist: 'Ivan Aivazovsky',
    year: 1887
  },
  {
    title: 'Between the Waves',
    artist: 'Ivan Aivazovsky',
    year: 1898
  },
  {
    title: 'Leda and the Swan',
    artist: 'Peter Paul Rubens',
    year: 1601
  },
  {
    title: 'Three Graces',
    artist: 'Peter Paul Rubens',
    year: 1605
  },
  {
    title: 'Prometheus Bound',
    artist: 'Peter Paul Rubens',
    year: 1612
  },
  {
    title: 'Two Satyrs',
    artist: 'Peter Paul Rubens',
    year: 1619
  },
  {
    title: 'The Fall of the Damned',
    artist: 'Peter Paul Rubens',
    year: 1620
  },
  {
    title: 'Perseus and Andromeda',
    artist: 'Peter Paul Rubens',
    year: 1622
  },
  {
    title: 'Susanna Fourment',
    artist: 'Peter Paul Rubens',
    year: 1622
  },
  {
    title: 'The Fall of Man',
    artist: 'Peter Paul Rubens',
    year: 1629
  },
  {
    title: 'The Garden of Love',
    artist: 'Peter Paul Rubens',
    year: 1633
  },
  {
    title: 'Consequences of War',
    artist: 'Peter Paul Rubens',
    year: 1639
  },
  {
    title: 'Saturn',
    artist: 'Peter Paul Rubens',
    year: 1636
  },
  {
    title: 'White Center',
    artist: 'Mark Rothko',
    year: 1950
  },
  {
    title: 'No. 6',
    artist: 'Mark Rothko',
    year: 1951
  },
  {
    title: 'Untitled',
    artist: 'Mark Rothko',
    year: 1952
  },
  {
    title: 'Earth & Green',
    artist: 'Mark Rothko',
    year: 1955
  },
  {
    title: 'Orange and Yellow',
    artist: 'Mark Rothko',
    year: 1956
  },
  {
    title: 'Black in Deep Red',
    artist: 'Mark Rothko',
    year: 1957
  },
  {
    title: 'Black on Maroon',
    artist: 'Mark Rothko',
    year: 1958
  },
  {
    title: 'Four Darks in Red',
    artist: 'Mark Rothko',
    year: 1958
  },
  {
    title: 'Red on Maroon',
    artist: 'Mark Rothko',
    year: 1959
  },
  {
    title: 'The Return of Neptune',
    artist: 'John Singleton Copley',
    year: 1754
  },
  {
    title: 'John Bours',
    artist: 'John Singleton Copley',
    year: 1763
  },
  {
    title: 'Ebenezer Storer',
    artist: 'John Singleton Copley',
    year: 1769
  },
  {
    title: 'Eleazer Tyng',
    artist: 'John Singleton Copley',
    year: 1772
  },
  {
    title: 'Mrs. John Winthrop',
    artist: 'John Singleton Copley',
    year: 1773
  },
  {
    title: 'Abigail Smith Babcock',
    artist: 'John Singleton Copley',
    year: 1774
  },
  {
    title: 'Watson and the Shark',
    artist: 'John Singleton Copley',
    year: 1778
  },
  {
    title: 'The Red Cross Knight',
    artist: 'John Singleton Copley',
    year: 1793
  },
  {
    title: 'John Quincy Adams',
    artist: 'John Singleton Copley',
    year: 1796
  },
  {
    title: 'Blue Field',
    artist: 'Julie Mehretu',
    year: 1997
  },
  {
    title: 'Excerpt (Riot)',
    artist: 'Julie Mehretu',
    year: 2003
  },
  {
    title: 'Stadia II',
    artist: 'Julie Mehretu',
    year: 2004
  },
  {
    title: 'Immanence',
    artist: 'Julie Mehretu',
    year: 2004
  },
  {
    title: 'Landscape Allegories',
    artist: 'Julie Mehretu',
    year: 2004
  },
  {
    title: 'Stadia III',
    artist: 'Julie Mehretu',
    year: 2004
  },
  {
    title: 'Entropia: Construction',
    artist: 'Julie Mehretu',
    year: 2005
  },
  {
    title: 'Easy Dark',
    artist: 'Julie Mehretu',
    year: 2007
  },
  {
    title: 'Auguries',
    artist: 'Julie Mehretu',
    year: 2010
  },
  {
    title: 'Excavation',
    artist: 'Willem de Kooning',
    year: 1950
  },
  {
    title: 'Abstraction',
    artist: 'Willem de Kooning',
    year: 1950
  },
  {
    title: 'Woman I',
    artist: 'Willem de Kooning',
    year: 1952
  },
  {
    title: 'Woman II',
    artist: 'Willem de Kooning',
    year: 1952
  },
  {
    title: 'Woman and Bicycle',
    artist: 'Willem de Kooning',
    year: 1953
  },
  {
    title: 'Woman III',
    artist: 'Willem de Kooning',
    year: 1953
  },
  {
    title: 'Woman-Ochre',
    artist: 'Willem de Kooning',
    year: 1955
  },
  {
    title: 'Interchange',
    artist: 'Willem de Kooning',
    year: 1955
  },
  {
    title: 'Door to the River',
    artist: 'Willem de Kooning',
    year: 1960
  },
  {
    title: 'Untitled No 213',
    artist: 'Etel Adnan',
    year: 2013
  },
  {
    title: 'Mountain No. 2',
    artist: 'Etel Adnan',
    year: 2014
  },
  {
    title: 'Ohne Titel',
    artist: 'Etel Adnan',
    year: 2015
  },
  {
    title: 'Envol (In Flight)',
    artist: 'Etel Adnan',
    year: 2017
  },
  {
    title: 'Untitled',
    artist: 'Etel Adnan',
    year: 2018
  },
  {
    title: 'Adobe',
    artist: 'Etel Adnan',
    year: 2018
  },
  {
    title: 'Equilibre',
    artist: 'Etel Adnan',
    year: 2018
  },
  {
    title: 'Late Afternoon',
    artist: 'Etel Adnan',
    year: 2020
  },
  {
    title: 'The Letter',
    artist: 'Giovanni Boldini',
    year: 1873
  },
  {
    title: 'The Hammock',
    artist: 'Giovanni Boldini',
    year: 1874
  },
  {
    title: 'The Laundry',
    artist: 'Giovanni Boldini',
    year: 1874
  },
  {
    title: 'Crossing the Street',
    artist: 'Giovanni Boldini',
    year: 1875
  },
  {
    title: 'View of Venice',
    artist: 'Giovanni Boldini',
    year: 1895
  },
  {
    title: 'Whistler Asleep',
    artist: 'Giovanni Boldini',
    year: 1897
  },
  {
    title: 'The Black Sash',
    artist: 'Giovanni Boldini',
    year: 1905
  },
  {
    title: 'Portrait of Madame de Florian',
    artist: 'Giovanni Boldini',
    year: 1910
  },
  {
    title: 'Reclining Nude',
    artist: 'Giovanni Boldini',
    year: 1930
  },
  {
    title: 'Judith Slaying Holofernes',
    artist: 'Artemisia Gentileschi',
    year: 1610
  },
  {
    title: 'Susanna and the Elders',
    artist: 'Artemisia Gentileschi',
    year: 1610
  },
  {
    title: 'Danae',
    artist: 'Artemisia Gentileschi',
    year: 1612
  },
  {
    title: 'Jael and Sisera',
    artist: 'Artemisia Gentileschi',
    year: 1620
  },
  {
    title: 'Cleopatra',
    artist: 'Artemisia Gentileschi',
    year: 1620
  },
  {
    title: 'Judith Beheading Holofernes',
    artist: 'Artemisia Gentileschi',
    year: 1620
  },
  {
    title: 'Esther and Ahaseurus',
    artist: 'Artemisia Gentileschi',
    year: 1630
  },
  {
    title: 'Self-Portrait as the Allegory of Painting',
    artist: 'Artemisia Gentileschi',
    year: 1639
  },
  {
    title: 'Minerva',
    artist: 'Artemisia Gentileschi',
    year: 1640
  },
  {
    title: 'The Crying Spider',
    artist: 'Odilon Redon',
    year: 1881
  },
  {
    title: 'Cactus Man',
    artist: 'Odilon Redon',
    year: 1882
  },
  {
    title: 'Closed Eyes',
    artist: 'Odilon Redon',
    year: 1890
  },
  {
    title: 'Haunting',
    artist: 'Odilon Redon',
    year: 1894
  },
  {
    title: 'Baronne de Domecy',
    artist: 'Odilon Redon',
    year: 1900
  },
  {
    title: 'Flower Clouds',
    artist: 'Odilon Redon',
    year: 1903
  },
  {
    title: 'Muse on Pegasus',
    artist: 'Odilon Redon',
    year: 1910
  },
  {
    title: 'The Cyclops',
    artist: 'Odilon Redon',
    year: 1914
  },
  {
    title: 'The Chariot of Apollo',
    artist: 'Odilon Redon',
    year: 1916
  },
  {
    title: 'Union Station',
    artist: 'Vija Celmins',
    year: 1962
  },
  {
    title: 'Suspended Plane',
    artist: 'Vija Celmins',
    year: 1966
  },
  {
    title: 'Flying Fortress',
    artist: 'Vija Celmins',
    year: 1966
  },
  {
    title: 'Explosion at Sea',
    artist: 'Vija Celmins',
    year: 1966
  },
  {
    title: 'Untitled (Big Sea #1)',
    artist: 'Vija Celmins',
    year: 1969
  },
  {
    title: 'Alliance',
    artist: 'Vija Celmins',
    year: 1983
  },
  {
    title: 'Ocean Surface',
    artist: 'Vija Celmins',
    year: 1992
  },
  {
    title: 'Night Sky ',
    artist: 'Vija Celmins',
    year: 1998
  },
  {
    title: 'Web #1',
    artist: 'Vija Celmins',
    year: 1999
  },
  {
    title: 'Fishing',
    artist: 'Natalia Goncharova',
    year: 1909
  },
  {
    title: 'Haycutting',
    artist: 'Natalia Goncharova',
    year: 1910
  },
  {
    title: 'Harvest',
    artist: 'Natalia Goncharova',
    year: 1911
  },
  {
    title: 'The Little Station',
    artist: 'Natalia Goncharova',
    year: 1911
  },
  {
    title: 'Landscape with a Train',
    artist: 'Natalia Goncharova',
    year: 1913
  },
  {
    title: 'Espagnole',
    artist: 'Natalia Goncharova',
    year: 1914
  },
  {
    title: 'Forest (Red-green)',
    artist: 'Natalia Goncharova',
    year: 1914
  },
  {
    title: 'Spanish Dancers',
    artist: 'Natalia Goncharova',
    year: 1918
  },
  {
    title: 'Flower Myth',
    artist: 'Paul Klee',
    year: 1918
  },
  {
    title: 'Red Balloon',
    artist: 'Paul Klee',
    year: 1922
  },
  {
    title: 'Fish Magic',
    artist: 'Paul Klee',
    year: 1925
  },
  {
    title: 'Aroundfish',
    artist: 'Paul Klee',
    year: 1926
  },
  {
    title: 'Castle and Sun',
    artist: 'Paul Klee',
    year: 1928
  },
  {
    title: 'Cat and Bird',
    artist: 'Paul Klee',
    year: 1928
  },
  {
    title: 'Highway and Byways',
    artist: 'Paul Klee',
    year: 1929
  },
  {
    title: 'New Harmony',
    artist: 'Paul Klee',
    year: 1936
  },
  {
    title: 'Legend of the Nile',
    artist: 'Paul Klee',
    year: 1937
  },
  {
    title: 'Death and Fire',
    artist: 'Paul Klee',
    year: 1940
  },
  {
    title: 'Untitled',
    artist: 'Paula Rego',
    year: 1964
  },
  {
    title: 'The Dance',
    artist: 'Paula Rego',
    year: 1988
  },
  {
    title: 'Dancing Ostriches',
    artist: 'Paula Rego',
    year: 1995
  },
  {
    title: 'Mist II',
    artist: 'Paula Rego',
    year: 1996
  },
  {
    title: 'Pomagne',
    artist: 'Paula Rego',
    year: 1996
  },
  {
    title: 'Moth',
    artist: 'Paula Rego',
    year: 1996
  },
  {
    title: 'Come to Me',
    artist: 'Paula Rego',
    year: 2001
  },
  {
    title: 'War',
    artist: 'Paula Rego',
    year: 2003
  },
  {
    title: 'The Guardian',
    artist: 'Paula Rego',
    year: 2008
  },
  {
    title: 'Portrait of a Gentleman Skating',
    artist: 'Gilbert Stuart',
    year: 1782
  },
  {
    title: 'Catherine Brass Yates',
    artist: 'Gilbert Stuart',
    year: 1794
  },
  {
    title: 'Matilda Stoughton de Jaudenes',
    artist: 'Gilbert Stuart',
    year: 1794
  },
  {
    title: 'Lansdowne Portrait',
    artist: 'Gilbert Stuart',
    year: 1796
  },
  {
    title: 'Athenaeum Portrait',
    artist: 'Gilbert Stuart',
    year: 1796
  },
  {
    title: 'Joseph Anthony Jr.',
    artist: 'Gilbert Stuart',
    year: 1798
  },
  {
    title: 'John R. Murray',
    artist: 'Gilbert Stuart',
    year: 1800
  },
  {
    title: 'Portrait of Thomas Jefferson',
    artist: 'Gilbert Stuart',
    year: 1805
  },
  {
    title: 'Mrs. Andrew Sigourney',
    artist: 'Gilbert Stuart',
    year: 1820
  },
  {
    title: 'Afro Mountain',
    artist: 'Ellen Gallagher',
    year: 1994
  },
  {
    title: 'Oh! Susanna',
    artist: 'Ellen Gallagher',
    year: 1995
  },
  {
    title: 'Delirious Hem',
    artist: 'Ellen Gallagher',
    year: 1995
  },
  {
    title: 'Paper Cup',
    artist: 'Ellen Gallagher',
    year: 1996
  },
  {
    title: 'Teeth Tracks',
    artist: 'Ellen Gallagher',
    year: 1996
  },
  {
    title: 'Untitled',
    artist: 'Ellen Gallagher',
    year: 1998
  },
  {
    title: 'Water Ecstatic',
    artist: 'Ellen Gallagher',
    year: 2003
  },
  {
    title: 'Bird in Hand',
    artist: 'Ellen Gallagher',
    year: 2006
  },
  {
    title: 'IGBT',
    artist: 'Ellen Gallagher',
    year: 2008
  },
  {
    title: 'The Ancient of Days',
    artist: 'William Blake',
    year: 1794
  },
  {
    title: 'Newton',
    artist: 'William Blake',
    year: 1795
  },
  {
    title: 'Albion Rose',
    artist: 'William Blake',
    year: 1796
  },
  {
    title: 'Job and His Daughters',
    artist: 'William Blake',
    year: 1800
  },
  {
    title: 'The Ghost of a Flea',
    artist: 'William Blake',
    year: 1820
  },
  {
    title: 'The Parable of the Wise and the Foolish',
    artist: 'William Blake',
    year: 1822
  },
  {
    title: 'The Fall of Satan',
    artist: 'William Blake',
    year: 1825
  },
  {
    title: "Job's Comforters",
    artist: 'William Blake',
    year: 1825
  },
  {
    title: 'The Lovers Whirlwind',
    artist: 'William Blake',
    year: 1827
  },
  {
    title: 'Untitled',
    artist: 'Laura Owens',
    year: 2000
  },
  {
    title: 'Untitled',
    artist: 'Laura Owens',
    year: 2001
  },
  {
    title: 'Untitled',
    artist: 'Laura Owens',
    year: 2002
  },
  {
    title: 'Untitled',
    artist: 'Laura Owens',
    year: 2003
  },
  {
    title: 'Untitled',
    artist: 'Laura Owens',
    year: 2004
  },
  {
    title: 'Untitled',
    artist: 'Laura Owens',
    year: 2005
  },
  {
    title: 'Untitled',
    artist: 'Laura Owens',
    year: 2006
  },
  {
    title: 'Untitled',
    artist: 'Laura Owens',
    year: 2007
  },
  {
    title: 'Untitled',
    artist: 'Laura Owens',
    year: 2008
  },
  {
    title: 'Vulcan and Venus',
    artist: 'Paolo Veronese',
    year: 1561
  },
  {
    title: 'The Wedding at Cana',
    artist: 'Paolo Veronese',
    year: 1563
  },
  {
    title: 'The Feast in the House of Simon the Pharisee',
    artist: 'Paolo Veronese',
    year: 1565
  },
  {
    title: 'Adoration of the Magi',
    artist: 'Paolo Veronese',
    year: 1573
  },
  {
    title: 'The Feast in the House of Levi',
    artist: 'Paolo Veronese',
    year: 1573
  },
  {
    title: 'Bathsheba at Her Bath',
    artist: 'Paolo Veronese',
    year: 1575
  },
  {
    title: 'The Rape of Europe',
    artist: 'Paolo Veronese',
    year: 1580
  },
  {
    title: 'Venus and Adonis',
    artist: 'Paolo Veronese',
    year: 1582
  },
  {
    title: 'Apotheosis of Venice',
    artist: 'Paolo Veronese',
    year: 1585
  },
  {
    title: 'The Red and the Black',
    artist: 'Elizabeth Peyton',
    year: 1992
  },
  {
    title: 'John Lydon',
    artist: 'Elizabeth Peyton',
    year: 1994
  },
  {
    title: 'Jake at the New Viet Huong',
    artist: 'Elizabeth Peyton',
    year: 1995
  },
  {
    title: 'Princess Elizabeth',
    artist: 'Elizabeth Peyton',
    year: 1995
  },
  {
    title: 'Liam',
    artist: 'Elizabeth Peyton',
    year: 1996
  },
  {
    title: 'Em',
    artist: 'Elizabeth Peyton',
    year: 2002
  },
  {
    title: 'Marc',
    artist: 'Elizabeth Peyton',
    year: 2003
  },
  {
    title: 'Flower Ben',
    artist: 'Elizabeth Peyton',
    year: 2003
  },
  {
    title: 'Keith (from Gimme Shelter)',
    artist: 'Elizabeth Peyton',
    year: 2004
  },
  {
    title: 'Job and His Friends',
    artist: 'Ilya Repin',
    year: 1869
  },
  {
    title: 'Slavic Composers',
    artist: 'Ilya Repin',
    year: 1872
  },
  {
    title: 'Barge Haulers on the Volga',
    artist: 'Ilya Repin',
    year: 1873
  },
  {
    title: 'A Fisher Girl',
    artist: 'Ilya Repin',
    year: 1874
  },
  {
    title: 'Ukranian Girl',
    artist: 'Ilya Repin',
    year: 1875
  },
  {
    title: 'Sadko',
    artist: 'Ilya Repin',
    year: 1876
  },
  {
    title: 'A Shy Peasant',
    artist: 'Ilya Repin',
    year: 1877
  },
  {
    title: 'Follow Me, Satan',
    artist: 'Ilya Repin',
    year: 1895
  },
  {
    title: 'Not Expected',
    artist: 'Ilya Repin',
    year: 1898
  },
  {
    title: 'Group of Four Nudes',
    artist: 'Tamara de Lempicka',
    year: 1925
  },
  {
    title: 'Nude on a Terrace',
    artist: 'Tamara de Lempicka',
    year: 1925
  },
  {
    title: 'Los Dos Amigos',
    artist: 'Tamara de Lempicka',
    year: 1928
  },
  {
    title: 'Self-Portrait in the Green Bugatti',
    artist: 'Tamara de Lempicka',
    year: 1929
  },
  {
    title: 'Women Bathing',
    artist: 'Tamara de Lempicka',
    year: 1929
  },
  {
    title: 'Sleeping Girl',
    artist: 'Tamara de Lempicka',
    year: 1930
  },
  {
    title: 'Young Lady with Gloves',
    artist: 'Tamara de Lempicka',
    year: 1930
  },
  {
    title: 'The Green Turban',
    artist: 'Tamara de Lempicka',
    year: 1930
  },
  {
    title: 'The Girls',
    artist: 'Tamara de Lempicka',
    year: 1930
  },
  {
    title: 'Flowers',
    artist: 'Yayoi Kusama',
    year: 1983
  },
  {
    title: 'Butterfly',
    artist: 'Yayoi Kusama',
    year: 1988
  },
  {
    title: 'Shoes',
    artist: 'Yayoi Kusama',
    year: 1985
  },
  {
    title: 'Dandelions',
    artist: 'Yayoi Kusama',
    year: 1985
  },
  {
    title: 'Fields in Spring',
    artist: 'Yayoi Kusama',
    year: 1988
  },
  {
    title: 'Petals',
    artist: 'Yayoi Kusama',
    year: 1988
  },
  {
    title: 'Watching the Sea',
    artist: 'Yayoi Kusama',
    year: 1989
  },
  {
    title: 'Pumpkin',
    artist: 'Yayoi Kusama',
    year: 1990
  },
  {
    title: 'Mushrooms',
    artist: 'Yayoi Kusama',
    year: 1995
  },
  {
    title: "Chief's Blanket State",
    artist: 'R.C. Gorman',
    year: 1980
  },
  {
    title: 'Winona',
    artist: 'R.C. Gorman',
    year: 1982
  },
  {
    title: 'Blue Corn',
    artist: 'R.C. Gorman',
    year: 1984
  },
  {
    title: 'Zia Jar',
    artist: 'R.C. Gorman',
    year: 1985
  },
  {
    title: 'Secrets',
    artist: 'R.C. Gorman',
    year: 1987
  },
  {
    title: 'Gladia',
    artist: 'R.C. Gorman',
    year: 1990
  },
  {
    title: 'Chimayo Chilis',
    artist: 'R.C. Gorman',
    year: 1992
  },
  {
    title: 'Navajo Return',
    artist: 'R.C. Gorman',
    year: 1997
  },
  {
    title: 'Reverie',
    artist: 'R.C. Gorman',
    year: 2002
  },
  {
    title: 'Moinho Vermelho',
    artist: 'Beatriz Milhazes',
    year: 1999
  },
  {
    title: 'Meu Limão',
    artist: 'Beatriz Milhazes',
    year: 2000
  },
  {
    title: 'South Seas',
    artist: 'Beatriz Milhazes',
    year: 2001
  },
  {
    title: 'Phebo',
    artist: 'Beatriz Milhazes',
    year: 2004
  },
  {
    title: 'Mariposa',
    artist: 'Beatriz Milhazes',
    year: 2004
  },
  {
    title: 'Nega Maluca',
    artist: 'Beatriz Milhazes',
    year: 2006
  },
  {
    title: 'Modinha',
    artist: 'Beatriz Milhazes',
    year: 2007
  },
  {
    title: 'Popeye',
    artist: 'Beatriz Milhazes',
    year: 2008
  },
  {
    title: 'Spring Love',
    artist: 'Beatriz Milhazes',
    year: 2010
  },
  {
    title: 'The Miracle of the Fish',
    artist: 'Adriana Varejão',
    year: 1991
  },
  {
    title: 'East Indies Company Shipwreck',
    artist: 'Adriana Varejão',
    year: 1992
  },
  {
    title: 'Angels',
    artist: 'Adriana Varejão',
    year: 1995
  },
  {
    title: 'Tea and Tiles II',
    artist: 'Adriana Varejão',
    year: 1997
  },
  {
    title: 'Lozenge',
    artist: 'Adriana Varejão',
    year: 1997
  },
  {
    title: 'Cannibal and Nostalgic',
    artist: 'Adriana Varejão',
    year: 1998
  },
  {
    title: 'Mexican Wall',
    artist: 'Adriana Varejão',
    year: 1999
  },
  {
    title: 'Wall',
    artist: 'Adriana Varejão',
    year: 2001
  },
  {
    title: 'Folds 2',
    artist: 'Adriana Varejão',
    year: 2003
  },
  {
    title: 'Dubonnet',
    artist: 'Sonia Delaunay',
    year: 1914
  },
  {
    title: 'Prismes Electriques',
    artist: 'Sonia Delaunay',
    year: 1914
  },
  {
    title: 'Untitled',
    artist: 'Sonia Delaunay',
    year: 1917
  },
  {
    title: 'Fabric Pattern',
    artist: 'Sonia Delaunay',
    year: 1928
  },
  {
    title: 'Untitled Gouache',
    artist: 'Sonia Delaunay',
    year: 1956
  },
  {
    title: 'Greeting Card for Galerie Bing, Paris',
    artist: 'Sonia Delaunay',
    year: 1964
  },
  {
    title: 'Poster for Galerie Bing, Paris',
    artist: 'Sonia Delaunay',
    year: 1964
  },
  {
    title: 'Illustration for Rythmes et Couleurs',
    artist: 'Sonia Delaunay',
    year: 1966
  },
  {
    title: 'Abstract Swirl',
    artist: 'Sonia Delaunay',
    year: 1970
  },
  {
    title: 'Blue Hole, Flood Waters, Little Miami River',
    artist: 'Robert S. Duncanson',
    year: 1851
  },
  {
    title: 'Lanscape with Shepherds',
    artist: 'Robert S. Duncanson',
    year: 1852
  },
  {
    title: 'Landscape with Waterfall',
    artist: 'Robert S. Duncanson',
    year: 1853
  },
  {
    title: 'Pompeii',
    artist: 'Robert S. Duncanson',
    year: 1855
  },
  {
    title: 'Valley Pasture',
    artist: 'Robert S. Duncanson',
    year: 1857
  },
  {
    title: 'Landscape with Rainbow',
    artist: 'Robert S. Duncanson',
    year: 1859
  },
  {
    title: 'A Dream of Italy',
    artist: 'Robert S. Duncanson',
    year: 1865
  },
  {
    title: 'Landscape with Cows Watering in a Stream',
    artist: 'Robert S. Duncanson',
    year: 1871
  },
  {
    title: "Ellen's Isle, Loch Katrine",
    artist: 'Robert S. Duncanson',
    year: 1871
  },
  {
    title: 'Hemlock',
    artist: 'Joan Mitchell',
    year: 1956
  },
  {
    title: 'Strata',
    artist: 'Joan Mitchell',
    year: 1960
  },
  {
    title: 'My Landscape II',
    artist: 'Joan Mitchell',
    year: 1967
  },
  {
    title: 'Mooring',
    artist: 'Joan Mitchell',
    year: 1971
  },
  {
    title: 'Tilleul',
    artist: 'Joan Mitchell',
    year: 1978
  },
  {
    title: 'Bracket',
    artist: 'Joan Mitchell',
    year: 1989
  },
  {
    title: 'Sunflowers',
    artist: 'Joan Mitchell',
    year: 1991
  },
  {
    title: 'Merci',
    artist: 'Joan Mitchell',
    year: 1992
  },
  {
    title: 'Ici',
    artist: 'Joan Mitchell',
    year: 1992
  },
  {
    title: 'This Rain',
    artist: 'Agnes Martin',
    year: 1960
  },
  {
    title: 'The Islands',
    artist: 'Agnes Martin',
    year: 1961
  },
  {
    title: 'Tremolo',
    artist: 'Agnes Martin',
    year: 1962
  },
  {
    title: 'The Tree',
    artist: 'Agnes Martin',
    year: 1964
  },
  {
    title: 'The Peach',
    artist: 'Agnes Martin',
    year: 1964
  },
  {
    title: 'The Harvest',
    artist: 'Agnes Martin',
    year: 1965
  },
  {
    title: 'With My Back to the World',
    artist: 'Agnes Martin',
    year: 1997
  },
  {
    title: 'Faraway Love',
    artist: 'Agnes Martin',
    year: 1999
  },
  {
    title: 'The Sea',
    artist: 'Agnes Martin',
    year: 2003
  },
  {
    title: 'Napoleon Leading the Army Over the Alps',
    artist: 'Kehinde Wiley',
    year: 2005
  },
  {
    title: 'Randerson Romualdo Cordeiro',
    artist: 'Kehinde Wiley',
    year: 2008
  },
  {
    title: 'Femme Piquee par un Serpent',
    artist: 'Kehinde Wiley',
    year: 2008
  },
  {
    title: 'Judith Beheading Holofernes',
    artist: 'Kehinde Wiley',
    year: 2012
  },
  {
    title: 'Shantavia Beale II',
    artist: 'Kehinde Wiley',
    year: 2012
  },
  {
    title: 'John, 1st Baron Byron',
    artist: 'Kehinde Wiley',
    year: 2013
  },
  {
    title: 'Portrait of Mickalene Thomas, the Coyote',
    artist: 'Kehinde Wiley',
    year: 2017
  },
  {
    title: 'Ship of Fools',
    artist: 'Kehinde Wiley',
    year: 2017
  },
  {
    title: 'President Barack Obama',
    artist: 'Kehinde Wiley',
    year: 2018
  },
  {
    title: 'Portrait of Max Ernst',
    artist: 'Leonora Carrington',
    year: 1939
  },
  {
    title: 'Green Tea',
    artist: 'Leonora Carrington',
    year: 1942
  },
  {
    title: 'Neighbourly Advice',
    artist: 'Leonora Carrington',
    year: 1947
  },
  {
    title: 'The Ordeal of Owain',
    artist: 'Leonora Carrington',
    year: 1959
  },
  {
    title: 'Untitled',
    artist: 'Leonora Carrington',
    year: 1960
  },
  {
    title: 'Adieu Ammenotep',
    artist: 'Leonora Carrington',
    year: 1960
  },
  {
    title: 'Spider',
    artist: 'Leonora Carrington',
    year: 1967
  },
  {
    title: 'Bird Bath',
    artist: 'Leonora Carrington',
    year: 1974
  },
  {
    title: 'The Lovers',
    artist: 'Leonora Carrington',
    year: 1987
  },
  {
    title: 'Mother (a portrait of Cate Blanchett)',
    artist: 'Del Kathryn Barton',
    year: 2011
  },
  {
    title: 'And Then',
    artist: 'Del Kathryn Barton',
    year: 2016
  },
  {
    title: 'Small Differences',
    artist: 'Del Kathryn Barton',
    year: 2017
  },
  {
    title: 'This One is For U',
    artist: 'Del Kathryn Barton',
    year: 2018
  },
  {
    title: 'Angry Naked Swimming',
    artist: 'Del Kathryn Barton',
    year: 2018
  },
  {
    title: 'I Take it Down to the Flow',
    artist: 'Del Kathryn Barton',
    year: 2019
  },
  {
    title: 'Kiss Me Mother',
    artist: 'Del Kathryn Barton',
    year: 2019
  },
  {
    title: 'Here With Us....The Pleasure Brain',
    artist: 'Del Kathryn Barton',
    year: 2019
  },
  {
    title: 'Butterfly....She Changed Everything',
    artist: 'Del Kathryn Barton',
    year: 2019
  },
  {
    title: 'The Ten Largest, No. 1, Childhood',
    artist: 'Hilma af Klint',
    year: 1907
  },
  {
    title: 'The Ten Largest, No. 2, Childhood',
    artist: 'Hilma af Klint',
    year: 1907
  },
  {
    title: 'The Ten Largest, No. 3, Youth',
    artist: 'Hilma af Klint',
    year: 1907
  },
  {
    title: 'The Ten Largest, No. 4, Youth',
    artist: 'Hilma af Klint',
    year: 1907
  },
  {
    title: 'The Ten Largest, No. 5, Adulthood',
    artist: 'Hilma af Klint',
    year: 1907
  },
  {
    title: 'The Ten Largest, No. 6',
    artist: 'Hilma af Klint',
    year: 1907
  },
  {
    title: 'The Ten Largest, No. 7, Adulthood',
    artist: 'Hilma af Klint',
    year: 1907
  },
  {
    title: 'The Ten Largest, No. 8, Adulthood',
    artist: 'Hilma af Klint',
    year: 1907
  },
  {
    title: 'The Dove, No. 9',
    artist: 'Hilma af Klint',
    year: 1915
  },
  {
    title: 'Flowers',
    artist: 'Elaine Sturtevant',
    year: 1970
  },
  {
    title: 'Warhol Flowers',
    artist: 'Elaine Sturtevant',
    year: 1970
  },
  {
    title: 'Warhol Marilyns',
    artist: 'Elaine Sturtevant',
    year: 1971
  },
  {
    title: 'Warhol Diptych',
    artist: 'Elaine Sturtevant',
    year: 1973
  },
  {
    title: 'Warhol Gold Marilyn',
    artist: 'Elaine Sturtevant',
    year: 1973
  },
  {
    title: 'Haring Untitled',
    artist: 'Elaine Sturtevant',
    year: 1986
  },
  {
    title: 'Duchamp Wanted',
    artist: 'Elaine Sturtevant',
    year: 1992
  },
  {
    title: 'Stella Rye Beach',
    artist: 'Elaine Sturtevant',
    year: 1988
  },
  {
    title: 'Duchamp Triptych',
    artist: 'Elaine Sturtevant',
    year: 1998
  },
  {
    title: 'Serenade',
    artist: 'Judith Leyster',
    year: 1629
  },
  {
    title: 'Merry Trio',
    artist: 'Judith Leyster',
    year: 1629
  },
  {
    title: 'Young Boy in Profile',
    artist: 'Judith Leyster',
    year: 1630
  },
  {
    title: 'Carousing Couple',
    artist: 'Judith Leyster',
    year: 1630
  },
  {
    title: 'A Game of Tric-Trac',
    artist: 'Judith Leyster',
    year: 1630
  },
  {
    title: 'The Proposition',
    artist: 'Judith Leyster',
    year: 1631
  },
  {
    title: 'A Youth with a Jug',
    artist: 'Judith Leyster',
    year: 1633
  },
  {
    title: 'The Last Drop',
    artist: 'Judith Leyster',
    year: 1639
  },
  {
    title: 'Boy Playing the Flute',
    artist: 'Judith Leyster',
    year: 1640
  },
  {
    title: 'Realms of Existence II',
    artist: 'Beatrice Wanjiku',
    year: 2020
  },
  {
    title: 'Restless in Rest IV',
    artist: 'Beatrice Wanjiku',
    year: 2020
  },
  {
    title: 'Restless in Rest V',
    artist: 'Beatrice Wanjiku',
    year: 2020
  },
  {
    title: 'Losing My Religion II',
    artist: 'Beatrice Wanjiku',
    year: 2020
  },
  {
    title: 'Losing My Religion III',
    artist: 'Beatrice Wanjiku',
    year: 2020
  },
  {
    title: 'Realms of Existence',
    artist: 'Beatrice Wanjiku',
    year: 2020
  },
  {
    title: 'The Sentiment of My Flesh VI',
    artist: 'Beatrice Wanjiku',
    year: 2022
  },
  {
    title: 'Resume Your Flesh and Form XI',
    artist: 'Beatrice Wanjiku',
    year: 2022
  },
  {
    title: 'Dualities of Excess and Repression IV',
    artist: 'Beatrice Wanjiku',
    year: 2022
  },
  {
    title: 'Snowwhite in the Wrong Story',
    artist: 'Marlene Dumas',
    year: 1988
  },
  {
    title: 'Faceless',
    artist: 'Marlene Dumas',
    year: 1993
  },
  {
    title: 'The Painter',
    artist: 'Marlene Dumas',
    year: 1994
  },
  {
    title: 'The Visitor',
    artist: 'Marlene Dumas',
    year: 1995
  },
  {
    title: 'Super Model',
    artist: 'Marlene Dumas',
    year: 1995
  },
  {
    title: 'Naomi',
    artist: 'Marlene Dumas',
    year: 1995
  },
  {
    title: 'The Fog of War - Friendly Fire',
    artist: 'Marlene Dumas',
    year: 2006
  },
  {
    title: 'The Present One',
    artist: 'Marlene Dumas',
    year: 2013
  },
  {
    title: 'Skulls',
    artist: 'Marlene Dumas',
    year: 2015
  },
  {
    title: 'Horse in a Landscape',
    artist: 'Franz Marc',
    year: 1910
  },
  {
    title: 'The Large Blue Horses',
    artist: 'Franz Marc',
    year: 1911
  },
  {
    title: 'The Yellow Cow',
    artist: 'Franz Marc',
    year: 1911
  },
  {
    title: 'Fox',
    artist: 'Franz Marc',
    year: 1911
  },
  {
    title: 'Dog Lying in the Snow',
    artist: 'Franz Marc',
    year: 1911
  },
  {
    title: 'Little Yellow Horses',
    artist: 'Franz Marc',
    year: 1912
  },
  {
    title: 'Tiger',
    artist: 'Franz Marc',
    year: 1912
  },
  {
    title: 'The Foxes',
    artist: 'Franz Marc',
    year: 1913
  },
  {
    title: 'Deer in the Forest',
    artist: 'Franz Marc',
    year: 1913
  },
  {
    title: 'Fighting Forms',
    artist: 'Franz Marc',
    year: 1914
  },
  {
    title: 'Tyrol',
    artist: 'Franz Marc',
    year: 1914
  },
  {
    title: 'Landscape with Ruins',
    artist: 'Hubert Robert',
    year: 1772
  },
  {
    title: 'The Old Bridge',
    artist: 'Hubert Robert',
    year: 1775
  },
  {
    title: 'The Mouth of a Cave',
    artist: 'Hubert Robert',
    year: 1784
  },
  {
    title: 'The Pont du Gard',
    artist: 'Hubert Robert',
    year: 1787
  },
  {
    title: 'The Old Temple',
    artist: 'Hubert Robert',
    year: 1788
  },
  {
    title: 'The Washer Women',
    artist: 'Hubert Robert',
    year: 1792
  },
  {
    title: 'The Grande Galerie',
    artist: 'Hubert Robert',
    year: 1795
  },
  {
    title: 'The Bathing Pool',
    artist: 'Hubert Robert',
    year: 1808
  },
  {
    title: 'The Ancient Temple',
    artist: 'Hubert Robert',
    year: 1808
  },
  {
    title: 'The Emancipation Approximation',
    artist: 'Kara Walker',
    year: 1999
  },
  {
    title: "Gone: An Historical Romance of a Civil War as It Occurred b'tween the Dusky Thighs of One Young Negress and Her Heart",
    artist: 'Kara Walker',
    year: 1994
  },
  {
    title: 'Resurrection Story with Patrons',
    artist: 'Kara Walker',
    year: 2017
  },
  {
    title: 'Salvation',
    artist: 'Kara Walker',
    year: 2000
  },
  {
    title: 'The Rich Soil Down There',
    artist: 'Kara Walker',
    year: 2002
  },
  {
    title: 'They Waz Nice White Folks While They Lasted',
    artist: 'Kara Walker',
    year: 2001
  },
  {
    title: "Scene of McPherson's Death",
    artist: 'Kara Walker',
    year: 2005
  },
  {
    title: 'Alabama Loyalists Greeting the Federal Gun-Boats',
    artist: 'Kara Walker',
    year: 2005
  },
  {
    title: 'The Means to an End...A Shadow Drama in Five Acts',
    artist: 'Kara Walker',
    year: 2005
  },
  {
    title: 'Figures',
    artist: 'June Leaf',
    year: 1949
  },
  {
    title: 'The Salon',
    artist: 'June Leaf',
    year: 1965
  },
  {
    title: 'Murder in the Bronx',
    artist: 'June Leaf',
    year: 1967
  },
  {
    title: 'Coney Island',
    artist: 'June Leaf',
    year: 1968
  },
  {
    title: 'The Girl with the Hoop',
    artist: 'June Leaf',
    year: 1980
  },
  {
    title: 'Self-Portrait',
    artist: 'June Leaf',
    year: 2006
  },
  {
    title: 'Second Skeleton',
    artist: 'June Leaf',
    year: 2010
  },
  {
    title: 'Making #2',
    artist: 'June Leaf',
    year: 2015
  },
  {
    title: 'Da-Dandy',
    artist: 'Hannah Höch',
    year: 1919
  },
  {
    title: 'Cut with the Dada Kitchen Knife through the Last Weimar Beer-Belly Cultural Epoch in Germany',
    artist: 'Hannah Höch',
    year: 1919
  },
  {
    title: 'The Beautiful Girl',
    artist: 'Hannah Höch',
    year: 1920
  },
  {
    title: 'From the Collection: From an Ethnographic Museum',
    artist: 'Hannah Höch',
    year: 1929
  },
  {
    title: 'Ohne Titel (Aus einem ethnographischen Museum)',
    artist: 'Hannah Höch',
    year: 1930
  },
  {
    title: "Untitled (Large Hand Over Woman's Head)",
    artist: 'Hannah Höch',
    year: 1930
  },
  {
    title: 'Flight',
    artist: 'Hannah Höch',
    year: 1931
  },
  {
    title: 'Made for a Party',
    artist: 'Hannah Höch',
    year: 1936
  },
  {
    title: 'Little Sun',
    artist: 'Hannah Höch',
    year: 1969
  },
  {
    title: 'Awabi Divers',
    artist: 'Utamaro',
    year: 1788
  },
  {
    title: 'Inleiding',
    artist: 'Utamaro',
    year: 1788
  },
  {
    title: 'Three Beauties of the Present Day',
    artist: 'Utamaro',
    year: 1793
  },
  {
    title: 'Oiran Hanaogi',
    artist: 'Utamaro',
    year: 1794
  },
  {
    title: 'Preparing Raw Fish',
    artist: 'Utamaro',
    year: 1799
  },
  {
    title: 'Prelude to Desire',
    artist: 'Utamaro',
    year: 1799
  },
  {
    title: 'Pearl Divers',
    artist: 'Utamaro',
    year: 1802
  },
  {
    title: 'Mochi Maken',
    artist: 'Utamaro',
    year: 1804
  },
  {
    title: 'Print',
    artist: 'Utamaro',
    year: 1806
  },
  {
    title: 'Birth of Krishna',
    artist: 'Abanindranath Tagore',
    year: 1897
  },
  {
    title: 'Bharat Mata',
    artist: 'Abanindranath Tagore',
    year: 1905
  },
  {
    title: "Asoka's Queen",
    artist: 'Abanindranath Tagore',
    year: 1910
  },
  {
    title: 'The Captive Hero',
    artist: 'Abanindranath Tagore',
    year: 1914
  },
  {
    title: 'Crane',
    artist: 'Abanindranath Tagore',
    year: 1916
  },
  {
    title: "Barbar's Home",
    artist: 'Abanindranath Tagore',
    year: 1927
  },
  {
    title: 'The Rhynoceros',
    artist: 'Abanindranath Tagore',
    year: 1938
  },
  {
    title: 'The Sword Fish',
    artist: 'Abanindranath Tagore',
    year: 1938
  },
  {
    title: 'The Snipes',
    artist: 'Abanindranath Tagore',
    year: 1939
  },
  {
    title: 'Hungarian Gypsy',
    artist: 'Amrita Sher-Gil',
    year: 1932
  },
  {
    title: 'Sleeping Woman',
    artist: 'Amrita Sher-Gil',
    year: 1933
  },
  {
    title: 'Mother India',
    artist: 'Amrita Sher-Gil',
    year: 1935
  },
  {
    title: 'Three Girls',
    artist: 'Amrita Sher-Gil',
    year: 1935
  },
  {
    title: 'Sumair',
    artist: 'Amrita Sher-Gil',
    year: 1936
  },
  {
    title: "Bride's Toilet",
    artist: 'Amrita Sher-Gil',
    year: 1937
  },
  {
    title: 'Brahmacharis',
    artist: 'Amrita Sher-Gil',
    year: 1937
  },
  {
    title: 'Ancient Story Teller',
    artist: 'Amrita Sher-Gil',
    year: 1940
  },
  {
    title: 'Two Elephants',
    artist: 'Amrita Sher-Gil',
    year: 1940
  },
  {
    title: 'Blue Hat',
    artist: 'Tarsila do Amaral',
    year: 1922
  },
  {
    title: 'A Negra',
    artist: 'Tarsila do Amaral',
    year: 1923
  },
  {
    title: 'A Cuca',
    artist: 'Tarsila do Amaral',
    year: 1924
  },
  {
    title: 'An Angler',
    artist: 'Tarsila do Amaral',
    year: 1925
  },
  {
    title: 'Manaca',
    artist: 'Tarsila do Amaral',
    year: 1927
  },
  {
    title: 'Abaporu',
    artist: 'Tarsila do Amaral',
    year: 1928
  },
  {
    title: 'The Egg',
    artist: 'Tarsila do Amaral',
    year: 1928
  },
  {
    title: 'Postcard',
    artist: 'Tarsila do Amaral',
    year: 1928
  },
  {
    title: 'Workers',
    artist: 'Tarsila do Amaral',
    year: 1933
  },
  {
    title: 'Mountain Landscape',
    artist: 'Wen Zhengming',
    year: 1522
  },
  {
    title: 'In the Deep Shade of a Bamboo Grove',
    artist: 'Wen Zhengming',
    year: 1527
  },
  {
    title: 'Old Trees by a Cold Waterfall',
    artist: 'Wen Zhengming',
    year: 1531
  },
  {
    title: 'Poems on Two Sounds in Running Script',
    artist: 'Wen Zhengming',
    year: 1550
  },
  {
    title: "Humble Administrator's Garden",
    artist: 'Wen Zhengming',
    year: 1551
  },
  {
    title: 'Edward and Sarah Rutter',
    artist: 'Joshua Johnson',
    year: 1805
  },
  {
    title: 'Emma Van Name',
    artist: 'Joshua Johnson',
    year: 1805
  },
  {
    title: 'Sarah Ogden Gustin',
    artist: 'Joshua Johnson',
    year: 1805
  },
  {
    title: 'Portrait of Sea Captain John Murphy',
    artist: 'Joshua Johnson',
    year: 1810
  },
  {
    title: 'Portrait of Mrs. Barbara Baker Murphy',
    artist: 'Joshua Johnson',
    year: 1810
  },
  {
    title: 'Adelina Morton',
    artist: 'Joshua Johnson',
    year: 1810
  },
  {
    title: 'John Jacob Anderson and Sons, John and Edward',
    artist: 'Joshua Johnson',
    year: 1815
  },
  {
    title: 'Portrait of a Girl',
    artist: 'Joshua Johnson',
    year: 1815
  },
  {
    title: 'The Annunciation',
    artist: 'Henry Ossawa Tanner',
    year: 1898
  },
  {
    title: 'The Thankful Poor',
    artist: 'Henry Ossawa Tanner',
    year: 1894
  },
  {
    title: 'The Young Sabot Maker',
    artist: 'Henry Ossawa Tanner',
    year: 1895
  },
  {
    title: 'The Good Shepard',
    artist: 'Henry Ossawa Tanner',
    year: 1903
  },
  {
    title: "Abraham's Oak",
    artist: 'Henry Ossawa Tanner',
    year: 1905
  },
  {
    title: 'Gateway, Tangiers',
    artist: 'Henry Ossawa Tanner',
    year: 1912
  },
  {
    title: 'Flight into Egypt',
    artist: 'Henry Ossawa Tanner',
    year: 1923
  },
  {
    title: 'Destruction of Sodom and Gomorrah',
    artist: 'Henry Ossawa Tanner',
    year: 1929
  },
  {
    title: 'Bar and Grill',
    artist: 'Jacob Lawrence',
    year: 1941
  },
  {
    title: 'Tombstones',
    artist: 'Jacob Lawrence',
    year: 1942
  },
  {
    title: 'Pool Parlor',
    artist: 'Jacob Lawrence',
    year: 1942
  },
  {
    title: 'The Apartment',
    artist: 'Jacob Lawrence',
    year: 1943
  },
  {
    title: 'The Shoemaker',
    artist: 'Jacob Lawrence',
    year: 1945
  },
  {
    title: 'The Seamstress',
    artist: 'Jacob Lawrence',
    year: 1946
  },
  {
    title: 'The Builders',
    artist: 'Jacob Lawrence',
    year: 1947
  },
  {
    title: 'The Library',
    artist: 'Jacob Lawrence',
    year: 1960
  },
  {
    title: 'Taboo',
    artist: 'Jacob Lawrence',
    year: 1963
  },
  {
    title: 'Untitled II',
    artist: 'Iona Rozeal Brown',
    year: 2002
  },
  {
    title: 'A3 #1',
    artist: 'Iona Rozeal Brown',
    year: 2002
  },
  {
    title: 'Blackface No. 47',
    artist: 'Iona Rozeal Brown',
    year: 2003
  },
  {
    title: 'Untitled II (Male)',
    artist: 'Iona Rozeal Brown',
    year: 2003
  },
  {
    title: 'A3 Blackface No. 3',
    artist: 'Iona Rozeal Brown',
    year: 2004
  },
  {
    title: 'A3 Blackface No. 79',
    artist: 'Iona Rozeal Brown',
    year: 2004
  },
  {
    title: 'Dangerous, the Appearance of a Contemporary',
    artist: 'Iona Rozeal Brown',
    year: 2005
  },
  {
    title: 'Dilapidated but Dipped: A Father’s Curse, a Repeated Wily Joke, 2007',
    artist: 'Iona Rozeal Brown',
    year: 2007
  },
  {
    title: 'Browndemics: Lessons from Us',
    artist: 'Iona Rozeal Brown',
    year: 2007
  },
  {
    title: 'Popcorn Shells',
    artist: 'Chris Ofili',
    year: 1995
  },
  {
    title: 'The Holy Virgin Mary',
    artist: 'Chris Ofili',
    year: 1996
  },
  {
    title: 'Double Captain Shit and the Legend of the Black Stars',
    artist: 'Chris Ofili',
    year: 1997
  },
  {
    title: 'No Woman No Cry',
    artist: 'Chris Ofili',
    year: 1998
  },
  {
    title: 'The Upper Room: Mono Gris',
    artist: 'Chris Ofili',
    year: 2002
  },
  {
    title: 'The Upper Room: Mono Turquesa',
    artist: 'Chris Ofili',
    year: 2002
  },
  {
    title: 'The Upper Room: Mono Azul',
    artist: 'Chris Ofili',
    year: 2002
  },
  {
    title: 'The Upper Room: Mono Amarillo',
    artist: 'Chris Ofili',
    year: 2002
  },
  {
    title: 'The Upper Room: Mono Negro',
    artist: 'Chris Ofili',
    year: 2002
  },
  {
    title: 'Montreux',
    artist: 'Keith Haring',
    year: 1983
  },
  {
    title: 'Free South Africa',
    artist: 'Keith Haring',
    year: 1985
  },
  {
    title: 'Crack is Wack',
    artist: 'Keith Haring',
    year: 1986
  },
  {
    title: 'Andy Mouse',
    artist: 'Keith Haring',
    year: 1986
  },
  {
    title: 'Pop Shop 1',
    artist: 'Keith Haring',
    year: 1987
  },
  {
    title: 'Safe Sex',
    artist: 'Keith Haring',
    year: 1988
  },
  {
    title: 'Tuttomondo',
    artist: 'Keith Haring',
    year: 1989
  },
  {
    title: 'Ignorance = Fear',
    artist: 'Keith Haring',
    year: 1989
  },
  {
    title: 'Radiant Baby',
    artist: 'Keith Haring',
    year: 1990
  },
  {
    title: 'La vraie carte du Monde',
    artist: 'Chéri Samba',
    year: 2014
  },
  {
    title: "J'aime le jeu de relais",
    artist: 'Chéri Samba',
    year: 2018
  },
  {
    title: 'Tout mécontent au monde est gilet jaune',
    artist: 'Chéri Samba',
    year: 2019
  },
  {
    title: 'Tout mécontent au monde est gilet jaune',
    artist: 'Chéri Samba',
    year: 2019
  },
  {
    title: 'Devoir à domicile',
    artist: 'Chéri Samba',
    year: 2020
  },
  {
    title: "L'intelligence aveugle",
    artist: 'Chéri Samba',
    year: 2020
  },
  {
    title: 'Je ne suis pas aimé',
    artist: 'Chéri Samba',
    year: 2020
  },
  {
    title: 'Le risque du métier',
    artist: 'Chéri Samba',
    year: 2020
  },
  {
    title: 'Les bonnes relations',
    artist: 'Chéri Samba',
    year: 2020
  },
  {
    title: 'The Buffalo Hunt',
    artist: 'Horace Pippin',
    year: 1933
  },
  {
    title: 'The Wash',
    artist: 'Horace Pippin',
    year: 1940
  },
  {
    title: 'Giving Thanks',
    artist: 'Horace Pippin',
    year: 1942
  },
  {
    title: 'Victory Garden',
    artist: 'Horace Pippin',
    year: 1943
  },
  {
    title: 'Mr. Prejudice',
    artist: 'Horace Pippin',
    year: 1943
  },
  {
    title: 'Domino Players',
    artist: 'Horace Pippin',
    year: 1943
  },
  {
    title: 'The Barracks',
    artist: 'Horace Pippin',
    year: 1945
  },
  {
    title: 'Holy Mountain III',
    artist: 'Horace Pippin',
    year: 1945
  },
  {
    title: 'Man on a Bench',
    artist: 'Horace Pippin',
    year: 1946
  },
  {
    title: 'Alien Awe I',
    artist: 'Wangechi Mutu',
    year: 2003
  },
  {
    title: 'Untitled',
    artist: 'Wangechi Mutu',
    year: 2004
  },
  {
    title: 'Misguided Little Unforgiveable Hierarchies',
    artist: 'Wangechi Mutu',
    year: 2005
  },
  {
    title: 'Cassandra',
    artist: 'Wangechi Mutu',
    year: 2007
  },
  {
    title: 'Homeward Bound',
    artist: 'Wangechi Mutu',
    year: 2010
  },
  {
    title: 'Dutty Water',
    artist: 'Wangechi Mutu',
    year: 2013
  },
  {
    title: 'The End of Carrying All',
    artist: 'Wangechi Mutu',
    year: 2015
  },
  {
    title: 'Chocolate Nguva',
    artist: 'Wangechi Mutu',
    year: 2015
  },
  {
    title: 'Eleven Secrets',
    artist: 'Wangechi Mutu',
    year: 2015
  },
  {
    title: 'Castine Harbor and Town',
    artist: 'Fitz Henry Lane',
    year: 1851
  },
  {
    title: 'Brig Off the Maine Coast',
    artist: 'Fitz Henry Lane',
    year: 1851
  },
  {
    title: 'New York Harbor',
    artist: 'Fitz Henry Lane',
    year: 1852
  },
  {
    title: 'Boston Harbor, Sunset',
    artist: 'Fitz Henry Lane',
    year: 1855
  },
  {
    title: '"Starlight" in Harbor',
    artist: 'Fitz Henry Lane',
    year: 1855
  },
  {
    title: 'Boston Harbor',
    artist: 'Fitz Henry Lane',
    year: 1856
  },
  {
    title: 'Lumber Schooners at Evening on Penobscot Bay',
    artist: 'Fitz Henry Lane',
    year: 1860
  },
  {
    title: 'Becalmed off Halfway Rock',
    artist: 'Fitz Henry Lane',
    year: 1860
  },
  {
    title: 'Stage Fort Across Gloucester Harbor',
    artist: 'Fitz Henry Lane',
    year: 1862
  },
  {
    title: 'Bather at a Mountain Stream',
    artist: 'Hermann Scherer',
    year: 1923
  },
  {
    title: 'Drei Personen am Tisch',
    artist: 'Hermann Scherer',
    year: 1924
  },
  {
    title: 'Waldlandschaft',
    artist: 'Hermann Scherer',
    year: 1925
  },
  {
    title: 'Blauer Wald',
    artist: 'Hermann Scherer',
    year: 1925
  },
  {
    title: 'Mendrisiotto with Church of Obino',
    artist: 'Hermann Scherer',
    year: 1926
  },
  {
    title: 'Mendrisiotto',
    artist: 'Hermann Scherer',
    year: 1926
  },
  {
    title: 'Mannerportrait',
    artist: 'Hermann Scherer',
    year: 1926
  },
  {
    title: 'Selbstbildnis in Landschaft',
    artist: 'Hermann Scherer',
    year: 1926
  },
  {
    title: 'A Long Way from Home',
    artist: 'Aaron Douglas',
    year: 1937
  },
  {
    title: 'Let My People Go',
    artist: 'Aaron Douglas',
    year: 1939
  },
  {
    title: 'The Judgement Day',
    artist: 'Aaron Douglas',
    year: 1939
  },
  {
    title: 'Aspirations',
    artist: 'Aaron Douglas',
    year: 1936
  },
  {
    title: 'Into Bondage',
    artist: 'Aaron Douglas',
    year: 1936
  },
  {
    title: 'The Creation',
    artist: 'Aaron Douglas',
    year: 1935
  },
  {
    title: 'Song of the Towers',
    artist: 'Aaron Douglas',
    year: 1934
  },
  {
    title: 'Go Down Death',
    artist: 'Aaron Douglas',
    year: 1934
  },
  {
    title: 'Building More Stately Mansions',
    artist: 'Aaron Douglas',
    year: 1944
  },
  {
    title: 'Reeds',
    artist: 'William Kentridge',
    year: 1997
  },
  {
    title: 'Art in a State of Grace',
    artist: 'William Kentridge',
    year: 2000
  },
  {
    title: 'Art in a State of Siege',
    artist: 'William Kentridge',
    year: 2000
  },
  {
    title: 'Art in a State of Hope',
    artist: 'William Kentridge',
    year: 2000
  },
  {
    title: 'North Pole Map',
    artist: 'William Kentridge',
    year: 2003
  },
  {
    title: 'Triumphs and Laments Procession Silhouette 6',
    artist: 'William Kentridge',
    year: 2016
  },
  {
    title: 'Study',
    artist: 'Michael Armitage',
    year: 2012
  },
  {
    title: 'Kampala Suburb',
    artist: 'Michael Armitage',
    year: 2014
  },
  {
    title: 'Mangroves Dip',
    artist: 'Michael Armitage',
    year: 2015
  },
  {
    title: 'Diamond Platnumz',
    artist: 'Michael Armitage',
    year: 2016
  },
  {
    title: 'Conjestina',
    artist: 'Michael Armitage',
    year: 2017
  },
  {
    title: 'The Paradise Edict',
    artist: 'Michael Armitage',
    year: 2019
  },
  {
    title: 'Mkokoteni',
    artist: 'Michael Armitage',
    year: 2019
  },
  {
    title: 'The Accomplice',
    artist: 'Michael Armitage',
    year: 2019
  },
  {
    title: 'Dream and Refuge',
    artist: 'Michael Armitage',
    year: 2020
  },
  {
    title: 'Untitled',
    artist: 'Njideka Akunyili Crosby',
    year: 2011
  },
  {
    title: 'Janded',
    artist: 'Njideka Akunyili Crosby',
    year: 2012
  },
  {
    title: 'Nwantini',
    artist: 'Njideka Akunyili Crosby',
    year: 2012
  },
  {
    title: 'Harmattan Haze',
    artist: 'Njideka Akunyili Crosby',
    year: 2014
  },
  {
    title: 'Mama, Mummy and Mamma',
    artist: 'Njideka Akunyili Crosby',
    year: 2014
  },
  {
    title: 'Cassave Garden',
    artist: 'Njideka Akunyili Crosby',
    year: 2015
  },
  {
    title: 'In the Lavender Room',
    artist: 'Njideka Akunyili Crosby',
    year: 2019
  },
  {
    title: 'Al Araba Al Madfuna',
    artist: 'Wael Shaky',
    year: 2019
  },
  {
    title: 'Al Araba Al Madfuna, Drawing',
    artist: 'Wael Shaky',
    year: 2019
  },
  {
    title: 'Al Araba Al Madfuna, Painting',
    artist: 'Wael Shaky',
    year: 2019
  },
  {
    title: 'Untitled #1001',
    artist: 'Wael Shaky',
    year: 2022
  },
  {
    title: 'Untitled #1002',
    artist: 'Wael Shaky',
    year: 2022
  },
  {
    title: 'Untitled #1003',
    artist: 'Wael Shaky',
    year: 2022
  },
  {
    title: 'Untitled #1004',
    artist: 'Wael Shaky',
    year: 2022
  },
  {
    title: 'Untitled #1005',
    artist: 'Wael Shaky',
    year: 2022
  },
  {
    title: 'The Myth of Sisyphe',
    artist: 'Abel Abdessemed',
    year: 1996
  },
  {
    title: 'Stonehenge',
    artist: 'Abel Abdessemed',
    year: 2014
  },
  {
    title: 'Chicos',
    artist: 'Abel Abdessemed',
    year: 2015
  },
  {
    title: 'La Tendresse du Tigre',
    artist: 'Abel Abdessemed',
    year: 2015
  },
  {
    title: 'Trabajo',
    artist: 'Abel Abdessemed',
    year: 2015
  },
  {
    title: 'Forbidden Colours',
    artist: 'Abel Abdessemed',
    year: 2018
  },
  {
    title: 'Cocorico - Chinese Painting',
    artist: 'Abel Abdessemed',
    year: 2018
  },
  {
    title: 'Politics of Drawing, Hamelin',
    artist: 'Abel Abdessemed',
    year: 2020
  },
  {
    title: 'Politics of Drawing, Coup de Sabot',
    artist: 'Abel Abdessemed',
    year: 2021
  },
  {
    title: 'We are Here Tonight to Celebrate Our Unknown Ancestors',
    artist: 'Basim Magdy',
    year: 2009
  },
  {
    title: 'Several Molecules of Future Liberties Manifesting as Baby Miracles',
    artist: 'Basim Magdy',
    year: 2020
  },
  {
    title: 'A Desperate Epiphany Led Us to the Elusive Phantome of Tangled Truths',
    artist: 'Basim Magdy',
    year: 2020
  },
  {
    title: 'And the Land was Populated with Curiosities and Instantly Forgotten Dreams',
    artist: 'Basim Magdy',
    year: 2020
  },
  {
    title: 'The Man Who Stole a Bone from the Sahara Confronted by the Shadow of War',
    artist: 'Basim Magdy',
    year: 2020
  },
  {
    title: 'Eternity is Nothing but a Geometric Void',
    artist: 'Basim Magdy',
    year: 2020
  },
  {
    title: 'Three Dinosaur Bones Disguised as Extinct Birds',
    artist: 'Basim Magdy',
    year: 2020
  },
  {
    title: 'Talking to the Moon I',
    artist: 'Barthélémy Toguo',
    year: 2013
  },
  {
    title: 'Grand Vase Charpin',
    artist: 'Barthélémy Toguo',
    year: 2016
  },
  {
    title: 'Deluge I',
    artist: 'Barthélémy Toguo',
    year: 2016
  },
  {
    title: 'Silence III',
    artist: 'Barthélémy Toguo',
    year: 2017
  },
  {
    title: 'The Canopy Man',
    artist: 'Barthélémy Toguo',
    year: 2018
  },
  {
    title: 'Eating Juicy',
    artist: 'Barthélémy Toguo',
    year: 2018
  },
  {
    title: 'Homo Planta B',
    artist: 'Barthélémy Toguo',
    year: 2018
  },
  {
    title: 'If Not Now When?',
    artist: 'Barthélémy Toguo',
    year: 2019
  },
  {
    title: 'Black and White',
    artist: 'Amoako Boafo',
    year: 2018
  },
  {
    title: 'Cobalt Blue Earring',
    artist: 'Amoako Boafo',
    year: 2019
  },
  {
    title: 'Yaw Abedi',
    artist: 'Amoako Boafo',
    year: 2019
  },
  {
    title: 'Tassel Earrings',
    artist: 'Amoako Boafo',
    year: 2019
  },
  {
    title: 'Desahnya',
    artist: 'Amoako Boafo',
    year: 2019
  },
  {
    title: 'Bailike',
    artist: 'Amoako Boafo',
    year: 2019
  },
  {
    title: 'Jordan Mbuyamba',
    artist: 'Amoako Boafo',
    year: 2020
  },
  {
    title: 'White Cap',
    artist: 'Amoako Boafo',
    year: 2020
  },
  {
    title: 'Aurore Iradukunda',
    artist: 'Amoako Boafo',
    year: 2020
  },
  {
    title: 'Untitled',
    artist: 'Aboudia',
    year: 2011
  },
  {
    title: 'Untitled',
    artist: 'Aboudia',
    year: 2013
  },
  {
    title: 'The Solution',
    artist: 'Aboudia',
    year: 2015
  },
  {
    title: 'Vert a Levres',
    artist: 'Aboudia',
    year: 2015
  },
  {
    title: 'Les Yeux Pop',
    artist: 'Aboudia',
    year: 2017
  },
  {
    title: 'La Grande Famille',
    artist: 'Aboudia',
    year: 2017
  },
  {
    title: 'Untitled',
    artist: 'Aboudia',
    year: 2020
  },
  {
    title: 'Les 3 Savants',
    artist: 'Aboudia',
    year: 2021
  },
  {
    title: 'The Chalice #7',
    artist: 'Ibrahim El-Salahi',
    year: 1964
  },
  {
    title: 'The Embroyo, the Child and the Bird #6',
    artist: 'Ibrahim El-Salahi',
    year: 1964
  },
  {
    title: 'The Last Sound',
    artist: 'Ibrahim El-Salahi',
    year: 1964
  },
  {
    title: 'Qatar-London #1',
    artist: 'Ibrahim El-Salahi',
    year: 1994
  },
  {
    title: 'Qatar-London #25',
    artist: 'Ibrahim El-Salahi',
    year: 1994
  },
  {
    title: 'The Tree',
    artist: 'Ibrahim El-Salahi',
    year: 2001
  },
  {
    title: 'Flamenco Dancers',
    artist: 'Ibrahim El-Salahi',
    year: 2011
  },
  {
    title: 'Forms VI',
    artist: 'Ibrahim El-Salahi',
    year: 2012
  },
  {
    title: 'Les Maitres du Temps 1',
    artist: 'Rachid Koraïchi',
    year: 2018
  },
  {
    title: 'Les Maitres du Temps 12',
    artist: 'Rachid Koraïchi',
    year: 2018
  },
  {
    title: 'Les Maitres du Temps 8',
    artist: 'Rachid Koraïchi',
    year: 2018
  },
  {
    title: 'La Montagne aux Etoiles XIV',
    artist: 'Rachid Koraïchi',
    year: 2021
  },
  {
    title: 'La Montagne aux Etoiles XX',
    artist: 'Rachid Koraïchi',
    year: 2021
  },
  {
    title: 'La Montagne aux Etoiles XV',
    artist: 'Rachid Koraïchi',
    year: 2021
  },
  {
    title: 'La Montagne aux Etoiles VII',
    artist: 'Rachid Koraïchi',
    year: 2021
  },
  {
    title: 'La Montagne aux Etoiles III',
    artist: 'Rachid Koraïchi',
    year: 2021
  },
  {
    title: 'La Montagne aux Etoiles III',
    artist: 'Rachid Koraïchi',
    year: 2021
  },
  {
    title: 'Untitled',
    artist: 'Mohammad Melehi',
    year: 2014
  },
  {
    title: 'Ranked Heads',
    artist: 'Misheck Masamvu',
    year: 2016
  },
  {
    title: 'Silent Conversations',
    artist: 'Misheck Masamvu',
    year: 2018
  },
  {
    title: 'Chewed Memory',
    artist: 'Misheck Masamvu',
    year: 2018
  },
  {
    title: 'Swings in the Wind',
    artist: 'Misheck Masamvu',
    year: 2018
  },
  {
    title: 'Mind Corridors',
    artist: 'Misheck Masamvu',
    year: 2019
  },
  {
    title: 'Benediction',
    artist: 'Misheck Masamvu',
    year: 2019
  },
  {
    title: 'Straight Street',
    artist: 'Misheck Masamvu',
    year: 2019
  },
  {
    title: 'Counting Coins',
    artist: 'Misheck Masamvu',
    year: 2019
  },
  {
    title: 'Natural Glow',
    artist: 'Misheck Masamvu',
    year: 2019
  },
  {
    title: 'Sky Above Manhattan',
    artist: 'Mohammad Melehi',
    year: 1963
  },
  {
    title: 'Moucharabieh, Grey on Black',
    artist: 'Mohammad Melehi',
    year: 2020
  },
  {
    title: 'Moucharabieh, Blue on Black',
    artist: 'Mohammad Melehi',
    year: 2020
  },
  {
    title: 'Moucharabieh, Ocher and Orange',
    artist: 'Mohammad Melehi',
    year: 2020
  },
  {
    title: 'Moucharabieh in Blue',
    artist: 'Mohammad Melehi',
    year: 2020
  },
  {
    title: 'Moucharabieh in Silver and Yellow',
    artist: 'Mohammad Melehi',
    year: 2020
  },
  {
    title: 'Moucharabieh in Grey',
    artist: 'Mohammad Melehi',
    year: 2020
  },
  {
    title: 'Arabian Moucharabieh',
    artist: 'Mohammad Melehi',
    year: 2020
  },
  {
    title: 'In Search Of',
    artist: 'Simphiwe Ndzube',
    year: 2016
  },
  {
    title: 'Untitled',
    artist: 'Simphiwe Ndzube',
    year: 2017
  },
  {
    title: 'Limbo State 2',
    artist: 'Simphiwe Ndzube',
    year: 2017
  },
  {
    title: 'Untitled',
    artist: 'Simphiwe Ndzube',
    year: 2018
  },
  {
    title: 'Gravedigger and Youth',
    artist: 'Simphiwe Ndzube',
    year: 2019
  },
  {
    title: 'In the Silence of the Drum',
    artist: 'Simphiwe Ndzube',
    year: 2020
  },
  {
    title: 'Madolo and the Aardwolf',
    artist: 'Simphiwe Ndzube',
    year: 2020
  },
  {
    title: 'Iqhawe',
    artist: 'Simphiwe Ndzube',
    year: 2021
  },
  {
    title: 'Yet to be Tilted',
    artist: 'Simphiwe Ndzube',
    year: 2021
  },
  {
    title: 'In Between',
    artist: 'Odili Donald Odita',
    year: 2002
  },
  {
    title: 'Found',
    artist: 'Odili Donald Odita',
    year: 2010
  },
  {
    title: 'Highway',
    artist: 'Odili Donald Odita',
    year: 2015
  },
  {
    title: "Van Gogh's Trees",
    artist: 'Odili Donald Odita',
    year: 2016
  },
  {
    title: 'Cut',
    artist: 'Odili Donald Odita',
    year: 2016
  },
  {
    title: 'Heat Wave',
    artist: 'Odili Donald Odita',
    year: 2018
  },
  {
    title: 'Dukes of Hazard',
    artist: 'Odili Donald Odita',
    year: 2018
  },
  {
    title: 'Fire',
    artist: 'Odili Donald Odita',
    year: 2019
  },
  {
    title: 'Origin Story',
    artist: 'Odili Donald Odita',
    year: 2022
  },
  {
    title: 'Two Piece 1',
    artist: 'Cinga Samson',
    year: 2018
  },
  {
    title: 'Two Piece 2',
    artist: 'Cinga Samson',
    year: 2018
  },
  {
    title: 'Ivory (i)',
    artist: 'Cinga Samson',
    year: 2018
  },
  {
    title: 'iRhorho 8',
    artist: 'Cinga Samson',
    year: 2019
  },
  {
    title: 'iRhorho 7',
    artist: 'Cinga Samson',
    year: 2019
  },
  {
    title: 'iRhorho 9',
    artist: 'Cinga Samson',
    year: 2019
  },
  {
    title: 'Shame Series',
    artist: 'Penny Siopis',
    year: 2003
  },
  {
    title: 'Shame',
    artist: 'Penny Siopis',
    year: 2004
  },
  {
    title: "Julia's Friend",
    artist: 'Penny Siopis',
    year: 2007
  },
  {
    title: 'Sleeping Baby',
    artist: 'Penny Siopis',
    year: 2007
  },
  {
    title: 'Slings and Arrows',
    artist: 'Penny Siopis',
    year: 2007
  },
  {
    title: 'World Without You',
    artist: 'Penny Siopis',
    year: 2016
  },
  {
    title: 'Atlas II (detail)',
    artist: 'Penny Siopis',
    year: 2020
  },
  {
    title: 'Soldiers',
    artist: 'José Clemente Orozco',
    year: 1926
  },
  {
    title: 'Combat',
    artist: 'José Clemente Orozco',
    year: 1927
  },
  {
    title: 'Prometheus',
    artist: 'José Clemente Orozco',
    year: 1930
  },
  {
    title: 'Zapata',
    artist: 'José Clemente Orozco',
    year: 1930
  },
  {
    title: 'Catharsis',
    artist: 'José Clemente Orozco',
    year: 1934
  },
  {
    title: 'Zapatistas',
    artist: 'José Clemente Orozco',
    year: 1935
  },
  {
    title: 'Man of Fire',
    artist: 'José Clemente Orozco',
    year: 1939
  },
  {
    title: 'Dive Bomber and Tank',
    artist: 'José Clemente Orozco',
    year: 1940
  },
  {
    title: 'Father Hidalgo',
    artist: 'José Clemente Orozco',
    year: 1949
  },
  {
    title: 'Self-Portrait',
    artist: 'Fernando Botero',
    year: 1959
  },
  {
    title: 'Pope Leo X',
    artist: 'Fernando Botero',
    year: 1964
  },
  {
    title: 'Dead Bishops',
    artist: 'Fernando Botero',
    year: 1965
  },
  {
    title: 'The Family',
    artist: 'Fernando Botero',
    year: 1966
  },
  {
    title: 'Dance in Colombia',
    artist: 'Fernando Botero',
    year: 1980
  },
  {
    title: 'The Dancers',
    artist: 'Fernando Botero',
    year: 1987
  },
  {
    title: 'Una Familia',
    artist: 'Fernando Botero',
    year: 1989
  },
  {
    title: 'Bananos',
    artist: 'Fernando Botero',
    year: 1990
  },
  {
    title: 'Bather on the Beach',
    artist: 'Fernando Botero',
    year: 2001
  },
  {
    title: 'Allegory of Freedom',
    artist: 'Maria Izquierdo',
    year: 1937
  },
  {
    title: 'The Racket',
    artist: 'Maria Izquierdo',
    year: 1938
  },
  {
    title: 'Untitled',
    artist: 'Maria Izquierdo',
    year: 1938
  },
  {
    title: 'Conchs',
    artist: 'Maria Izquierdo',
    year: 1939
  },
  {
    title: "Juan Soriano's Portrait",
    artist: 'Maria Izquierdo',
    year: 1939
  },
  {
    title: 'Self-Portrait',
    artist: 'Maria Izquierdo',
    year: 1940
  },
  {
    title: 'Sorrowful Friday',
    artist: 'Maria Izquierdo',
    year: 1945
  },
  {
    title: 'The Idyll',
    artist: 'Maria Izquierdo',
    year: 1946
  },
  {
    title: 'The Cupboard',
    artist: 'Maria Izquierdo',
    year: 1947
  },
  {
    title: 'Mother',
    artist: 'Joaquin Sorolla',
    year: 1895
  },
  {
    title: 'Sewing the Sail',
    artist: 'Joaquin Sorolla',
    year: 1896
  },
  {
    title: 'Sad Inheritance',
    artist: 'Joaquin Sorolla',
    year: 1899
  },
  {
    title: "The Horse's Bath",
    artist: 'Joaquin Sorolla',
    year: 1909
  },
  {
    title: 'Time for a Bathe',
    artist: 'Joaquin Sorolla',
    year: 1909
  },
  {
    title: 'Children in the Sea',
    artist: 'Joaquin Sorolla',
    year: 1909
  },
  {
    title: 'Walk on the Beach',
    artist: 'Joaquin Sorolla',
    year: 1909
  },
  {
    title: 'Boys on the Beach',
    artist: 'Joaquin Sorolla',
    year: 1910
  },
  {
    title: 'After Bathing',
    artist: 'Joaquin Sorolla',
    year: 1915
  },
  {
    title: 'Nude with Hat',
    artist: 'Amedeo Modigliani',
    year: 1907
  },
  {
    title: 'Paul Guillaume',
    artist: 'Amedeo Modigliani',
    year: 1915
  },
  {
    title: 'Bride and Groom',
    artist: 'Amedeo Modigliani',
    year: 1915
  },
  {
    title: 'Women with Red Hair',
    artist: 'Amedeo Modigliani',
    year: 1917
  },
  {
    title: 'Elena Povolozky',
    artist: 'Amedeo Modigliani',
    year: 1917
  },
  {
    title: 'Reclining Nude',
    artist: 'Amedeo Modigliani',
    year: 1917
  },
  {
    title: 'Sitting Nude',
    artist: 'Amedeo Modigliani',
    year: 1917
  },
  {
    title: 'Woman with Blue Eyes',
    artist: 'Amedeo Modigliani',
    year: 1918
  },
  {
    title: 'Alice',
    artist: 'Amedeo Modigliani',
    year: 1919
  },
  {
    title: 'Dante and Virgil',
    artist: 'William Bouguereau',
    year: 1850
  },
  {
    title: 'The Birth of Venus',
    artist: 'William Bouguereau',
    year: 1879
  },
  {
    title: 'The Knitting Girl',
    artist: 'William Bouguereau',
    year: 1869
  },
  {
    title: 'Nymphs and Satyr',
    artist: 'William Bouguereau',
    year: 1873
  },
  {
    title: 'Pieta',
    artist: 'William Bouguereau',
    year: 1876
  },
  {
    title: 'Evening Mood',
    artist: 'William Bouguereau',
    year: 1882
  },
  {
    title: 'The Shepherdess',
    artist: 'William Bouguereau',
    year: 1889
  },
  {
    title: 'The Bohemian',
    artist: 'William Bouguereau',
    year: 1890
  },
  {
    title: 'The Abduction of Psyche',
    artist: 'William Bouguereau',
    year: 1895
  },
  {
    title: 'Deposition from the Cross',
    artist: 'Giorgio Vasari',
    year: 1540
  },
  {
    title: 'Patience',
    artist: 'Giorgio Vasari',
    year: 1542
  },
  {
    title: 'Allegory of Justice',
    artist: 'Giorgio Vasari',
    year: 1543
  },
  {
    title: 'Six Tuscan Poets',
    artist: 'Giorgio Vasari',
    year: 1544
  },
  {
    title: 'The Nativity',
    artist: 'Giorgio Vasari',
    year: 1546
  },
  {
    title: "Volcano's Forge",
    artist: 'Giorgio Vasari',
    year: 1557
  },
  {
    title: 'Self-Portrait',
    artist: 'Giorgio Vasari',
    year: 1568
  },
  {
    title: 'The Annunciation',
    artist: 'Giorgio Vasari',
    year: 1571
  },
  {
    title: 'The Last Judgment',
    artist: 'Giorgio Vasari',
    year: 1572
  },
  {
    title: 'Mars and Venus Allegory of Peace',
    artist: 'Louis-Jean-Francois Lagrenee',
    year: 1770
  },
  {
    title: 'Pygmalion and Galatea',
    artist: 'Louis-Jean-Francois Lagrenee',
    year: 1772
  },
  {
    title: 'The Death of the Dauphin',
    artist: 'Louis-Jean-Francois Lagrenee',
    year: 1775
  },
  {
    title: 'The Spartan Mother',
    artist: 'Louis-Jean-Francois Lagrenee',
    year: 1778
  },
  {
    title: 'David and Bathsheba',
    artist: 'Louis-Jean-Francois Lagrenee',
    year: 1781
  },
  {
    title: 'Happy Old Age',
    artist: 'Louis-Jean-Francois Lagrenee',
    year: 1784
  },
  {
    title: 'Allegory of Touch',
    artist: 'Louis-Jean-Francois Lagrenee',
    year: 1787
  },
  {
    title: 'Rinaldo and Armida',
    artist: 'Louis-Jean-Francois Lagrenee',
    year: 1791
  },
  {
    title: 'Seated Male Nude',
    artist: 'Louis-Jean-Francois Lagrenee',
    year: 1805
  },
  {
    title: 'Great Scene of Agony',
    artist: 'Max Beckmann',
    year: 1906
  },
  {
    title: 'The Night',
    artist: 'Max Beckmann',
    year: 1919
  },
  {
    title: 'The Iron Bridge',
    artist: 'Max Beckmann',
    year: 1922
  },
  {
    title: 'The Dream',
    artist: 'Max Beckmann',
    year: 1923
  },
  {
    title: 'Artists with Vegetable',
    artist: 'Max Beckmann',
    year: 1924
  },
  {
    title: 'The Artist and His Wife',
    artist: 'Max Beckmann',
    year: 1925
  },
  {
    title: 'Carnival',
    artist: 'Max Beckmann',
    year: 1926
  },
  {
    title: 'Still Life with Pallets',
    artist: 'Max Beckmann',
    year: 1927
  },
  {
    title: 'Prunier',
    artist: 'Max Beckmann',
    year: 1928
  },
  {
    title: 'Composition',
    artist: 'Arshile Gorky',
    year: 1940
  },
  {
    title: 'Garden in Sochi',
    artist: 'Arshile Gorky',
    year: 1941
  },
  {
    title: 'Waterfall',
    artist: 'Arshile Gorky',
    year: 1943
  },
  {
    title: 'Diary of a Seducer',
    artist: 'Arshile Gorky',
    year: 1945
  },
  {
    title: 'The Limit',
    artist: 'Arshile Gorky',
    year: 1947
  },
  {
    title: 'Agony',
    artist: 'Arshile Gorky',
    year: 1947
  },
  {
    title: 'Summation',
    artist: 'Arshile Gorky',
    year: 1947
  },
  {
    title: 'The Betrothal II',
    artist: 'Arshile Gorky',
    year: 1947
  },
  {
    title: 'Untitled',
    artist: 'Arshile Gorky',
    year: 1948
  },
  {
    title: 'Self-Portrait',
    artist: 'Suzanne Valadon',
    year: 1898
  },
  {
    title: 'Adam and Eve',
    artist: 'Suzanne Valadon',
    year: 1909
  },
  {
    title: 'Casting the Net',
    artist: 'Suzanne Valadon',
    year: 1914
  },
  {
    title: 'Study of a Cat',
    artist: 'Suzanne Valadon',
    year: 1918
  },
  {
    title: 'Bouquet and a Cat',
    artist: 'Suzanne Valadon',
    year: 1919
  },
  {
    title: 'Miss Lily Walton',
    artist: 'Suzanne Valadon',
    year: 1922
  },
  {
    title: 'The Two Bathers',
    artist: 'Suzanne Valadon',
    year: 1923
  },
  {
    title: 'Joy of LIfe',
    artist: 'Suzanne Valadon',
    year: 1923
  },
  {
    title: 'Reclining Nude',
    artist: 'Suzanne Valadon',
    year: 1928
  },
  {
    title: 'Stroller',
    artist: 'August Macke',
    year: 1907
  },
  {
    title: 'Portrait with Apples',
    artist: 'August Macke',
    year: 1909
  },
  {
    title: 'Large Bright Showcase',
    artist: 'August Macke',
    year: 1912
  },
  {
    title: 'Lady in Green Jacket',
    artist: 'August Macke',
    year: 1913
  },
  {
    title: 'The Hat Shop',
    artist: 'August Macke',
    year: 1913
  },
  {
    title: 'People at the Blue Lake',
    artist: 'August Macke',
    year: 1913
  },
  {
    title: 'At the Garden Table',
    artist: 'August Macke',
    year: 1914
  },
  {
    title: 'Four Girls',
    artist: 'August Macke',
    year: 1914
  },
  {
    title: 'View inat a Lane',
    artist: 'August Macke',
    year: 1914
  },
  {
    title: 'Landscape with Cows and Camel',
    artist: 'August Macke',
    year: 1914
  },
  {
    title: 'Street, Dresden',
    artist: 'Ernst Ludwig Kirchner',
    year: 1908
  },
  {
    title: 'Marcella',
    artist: 'Ernst Ludwig Kirchner',
    year: 1909
  },
  {
    title: 'Self-Portrait with a Model',
    artist: 'Ernst Ludwig Kirchner',
    year: 1910
  },
  {
    title: 'Female Artist',
    artist: 'Ernst Ludwig Kirchner',
    year: 1910
  },
  {
    title: 'Three Bathers',
    artist: 'Ernst Ludwig Kirchner',
    year: 1913
  },
  {
    title: 'Berlin Street Scene',
    artist: 'Ernst Ludwig Kirchner',
    year: 1913
  },
  {
    title: 'Street, Berlin',
    artist: 'Ernst Ludwig Kirchner',
    year: 1913
  },
  {
    title: 'Bathers',
    artist: 'Ernst Ludwig Kirchner',
    year: 1927
  },
  {
    title: 'A Group of Artists',
    artist: 'Ernst Ludwig Kirchner',
    year: 1927
  },
  {
    title: 'La Robe Rose',
    artist: 'Jean Frederic Bazille',
    year: 1864
  },
  {
    title: 'Landscape at Chailly',
    artist: 'Jean Frederic Bazille',
    year: 1865
  },
  {
    title: 'Family Reunion',
    artist: 'Jean Frederic Bazille',
    year: 1867
  },
  {
    title: 'Portrait of Renoir',
    artist: 'Jean Frederic Bazille',
    year: 1867
  },
  {
    title: 'The Little Gardner',
    artist: 'Jean Frederic Bazille',
    year: 1867
  },
  {
    title: 'Flowers',
    artist: 'Jean Frederic Bazille',
    year: 1868
  },
  {
    title: 'Summer Scene',
    artist: 'Jean Frederic Bazille',
    year: 1869
  },
  {
    title: "Bazille's Studio",
    artist: 'Jean Frederic Bazille',
    year: 1870
  },
  {
    title: 'The Fortune Teller',
    artist: 'Jean Frederic Bazille',
    year: 1869
  },
  {
    title: 'Lady in Black',
    artist: 'William Merritt Chase',
    year: 1888
  },
  {
    title: 'Back of a Nude',
    artist: 'William Merritt Chase',
    year: 1888
  },
  {
    title: 'Terrace at the Mall, Central Park',
    artist: 'William Merritt Chase',
    year: 1890
  },
  {
    title: 'At the Seaside',
    artist: 'William Merritt Chase',
    year: 1892
  },
  {
    title: 'Idle Hours',
    artist: 'William Merritt Chase',
    year: 1894
  },
  {
    title: 'A Friendly Call',
    artist: 'William Merritt Chase',
    year: 1895
  },
  {
    title: 'First Touch of Autumn',
    artist: 'William Merritt Chase',
    year: 1899
  },
  {
    title: 'For the Little One',
    artist: 'William Merritt Chase',
    year: 1896
  },
  {
    title: 'Still Life',
    artist: 'William Merritt Chase',
    year: 1917
  },
  {
    title: 'Riot in the Galleria',
    artist: 'Umberto Boccioni',
    year: 1909
  },
  {
    title: 'Three Women',
    artist: 'Umberto Boccioni',
    year: 1910
  },
  {
    title: 'The City Rises',
    artist: 'Umberto Boccioni',
    year: 1910
  },
  {
    title: 'Modern Idol',
    artist: 'Umberto Boccioni',
    year: 1911
  },
  {
    title: 'The Laugh',
    artist: 'Umberto Boccioni',
    year: 1911
  },
  {
    title: 'Horizontal Volumes',
    artist: 'Umberto Boccioni',
    year: 1912
  },
  {
    title: 'Simultaneous Visions',
    artist: 'Umberto Boccioni',
    year: 1912
  },
  {
    title: 'Elasticity',
    artist: 'Umberto Boccioni',
    year: 1912
  },
  {
    title: 'Dynamism of a Cyclist',
    artist: 'Umberto Boccioni',
    year: 1913
  },
  {
    title: 'The Street Pavers',
    artist: 'Umberto Boccioni',
    year: 1914
  },
  {
    title: 'Juan Legua',
    artist: 'Juan Gris',
    year: 1911
  },
  {
    title: 'Bottles and Knife',
    artist: 'Juan Gris',
    year: 1912
  },
  {
    title: 'Pears and Grapes on a Table',
    artist: 'Juan Gris',
    year: 1913
  },
  {
    title: 'Guitar and Pipe',
    artist: 'Juan Gris',
    year: 1913
  },
  {
    title: 'A Man in a Cafe',
    artist: 'Juan Gris',
    year: 1914
  },
  {
    title: 'Guitar on a Table',
    artist: 'Juan Gris',
    year: 1915
  },
  {
    title: 'Harlequin with Guitar',
    artist: 'Juan Gris',
    year: 1919
  },
  {
    title: 'The Open Window',
    artist: 'Juan Gris',
    year: 1921
  },
  {
    title: "The Musician's Table",
    artist: 'Juan Gris',
    year: 1926
  },
  {
    title: 'Rustic Mill',
    artist: 'Albert Bierstadt',
    year: 1855
  },
  {
    title: 'Indians Spear Fishing',
    artist: 'Albert Bierstadt',
    year: 1862
  },
  {
    title: 'Sundown at Yosemite',
    artist: 'Albert Bierstadt',
    year: 1863
  },
  {
    title: 'Rocky Mountains',
    artist: 'Albert Bierstadt',
    year: 1863
  },
  {
    title: 'Valley of the Yosemite',
    artist: 'Albert Bierstadt',
    year: 1864
  },
  {
    title: 'The Buffalo Trail',
    artist: 'Albert Bierstadt',
    year: 1867
  },
  {
    title: 'Mount Hood',
    artist: 'Albert Bierstadt',
    year: 1869
  },
  {
    title: 'California Spring',
    artist: 'Albert Bierstadt',
    year: 1875
  },
  {
    title: 'Street in Nassau',
    artist: 'Albert Bierstadt',
    year: 1878
  },
  {
    title: 'The Last of the Buffalo',
    artist: 'Albert Bierstadt',
    year: 1888
  },
  {
    title: 'The Farm',
    artist: 'Joan Miró',
    year: 1922
  },
  {
    title: 'The Tilled Field',
    artist: 'Joan Miró',
    year: 1924
  },
  {
    title: 'Catalan Landscape (The Hunter)',
    artist: 'Joan Miró',
    year: 1924
  },
  {
    title: 'Siesta',
    artist: 'Joan Miró',
    year: 1925
  },
  {
    title: 'Dutch Interior I',
    artist: 'Joan Miró',
    year: 1928
  },
  {
    title: 'Dutch Interior II',
    artist: 'Joan Miró',
    year: 1928
  },
  {
    title: 'Still Life with Old Shoe',
    artist: 'Joan Miró',
    year: 1937
  },
  {
    title: 'The Escape Ladder',
    artist: 'Joan Miró',
    year: 1939
  },
  {
    title: 'Ciphers and Constellations, in Love with a Woman',
    artist: 'Joan Miró',
    year: 1941
  },
  {
    title: 'The Red Sun',
    artist: 'Joan Miró',
    year: 1948
  },
  {
    title: 'Painting (Blue Star)',
    artist: 'Joan Miró',
    year: 1963
  },
  {
    title: 'Death of the Virgin',
    artist: 'Andrea Mantegna',
    year: 1464
  },
  {
    title: 'The Lamentation Over the Dead Christ',
    artist: 'Andrea Mantegna',
    year: 1480
  },
  {
    title: 'San Sebastion',
    artist: 'Andrea Mantegna',
    year: 1480
  },
  {
    title: 'Judith and Holofernes',
    artist: 'Andrea Mantegna',
    year: 1495
  },
  {
    title: 'Parnassus',
    artist: 'Andrea Mantegna',
    year: 1497
  },
  {
    title: 'Trivulzio Madonna',
    artist: 'Andrea Mantegna',
    year: 1497
  },
  {
    title: 'Adoration of the Magi',
    artist: 'Andrea Mantegna',
    year: 1500
  },
  {
    title: 'Triumph of the Virtues',
    artist: 'Andrea Mantegna',
    year: 1502
  },
  {
    title: 'Triumphs of Caesar',
    artist: 'Andrea Mantegna',
    year: 1505
  },
  {
    title: 'The Last Supper',
    artist: 'Emile Nolde',
    year: 1909
  },
  {
    title: 'Mask Still Life III',
    artist: 'Emile Nolde',
    year: 1911
  },
  {
    title: 'The Missionary',
    artist: 'Emile Nolde',
    year: 1912
  },
  {
    title: 'Self-Portrait',
    artist: 'Emile Nolde',
    year: 1912
  },
  {
    title: 'Burial',
    artist: 'Emile Nolde',
    year: 1915
  },
  {
    title: 'The Tribute Money',
    artist: 'Emile Nolde',
    year: 1915
  },
  {
    title: 'Sunflowers',
    artist: 'Emile Nolde',
    year: 1917
  },
  {
    title: 'Women and Pierrot',
    artist: 'Emile Nolde',
    year: 1917
  },
  {
    title: 'Large Poppies',
    artist: 'Emile Nolde',
    year: 1942
  },
  {
    title: 'Summer Scene',
    artist: 'Giuseppe Arcimboldo',
    year: 1563
  },
  {
    title: 'The Librarian',
    artist: 'Giuseppe Arcimboldo',
    year: 1566
  },
  {
    title: 'The Jurist',
    artist: 'Giuseppe Arcimboldo',
    year: 1566
  },
  {
    title: 'Earth',
    artist: 'Giuseppe Arcimboldo',
    year: 1566
  },
  {
    title: 'Autumn',
    artist: 'Giuseppe Arcimboldo',
    year: 1573
  },
  {
    title: 'Winter',
    artist: 'Giuseppe Arcimboldo',
    year: 1573
  },
  {
    title: 'Spring',
    artist: 'Giuseppe Arcimboldo',
    year: 1573
  },
  {
    title: 'Flora',
    artist: 'Giuseppe Arcimboldo',
    year: 1588
  },
  {
    title: 'Vertumnus',
    artist: 'Giuseppe Arcimboldo',
    year: 1591
  },
  {
    title: 'Heads',
    artist: 'Pavel Filonov',
    year: 1910
  },
  {
    title: 'Peasant Family',
    artist: 'Pavel Filonov',
    year: 1910
  },
  {
    title: 'Feast of the Kings',
    artist: 'Pavel Filonov',
    year: 1913
  },
  {
    title: 'Peasant Family',
    artist: 'Pavel Filonov',
    year: 1914
  },
  {
    title: 'Defeater of the City',
    artist: 'Pavel Filonov',
    year: 1915
  },
  {
    title: 'Formula of Spring',
    artist: 'Pavel Filonov',
    year: 1920
  },
  {
    title: 'A Family Portrait',
    artist: 'Pavel Filonov',
    year: 1924
  },
  {
    title: 'Two Heads',
    artist: 'Pavel Filonov',
    year: 1925
  },
  {
    title: 'Faces',
    artist: 'Pavel Filonov',
    year: 1940
  },
  {
    title: 'Houses at Estaque',
    artist: 'Georges Braque',
    year: 1908
  },
  {
    title: 'Glass on a Table',
    artist: 'Georges Braque',
    year: 1909
  },
  {
    title: 'Mandola',
    artist: 'Georges Braque',
    year: 1910
  },
  {
    title: 'Violin and Candlestick',
    artist: 'Georges Braque',
    year: 1910
  },
  {
    title: 'Mandora',
    artist: 'Georges Braque',
    year: 1910
  },
  {
    title: 'Fruit Dish and Glass',
    artist: 'Georges Braque',
    year: 1912
  },
  {
    title: 'Woman with a Guitar',
    artist: 'Georges Braque',
    year: 1913
  },
  {
    title: 'The Bowl of Grapes',
    artist: 'Georges Braque',
    year: 1926
  },
  {
    title: 'Balustre et Crane',
    artist: 'Georges Braque',
    year: 1938
  },
  {
    title: 'Hector and Andromache',
    artist: 'Giorgio de Chirico',
    year: 1912
  },
  {
    title: 'Ariadne',
    artist: 'Giorgio de Chirico',
    year: 1913
  },
  {
    title: 'The Red Tower',
    artist: 'Giorgio de Chirico',
    year: 1913
  },
  {
    title: 'The Uncertainty of the Poet',
    artist: 'Giorgio de Chirico',
    year: 1913
  },
  {
    title: 'The Song of Love',
    artist: 'Giorgio de Chirico',
    year: 1914
  },
  {
    title: "The Child's Brain",
    artist: 'Giorgio de Chirico',
    year: 1914
  },
  {
    title: 'Christ and the Storm',
    artist: 'Giorgio de Chirico',
    year: 1914
  },
  {
    title: 'The Two Masks',
    artist: 'Giorgio de Chirico',
    year: 1926
  },
  {
    title: 'The Archaeologists',
    artist: 'Giorgio de Chirico',
    year: 1927
  },
  {
    title: 'Lane Near a Small Town',
    artist: 'Alfred Sisley',
    year: 1864
  },
  {
    title: 'The Seine at Argenteuil',
    artist: 'Alfred Sisley',
    year: 1872
  },
  {
    title: 'Villeneuve-la-Garenne',
    artist: 'Alfred Sisley',
    year: 1872
  },
  {
    title: 'The Machine at Marly',
    artist: 'Alfred Sisley',
    year: 1873
  },
  {
    title: 'Flood at Port-Marly',
    artist: 'Alfred Sisley',
    year: 1876
  },
  {
    title: 'Snow at Louveciennes',
    artist: 'Alfred Sisley',
    year: 1878
  },
  {
    title: "The Loing's Canal",
    artist: 'Alfred Sisley',
    year: 1892
  },
  {
    title: 'The Bridge at Moret',
    artist: 'Alfred Sisley',
    year: 1893
  },
  {
    title: 'A Village Street in Winter',
    artist: 'Alfred Sisley',
    year: 1893
  },
  {
    title: 'The Ray',
    artist: 'Jean-Baptiste Simeon Chardin',
    year: 1728
  },
  {
    title: 'The Buffet',
    artist: 'Jean-Baptiste Simeon Chardin',
    year: 1728
  },
  {
    title: 'A Bowl of Plums',
    artist: 'Jean-Baptiste Simeon Chardin',
    year: 1728
  },
  {
    title: 'The Silver Goblet',
    artist: 'Jean-Baptiste Simeon Chardin',
    year: 1728
  },
  {
    title: 'The Governess',
    artist: 'Jean-Baptiste Simeon Chardin',
    year: 1739
  },
  {
    title: 'The Good Education',
    artist: 'Jean-Baptiste Simeon Chardin',
    year: 1758
  },
  {
    title: 'Jar of Apricots',
    artist: 'Jean-Baptiste Simeon Chardin',
    year: 1758
  },
  {
    title: 'A Vase of Flowers',
    artist: 'Jean-Baptiste Simeon Chardin',
    year: 1765
  },
  {
    title: 'Basket of Peaches',
    artist: 'Jean-Baptiste Simeon Chardin',
    year: 1768
  },
  {
    title: 'Onement, I',
    artist: 'Barnett Newman',
    year: 1948
  },
  {
    title: 'Two Edges',
    artist: 'Barnett Newman',
    year: 1948
  },
  {
    title: 'Yellow Painting',
    artist: 'Barnett Newman',
    year: 1949
  },
  {
    title: 'Concord',
    artist: 'Barnett Newman',
    year: 1949
  },
  {
    title: 'Dionysius',
    artist: 'Barnett Newman',
    year: 1949
  },
  {
    title: 'Black Fire I',
    artist: 'Barnett Newman',
    year: 1961
  },
  {
    title: 'Canto VII',
    artist: 'Barnett Newman',
    year: 1963
  },
  {
    title: 'The Moment',
    artist: 'Barnett Newman',
    year: 1966
  },
  {
    title: 'Voice of Fire',
    artist: 'Barnett Newman',
    year: 1967
  },
  {
    title: 'Saint Quentin',
    artist: 'Pontormo',
    year: 1517
  },
  {
    title: 'Joseph in Egypt',
    artist: 'Pontormo',
    year: 1518
  },
  {
    title: 'Pucci Altarpiece',
    artist: 'Pontormo',
    year: 1518
  },
  {
    title: 'Visitation',
    artist: 'Pontormo',
    year: 1528
  },
  {
    title: 'Annunciation',
    artist: 'Pontormo',
    year: 1528
  },
  {
    title: 'Supper at Emmaus',
    artist: 'Pontormo',
    year: 1525
  },
  {
    title: 'The Holy Family',
    artist: 'Pontormo',
    year: 1525
  },
  {
    title: 'Venus and Cupid',
    artist: 'Pontormo',
    year: 1533
  },
  {
    title: 'Group of the Dead',
    artist: 'Pontormo',
    year: 1550
  },
  {
    title: 'The City',
    artist: 'George Grosz',
    year: 1916
  },
  {
    title: 'The Funeral',
    artist: 'George Grosz',
    year: 1918
  },
  {
    title: 'Eclipse of the Sun',
    artist: 'George Grosz',
    year: 1926
  },
  {
    title: 'A Hunger Fantasy',
    artist: 'George Grosz',
    year: 1930
  },
  {
    title: 'The Lecture',
    artist: 'George Grosz',
    year: 1935
  },
  {
    title: 'Interrogation',
    artist: 'George Grosz',
    year: 1938
  },
  {
    title: 'Cain, or Hitler in Hell',
    artist: 'George Grosz',
    year: 1944
  },
  {
    title: 'Flag',
    artist: 'Jasper Johns',
    year: 1955
  },
  {
    title: 'White Flag',
    artist: 'Jasper Johns',
    year: 1955
  },
  {
    title: 'Three Flags',
    artist: 'Jasper Johns',
    year: 1958
  },
  {
    title: 'False Start',
    artist: 'Jasper Johns',
    year: 1959
  },
  {
    title: 'Numbers in Color',
    artist: 'Jasper Johns',
    year: 1959
  },
  {
    title: '0 through 9',
    artist: 'Jasper Johns',
    year: 1960
  },
  {
    title: 'Map',
    artist: 'Jasper Johns',
    year: 1961
  },
  {
    title: 'The Critic Smiles',
    artist: 'Jasper Johns',
    year: 1969
  },
  {
    title: 'Corpse and Mirror II',
    artist: 'Jasper Johns',
    year: 1975
  },
  {
    title: 'Fish Fight',
    artist: 'Max Ernst',
    year: 1917
  },
  {
    title: 'Aquis Submersus',
    artist: 'Max Ernst',
    year: 1919
  },
  {
    title: 'Célèbes or Elephant Célèbes',
    artist: 'Max Ernst',
    year: 1921
  },
  {
    title: 'Ubu Imperator',
    artist: 'Max Ernst',
    year: 1923
  },
  {
    title: 'Forest and Dove',
    artist: 'Max Ernst',
    year: 1927
  },
  {
    title: 'Forest and Sun',
    artist: 'Max Ernst',
    year: 1927
  },
  {
    title: 'The Entire City',
    artist: 'Max Ernst',
    year: 1935
  },
  {
    title: 'The Barbarians',
    artist: 'Max Ernst',
    year: 1937
  },
  {
    title: 'The Eye of Silence',
    artist: 'Max Ernst',
    year: 1943
  },
  {
    title: 'I and the Village',
    artist: 'Marc Chagall',
    year: 1911
  },
  {
    title: 'The Soldier Drinks',
    artist: 'Marc Chagall',
    year: 1912
  },
  {
    title: 'The Fiddler',
    artist: 'Marc Chagall',
    year: 1913
  },
  {
    title: 'Birthday',
    artist: 'Marc Chagall',
    year: 1915
  },
  {
    title: 'Over the Town',
    artist: 'Marc Chagall',
    year: 1918
  },
  {
    title: 'The Promenade',
    artist: 'Marc Chagall',
    year: 1918
  },
  {
    title: 'Green Violinist',
    artist: 'Marc Chagall',
    year: 1924
  },
  {
    title: 'White Crucifixion',
    artist: 'Marc Chagall',
    year: 1938
  },
  {
    title: 'Cow with Parasol',
    artist: 'Marc Chagall',
    year: 1946
  },
  {
    title: 'The Falling Angel',
    artist: 'Marc Chagall',
    year: 1947
  },
  {
    title: 'Triptych of Jan Crabbe',
    artist: 'Hans Memling',
    year: 1470
  },
  {
    title: 'The Passion',
    artist: 'Hans Memling',
    year: 1471
  },
  {
    title: 'The Last Judgment',
    artist: 'Hans Memling',
    year: 1473
  },
  {
    title: 'Virgin and Child',
    artist: 'Hans Memling',
    year: 1478
  },
  {
    title: 'Donne Triptych',
    artist: 'Hans Memling',
    year: 1478
  },
  {
    title: 'St John Altarpiece',
    artist: 'Hans Memling',
    year: 1479
  },
  {
    title: 'Scenes from the Life of Mary',
    artist: 'Hans Memling',
    year: 1480
  },
  {
    title: 'Hell',
    artist: 'Hans Memling',
    year: 1485
  },
  {
    title: 'The Baptism of Christ',
    artist: 'Piero Della Francesca',
    year: 1450
  },
  {
    title: 'Death of Adam',
    artist: 'Piero Della Francesca',
    year: 1458
  },
  {
    title: 'Flagellation of Christ',
    artist: 'Piero Della Francesca',
    year: 1460
  },
  {
    title: 'Madonna del Parto',
    artist: 'Piero Della Francesca',
    year: 1460
  },
  {
    title: 'Annunciation',
    artist: 'Piero Della Francesca',
    year: 1464
  },
  {
    title: 'The Resurrection',
    artist: 'Piero Della Francesca',
    year: 1465
  },
  {
    title: 'The Crucifixion',
    artist: 'Piero Della Francesca',
    year: 1469
  },
  {
    title: 'Portraits of Federico da Montefeltro and His Wife Battista Sforza',
    artist: 'Piero Della Francesca',
    year: 1472
  },
  {
    title: 'Nativity',
    artist: 'Piero Della Francesca',
    year: 1475
  },
  {
    title: 'Testament and Death of Moses',
    artist: 'Luca Signorelli',
    year: 1482
  },
  {
    title: "Sant'Onofrio Altarpiece",
    artist: 'Luca Signorelli',
    year: 1484
  },
  {
    title: 'The Circumcision',
    artist: 'Luca Signorelli',
    year: 1491
  },
  {
    title: 'Marriage of the Virgin',
    artist: 'Luca Signorelli',
    year: 1491
  },
  {
    title: 'Portrait of an Elderly Man',
    artist: 'Luca Signorelli',
    year: 1492
  },
  {
    title: 'The Damned',
    artist: 'Luca Signorelli',
    year: 1504
  },
  {
    title: 'The Elect',
    artist: 'Luca Signorelli',
    year: 1504
  },
  {
    title: 'The Poet Virgil',
    artist: 'Luca Signorelli',
    year: 1504
  },
  {
    title: 'The Crucifixion',
    artist: 'Luca Signorelli',
    year: 1505
  },
  {
    title: 'Sweetwater',
    artist: 'Edward Ruscha',
    year: 1959
  },
  {
    title: 'OOF',
    artist: 'Edward Ruscha',
    year: 1962
  },
  {
    title: 'Honk',
    artist: 'Edward Ruscha',
    year: 1962
  },
  {
    title: 'Gas',
    artist: 'Edward Ruscha',
    year: 1962
  },
  {
    title: 'Actual Size',
    artist: 'Edward Ruscha',
    year: 1962
  },
  {
    title: 'Standard Station',
    artist: 'Edward Ruscha',
    year: 1966
  },
  {
    title: 'Lisp',
    artist: 'Edward Ruscha',
    year: 1968
  },
  {
    title: 'Hi, Honey',
    artist: 'Edward Ruscha',
    year: 1981
  },
  {
    title: 'Pay Nothing Until April',
    artist: 'Edward Ruscha',
    year: 2003
  },
  {
    title: 'On the Balcony',
    artist: 'Peter Blake',
    year: 1957
  },
  {
    title: 'The Fine Art Bit',
    artist: 'Peter Blake',
    year: 1959
  },
  {
    title: 'Tuesday',
    artist: 'Peter Blake',
    year: 1961
  },
  {
    title: 'The Toy Shop',
    artist: 'Peter Blake',
    year: 1962
  },
  {
    title: 'Leo 79',
    artist: 'Peter Blake',
    year: 1969
  },
  {
    title: 'Leo 139',
    artist: 'Peter Blake',
    year: 1969
  },
  {
    title: '3675',
    artist: 'Peter Blake',
    year: 1969
  },
  {
    title: 'Penny Black',
    artist: 'Peter Blake',
    year: 1972
  },
  {
    title: 'The Tuareg',
    artist: 'Peter Blake',
    year: 1972
  },
  {
    title: 'Phantoms',
    artist: 'Yves Tanguy',
    year: 1928
  },
  {
    title: 'Outside',
    artist: 'Yves Tanguy',
    year: 1929
  },
  {
    title: 'Blue Bed',
    artist: 'Yves Tanguy',
    year: 1929
  },
  {
    title: 'Promontory Palace',
    artist: 'Yves Tanguy',
    year: 1931
  },
  {
    title: 'The Furniture of Time',
    artist: 'Yves Tanguy',
    year: 1939
  },
  {
    title: 'Slowly Toward the North',
    artist: 'Yves Tanguy',
    year: 1942
  },
  {
    title: 'Indefined Divisibility',
    artist: 'Yves Tanguy',
    year: 1942
  },
  {
    title: 'Reply to Red',
    artist: 'Yves Tanguy',
    year: 1943
  },
  {
    title: 'The Rapidity of Sleep',
    artist: 'Yves Tanguy',
    year: 1945
  },
  {
    title: 'Tool Box 8',
    artist: 'Jim Dine',
    year: 1966
  },
  {
    title: 'Tool Box 2',
    artist: 'Jim Dine',
    year: 1966
  },
  {
    title: 'Tool Box 4',
    artist: 'Jim Dine',
    year: 1966
  },
  {
    title: 'Four Hearts',
    artist: 'Jim Dine',
    year: 1969
  },
  {
    title: 'Braid',
    artist: 'Jim Dine',
    year: 1973
  },
  {
    title: 'Tinsnip',
    artist: 'Jim Dine',
    year: 1973
  },
  {
    title: 'Nutcracker',
    artist: 'Jim Dine',
    year: 1973
  },
  {
    title: 'Two Hearts in a Forest',
    artist: 'Jim Dine',
    year: 1981
  },
  {
    title: 'Confetti Heart',
    artist: 'Jim Dine',
    year: 1985
  },
  {
    title: 'Gualino Madonna',
    artist: 'Duccio di Buoninsegna',
    year: 1283
  },
  {
    title: 'Madonna and Child',
    artist: 'Duccio di Buoninsegna',
    year: 1300
  },
  {
    title: 'The Crucifixion',
    artist: 'Duccio di Buoninsegna',
    year: 1310
  },
  {
    title: 'Maesto',
    artist: 'Duccio di Buoninsegna',
    year: 1311
  },
  {
    title: 'The Raising of Lazarus',
    artist: 'Duccio di Buoninsegna',
    year: 1311
  },
  {
    title: 'Assumption',
    artist: 'Duccio di Buoninsegna',
    year: 1311
  },
  {
    title: 'Arrest of Christ',
    artist: 'Duccio di Buoninsegna',
    year: 1311
  },
  {
    title: 'Christ before Pilate',
    artist: 'Duccio di Buoninsegna',
    year: 1311
  },
  {
    title: 'Prophets. Isaiah',
    artist: 'Duccio di Buoninsegna',
    year: 1311
  },
  {
    title: 'The Knifegrinder',
    artist: 'Kazimir Severinovich Malevich',
    year: 1912
  },
  {
    title: 'Cow and Fiddle',
    artist: 'Kazimir Severinovich Malevich',
    year: 1913
  },
  {
    title: 'An Englishman in Moscow',
    artist: 'Kazimir Severinovich Malevich',
    year: 1914
  },
  {
    title: 'Aviator',
    artist: 'Kazimir Severinovich Malevich',
    year: 1914
  },
  {
    title: 'Black Square',
    artist: 'Kazimir Severinovich Malevich',
    year: 1915
  },
  {
    title: 'Head of a Peasant',
    artist: 'Kazimir Severinovich Malevich',
    year: 1929
  },
  {
    title: "Marpha and Vank'ka",
    artist: 'Kazimir Severinovich Malevich',
    year: 1929
  },
  {
    title: 'Haymaking',
    artist: 'Kazimir Severinovich Malevich',
    year: 1929
  },
  {
    title: 'Woman with Rake',
    artist: 'Kazimir Severinovich Malevich',
    year: 1932
  },
  {
    title: 'The Dreaming Boys',
    artist: 'Oskar Kokoschka',
    year: 1908
  },
  {
    title: 'Robinson',
    artist: 'Oskar Kokoschka',
    year: 1908
  },
  {
    title: 'Martha Hirsch',
    artist: 'Oskar Kokoschka',
    year: 1909
  },
  {
    title: 'Annunciation',
    artist: 'Oskar Kokoschka',
    year: 1911
  },
  {
    title: 'Alma Mahler',
    artist: 'Oskar Kokoschka',
    year: 1912
  },
  {
    title: 'Bride of the Wind',
    artist: 'Oskar Kokoschka',
    year: 1914
  },
  {
    title: 'Lovers with Cat',
    artist: 'Oskar Kokoschka',
    year: 1917
  },
  {
    title: 'Mother and Child',
    artist: 'Oskar Kokoschka',
    year: 1921
  },
  {
    title: 'Loreley',
    artist: 'Oskar Kokoschka',
    year: 1942
  },
  {
    title: 'The Girl with the Knife in Her Hand',
    artist: 'Yoshitomo Nara',
    year: 1991
  },
  {
    title: 'The Longest Night',
    artist: 'Yoshitomo Nara',
    year: 1995
  },
  {
    title: "Untitled (Nobody's Fool)",
    artist: 'Yoshitomo Nara',
    year: 1998
  },
  {
    title: 'Knife Behind Back',
    artist: 'Yoshitomo Nara',
    year: 2000
  },
  {
    title: 'Star Island',
    artist: 'Yoshitomo Nara',
    year: 2003
  },
  {
    title: 'A to Z Memorial Dog',
    artist: 'Yoshitomo Nara',
    year: 2006
  },
  {
    title: 'Fire',
    artist: 'Yoshitomo Nara',
    year: 2009
  },
  {
    title: 'Midnight Surprise',
    artist: 'Yoshitomo Nara',
    year: 2017
  },
  {
    title: 'Lucky Strike',
    artist: 'Stuart Davis',
    year: 1921
  },
  {
    title: 'Tree and Urn',
    artist: 'Stuart Davis',
    year: 1921
  },
  {
    title: 'Steeple and Street',
    artist: 'Stuart Davis',
    year: 1922
  },
  {
    title: 'Odol',
    artist: 'Stuart Davis',
    year: 1924
  },
  {
    title: 'Percolator',
    artist: 'Stuart Davis',
    year: 1927
  },
  {
    title: 'Egg Beater No. 4',
    artist: 'Stuart Davis',
    year: 1928
  },
  {
    title: 'Egg Beater No. 2',
    artist: 'Stuart Davis',
    year: 1928
  },
  {
    title: 'Men Without Women',
    artist: 'Stuart Davis',
    year: 1932
  },
  {
    title: 'G&W',
    artist: 'Stuart Davis',
    year: 1944
  },
  {
    title: 'Watermelon Slices',
    artist: 'Wayne Thiebaud',
    year: 1961
  },
  {
    title: 'Cut Meringues',
    artist: 'Wayne Thiebaud',
    year: 1961
  },
  {
    title: 'Pies, Pies, Pies',
    artist: 'Wayne Thiebaud',
    year: 1961
  },
  {
    title: 'Display Cakes',
    artist: 'Wayne Thiebaud',
    year: 1963
  },
  {
    title: 'Three Machines',
    artist: 'Wayne Thiebaud',
    year: 1963
  },
  {
    title: 'Lemon Cake',
    artist: 'Wayne Thiebaud',
    year: 1964
  },
  {
    title: 'Dark Cones',
    artist: 'Wayne Thiebaud',
    year: 1964
  },
  {
    title: 'Chocolate Cake',
    artist: 'Wayne Thiebaud',
    year: 1971
  },
  {
    title: 'Cake Slice',
    artist: 'Wayne Thiebaud',
    year: 1979
  },
  {
    title: 'Self-Portrait',
    artist: 'Otto Dix',
    year: 1912
  },
  {
    title: 'The Match Seller',
    artist: 'Otto Dix',
    year: 1920
  },
  {
    title: 'The Skat Players',
    artist: 'Otto Dix',
    year: 1920
  },
  {
    title: 'Mother with Child',
    artist: 'Otto Dix',
    year: 1921
  },
  {
    title: 'The Trench',
    artist: 'Otto Dix',
    year: 1923
  },
  {
    title: 'Salon',
    artist: 'Otto Dix',
    year: 1927
  },
  {
    title: 'Metropolis',
    artist: 'Otto Dix',
    year: 1928
  },
  {
    title: 'The War',
    artist: 'Otto Dix',
    year: 1932
  },
  {
    title: 'Flanders',
    artist: 'Otto Dix',
    year: 1934
  },
  {
    title: 'Autumn',
    artist: 'William McTaggart',
    year: 1863
  },
  {
    title: 'Spring',
    artist: 'William McTaggart',
    year: 1864
  },
  {
    title: 'Off to the Fishing',
    artist: 'William McTaggart',
    year: 1871
  },
  {
    title: 'The Young Fishers',
    artist: 'William McTaggart',
    year: 1876
  },
  {
    title: 'Storm Cloud',
    artist: 'William McTaggart',
    year: 1890
  },
  {
    title: 'The Bait Gatherers',
    artist: 'William McTaggart',
    year: 1890
  },
  {
    title: 'The Storm',
    artist: 'William McTaggart',
    year: 1890
  },
  {
    title: 'By Summer Seas',
    artist: 'William McTaggart',
    year: 1890
  },
  {
    title: 'Harvest Moon',
    artist: 'William McTaggart',
    year: 1899
  },
  {
    title: 'Speeding Automobile',
    artist: 'Giacomo Balla',
    year: 1913
  },
  {
    title: 'Landscape',
    artist: 'Giacomo Balla',
    year: 1913
  },
  {
    title: 'Flight of the Swallows',
    artist: 'Giacomo Balla',
    year: 1913
  },
  {
    title: 'Abstract Speed + Sound',
    artist: 'Giacomo Balla',
    year: 1914
  },
  {
    title: 'Vortice',
    artist: 'Giacomo Balla',
    year: 1914
  },
  {
    title: 'Fiore Futurista',
    artist: 'Giacomo Balla',
    year: 1920
  },
  {
    title: 'The Spell is Broken',
    artist: 'Giacomo Balla',
    year: 1920
  },
  {
    title: 'Numbers in Love',
    artist: 'Giacomo Balla',
    year: 1923
  },
  {
    title: 'Nuns and Landscape',
    artist: 'Giacomo Balla',
    year: 1925
  },
  {
    title: 'The Poor Poet',
    artist: 'Carl Spitzweg',
    year: 1839
  },
  {
    title: 'The Butterfly Hunter',
    artist: 'Carl Spitzweg',
    year: 1840
  },
  {
    title: 'Sunday Stroll',
    artist: 'Carl Spitzweg',
    year: 1841
  },
  {
    title: 'Resting on the Vine',
    artist: 'Carl Spitzweg',
    year: 1845
  },
  {
    title: 'The Bookworm',
    artist: 'Carl Spitzweg',
    year: 1850
  },
  {
    title: 'The Cactus Lover',
    artist: 'Carl Spitzweg',
    year: 1855
  },
  {
    title: 'Ash Wednesday',
    artist: 'Carl Spitzweg',
    year: 1860
  },
  {
    title: 'Arts and Science',
    artist: 'Carl Spitzweg',
    year: 1880
  },
  {
    title: 'The Writer',
    artist: 'Carl Spitzweg',
    year: 1880
  },
  {
    title: 'The Siege of Cosel',
    artist: 'Wilhelm von Kobell',
    year: 1808
  },
  {
    title: 'Cattle Market',
    artist: 'Wilhelm von Kobell',
    year: 1820
  },
  {
    title: 'On Gaisalm',
    artist: 'Wilhelm von Kobell',
    year: 1820
  },
  {
    title: 'Reiter am Tegernsee',
    artist: 'Wilhelm von Kobell',
    year: 1820
  },
  {
    title: 'Landscape near Munich',
    artist: 'Wilhelm von Kobell',
    year: 1820
  },
  {
    title: 'Winter View of Munich',
    artist: 'Wilhelm von Kobell',
    year: 1821
  },
  {
    title: 'A Country Lane',
    artist: 'Wilhelm von Kobell',
    year: 1821
  },
  {
    title: 'The Interior of Saint Bavo',
    artist: 'Pieter Jansz Saenredam',
    year: 1628
  },
  {
    title: 'Church of Santa Maria della Febbre',
    artist: 'Pieter Jansz Saenredam',
    year: 1629
  },
  {
    title: 'The Interior of the Grote Kerk at Haarlem',
    artist: 'Pieter Jansz Saenredam',
    year: 1637
  },
  {
    title: 'Interior of the Buurkerk, Utrecht',
    artist: 'Pieter Jansz Saenredam',
    year: 1645
  },
  {
    title: 'Interior of the Sint-Odulphuskerk',
    artist: 'Pieter Jansz Saenredam',
    year: 1649
  },
  {
    title: 'The Oude Stadhuis in Amsterdam',
    artist: 'Pieter Jansz Saenredam',
    year: 1657
  },
  {
    title: 'Church Interior',
    artist: 'Pieter Jansz Saenredam',
    year: 1665
  },
  {
    title: 'A Moonlight Lane',
    artist: 'John Atkinson Grimshaw',
    year: 1874
  },
  {
    title: 'The Lady of Shalott',
    artist: 'John Atkinson Grimshaw',
    year: 1878
  },
  {
    title: 'Spirit of the Night',
    artist: 'John Atkinson Grimshaw',
    year: 1879
  },
  {
    title: 'Shipping on the Clyde',
    artist: 'John Atkinson Grimshaw',
    year: 1881
  },
  {
    title: 'Boar Lane, Leeds',
    artist: 'John Atkinson Grimshaw',
    year: 1881
  },
  {
    title: 'Moonlight',
    artist: 'John Atkinson Grimshaw',
    year: 1882
  },
  {
    title: 'Dulce Domum',
    artist: 'John Atkinson Grimshaw',
    year: 1885
  },
  {
    title: 'Canny Glasgow',
    artist: 'John Atkinson Grimshaw',
    year: 1887
  },
  {
    title: 'Golden Light',
    artist: 'John Atkinson Grimshaw',
    year: 1893
  }
]);
