import React from 'react';
import { Modal, Form } from 'react-bootstrap';

export const defaultOptions = { lightMode: false };

function OptionsModal({ isOpen, onClose, lightMode, onLightModeChange }) {
  const onLightModeToggle = () => {
    const storedOptions = localStorage.getItem('options');
    const options = storedOptions ? JSON.parse(storedOptions) : defaultOptions;
    options.lightMode = !options.lightMode;
    localStorage.setItem('options', JSON.stringify(options));
    onLightModeChange(options.lightMode);
  };

  return (
    <Modal show={isOpen} onHide={onClose} contentClassName={lightMode ? 'light-mode' : ''}>
      <Modal.Header closeButton>
        <Modal.Title>Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="color-mode-preference">
          <div className="darkmode-label">Dark Mode</div>
          <Form>
            <Form.Check
              type="switch"
              label="Light Mode"
              id="disabled-custom-switch"
              onChange={onLightModeToggle}
              checked={lightMode}
            />
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default OptionsModal;
