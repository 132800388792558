import React from 'react';

function Guess({ value, answer }) {
  const titleCorrect = value.title && answer.title.toLowerCase() === value.title.toLowerCase();
  const artistCorrect = value.artist && answer.artist.toLowerCase() === value.artist.toLowerCase();
  const yearCorrect = answer.year === value.year;
  const yearClose = Math.abs(answer.year - value.year) <= 50;

  return (
    <div className="guess">
      <div className={`guess-part piece ${titleCorrect ? 'correct' : ''}`}>
        <span>{value.title}</span>
      </div>
      <div className={`guess-part artist ${artistCorrect ? 'correct' : ''}`}>
        <span>{value.artist}</span>
      </div>
      <div
        className={`guess-part year ${yearCorrect ? 'correct' : ''} ${yearClose ? 'close' : ''}`}
      >
        {value.year === 0 ? null : value.year}
      </div>
    </div>
  );
}

export default Guess;
