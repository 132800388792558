import React from 'react';
import { ReactComponent as Logo } from '../Images/logo.svg';
import { ReactComponent as IconArchive } from '../Images/icon-archive.svg';
import { ReactComponent as IconHTP } from '../Images/icon-how-to-play.svg';
import { ReactComponent as IconLove } from '../Images/icon-love.svg';
import { ReactComponent as IconStats } from '../Images/icon-stats.svg';
import { ReactComponent as IconOptions } from '../Images/icon-options.svg';

function Navigation({ onOpenSupport, onOpenArchive, onOpenHTP, onOpenStats, onOpenOptions }) {
  return (
    <div className="navigation">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="navigation-content">
              <div className="icon-group">
                <IconHTP onClick={onOpenHTP} />
                <IconLove onClick={onOpenSupport} />
              </div>
              <h1 className="logo">
                <Logo />
              </h1>
              <div className="icon-group">
                <IconArchive onClick={onOpenArchive} />
                <IconStats onClick={onOpenStats} />
                <IconOptions onClick={onOpenOptions} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
