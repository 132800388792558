import React from 'react';
import { Modal } from 'react-bootstrap';

function SupportModal({ lightMode, isOpen, onClose }) {
  return (
    <Modal show={isOpen} onHide={onClose} contentClassName={lightMode ? 'light-mode' : ''}>
      <Modal.Header closeButton>
        <Modal.Title>Support Paintle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>If you’re enjoying the game, please consider supporting it.</p>
        <p><a href="https://ko-fi.com/paintle" target="_blank" rel="noopener noreferrer" className="btn">Support Paintle on Ko-fi</a></p>
        <p>
          <strong>Questions?</strong>
          {' '}
          <a href="mailto:hello@paintle.app" className="link-green">Email me</a>
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default SupportModal;
