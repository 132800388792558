import React from 'react';

function Pip({ count, value, onClick, final, isAnswerShown }) {
  let state = 'future';
  if (count > value) {
    state = 'past';
  } else if (count === value) {
    state = 'present';
    if (isAnswerShown) {
      state += ' correct';
    }
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDown}
      className={`pip ${state} ${final ? 'final' : ''} ${final && !isAnswerShown ? 'hide' : ''}`}
      aria-label={`Guess ${value}`}
    />
  );
}

function GuessIndicator({ count, isAnswerShown, goToGuess }) {
  return (
    <div className={`guess-indicator ${isAnswerShown ? 'game-over' : ''}`}>
      <Pip count={count} value={1} isAnswerShown={isAnswerShown} onClick={isAnswerShown ? () => goToGuess(1) : undefined} />
      <Pip count={count} value={2} isAnswerShown={isAnswerShown} onClick={isAnswerShown ? () => goToGuess(2) : undefined} />
      <Pip count={count} value={3} isAnswerShown={isAnswerShown} onClick={isAnswerShown ? () => goToGuess(3) : undefined} />
      <Pip count={count} value={4} isAnswerShown={isAnswerShown} onClick={isAnswerShown ? () => goToGuess(4) : undefined} />
      <Pip count={count} value={5} isAnswerShown={isAnswerShown} onClick={isAnswerShown ? () => goToGuess(5) : undefined} />
      <Pip count={count} value={6} isAnswerShown={isAnswerShown} onClick={isAnswerShown ? () => goToGuess(6) : undefined} />
      <Pip final count={7} value={7} isAnswerShown={isAnswerShown} onClick={() => goToGuess(7)} />
    </div>
  );
}

export default GuessIndicator;
