import React from 'react';
import { Modal } from 'react-bootstrap';

function HowToPlayModal({ lightMode, isOpen, onClose }) {
  return (
    <Modal show={isOpen} onHide={onClose} contentClassName={lightMode ? 'light-mode' : ''}>
      <Modal.Header closeButton>
        <Modal.Title>How to Play</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <h3>About</h3>
        <p>
          Paintle is the daily browser game, inspired by Wordle and Framed, for art lovers.
          Each day, a new painting is picked from a curated list and obscured through pixelation.
          Users have six guesses to try and identify the painting by searching for and entering a title, artist, and/or year.
          Incorrect guesses reveal a less pixelated version of the painting.
        </p>

        <h3>How to Play</h3>
        <ul>
          <li>Type a word (painting title, artist name, or year) into the input box and press the Guess button.</li>
          <li>Correct answers will display as green. Years within 100 years (50+/-) will display as yellow.</li>
          <li>To win, figure out the title of the painting.</li>
        </ul>

        <p>A new puzzle will be available every day at midnight UTC (8 pm EST).</p>

        <p>
          Follow Paintle on Twitter
          {' '}
          <a href="https://twitter.com/paintle_app" target="_blank" rel="noopener noreferrer" className="link-green">@paintle_app</a>
          {' '}
          and Instagram
          {' '}
          <a href="https://instagram.com/paintle_app" target="_blank" rel="noopener noreferrer" className="link-green">@paintle_app</a>
          .
        </p>

        <p>
          Created by
          {' '}
          <a href="https://zmcfarlen.com" target="_blank" rel="noopener noreferrer" className="link-green">Zach McFarlen</a>
        </p>

        <p>
          All rights go to the rightful owners—no copyright infringement is intended.
          Usage is purely for educational and entertainment purposes.
        </p>

        <h3>Does Paintle collect any user data?</h3>
        <p>
          In order to provide global performance stats, Paintle makes note of a user&apos;s performance on a given puzzle.
          The data collected is limited to the user&apos;s score and accuracy, and is anonymized.
        </p>
        <p>
          Paintle does not collect any information regarding a user&apos;s actual guesses,
          nor does Paintle collect any information that is personally identifiable.
        </p>
        <p>
          Paintle is neither willing nor able to de-anonymize this information.
          Paintle does not have any partner organizations to share this information with, and would
          not share this information with any partner organizations if they existed.
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default HowToPlayModal;
