import React from 'react';
import Guess from './Guess';

function GuessList({ guesses, answer, showAnswer, collapsed, onCollapseChange }) {
  return (
    <>
      {showAnswer && (
        <div
          className="collapse-guesses-btn"
          role="button"
          tabIndex={0}
          onClick={() => onCollapseChange()}
          onKeyDown={(e) => onCollapseChange(null, e)}
        >
          {!collapsed ? 'Hide My Guesses' : 'Show My Guesses'}
        </div>
      )}
      <div className={`guess-list ${collapsed ? 'collapse' : ''}`}>
        <Guess value={guesses[0] || {}} answer={answer} />
        <Guess value={guesses[1] || {}} answer={answer} />
        <Guess value={guesses[2] || {}} answer={answer} />
        <Guess value={guesses[3] || {}} answer={answer} />
        <Guess value={guesses[4] || {}} answer={answer} />
        <Guess value={guesses[5] || {}} answer={answer} />
      </div>
    </>
  );
}

export default GuessList;
