import React from 'react';
import { piecesMatch } from '../Utils';

function CorrectAnswerDisplay({ piece, finalGuess }) {
  const correct = finalGuess && piecesMatch(piece, finalGuess);
  return (
    <>
      <div className={`win-loss-msg ${correct ? 'win' : 'loss'}`}>
        <div>{correct ? 'You guessed correctly!' : 'You missed this one. Try again tomorrow!'}</div>
      </div>
      <div className="correct-answer">
        <div className="correct-piece-title">{piece.title}</div>
        <div className="correct-artist-year">
          <div className="correct-piece-artist">
            {piece.artist}
            ,
          </div>
          <div className="correct-piece-year">{piece.year}</div>
        </div>
      </div>
    </>
  );
}

export default CorrectAnswerDisplay;
