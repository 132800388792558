export function piecesMatch(a, b) {
  if (!a || !b) return false;

  if (a.title === b.title) {
    if (a.artist === b.artist) {
      if (a.year === b.year) {
        return true;
      }
    }
  }
  return false;
}

export default {
  piecesMatch,
};
