/* eslint-disable import/no-dynamic-require */
import React from 'react';

import { ImagePixelated } from 'react-pixelate';

function ImageDisplay({ piece, guessCount, showAnswer, showPieceAtGuess }) {
  const [size, setSize] = React.useState(null);

  React.useEffect(() => {
    const BLUR_MULTIPLIER = [1, 2, 3, 5, 7, 9, 100];

    const multiplier = showPieceAtGuess ? (BLUR_MULTIPLIER[showPieceAtGuess - 1]) : (BLUR_MULTIPLIER[guessCount - 1]);
    const pixelSize = Math.round(600 / (6 * multiplier));

    if (showPieceAtGuess !== 7) {
      setSize(pixelSize);
    }
  }, [guessCount, showPieceAtGuess, showAnswer]);

  return (
    <div className="image-display">
      {size && <ImagePixelated src={require(`../Images/${piece.number}_Small.jpg`)} pixelSize={size} />}
      {showAnswer && (showPieceAtGuess === 7) && <img src={require(`../Images/${piece.number}_Medium.jpg`)} alt={piece.title} />}
    </div>
  );
}

export default ImageDisplay;
