import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';

import Navigation from './Components/Navigation';
import ImageDisplay from './Components/ImageDisplay';
import GuessIndicator from './Components/GuessIndicator';
import AnswerForm from './Components/AnswerForm';
import GuessList from './Components/GuessList';
import ArchiveModal from './Components/Modals/ArchiveModal';
import HowToPlayModal from './Components/Modals/HowToPlayModal';
import SupportModal from './Components/Modals/SupportModal';
import OptionsModal, { defaultOptions } from './Components/Modals/OptionsModal';
import StatisticsModal from './Components/Modals/StatisticsModal';
import { ReactComponent as IconLoveTiny } from './Images/icon-love-tiny.svg';
import NextGameIndicator from './Components/NextGameIndicator';
import CorrectAnswerDisplay from './Components/CorrectAnswerDisplay';

import DailyData from './Data/Daily';
import GuessesData from './Data/Guesses';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles/App.css';
import { piecesMatch } from './Utils';

const header = 'Welcome to Paintle!';
const headerStyle = 'color: red; font-size: 24px; font-weight: bold;';
const body = 'These developer tools could probably help you find the answer to today\'s puzzle, '
+ 'but we encourage you to avoid doing this. It will make the game far less fun for you.';
const bodyStyle = 'font-size: 14px;';

// eslint-disable-next-line no-console
console.log(`%c${header}\n%c${body}`, headerStyle, bodyStyle);

const MAX_GUESSES = 6;
const FINAL_DAY = DailyData.length;

const buildSelectOptions = () => GuessesData.map((d) => ({
  value: d,
  label: `${d.title} - ${d.artist}, ${d.year}`
})).sort((a, b) => (a.value.title.toLowerCase() > b.value.title.toLowerCase() ? 1 : -1));
const selectOptions = buildSelectOptions();

function clearCurrentGame() {
  sessionStorage.setItem('game', JSON.stringify({ id: null, guesses: [], timestamp: Date.now() }));
}

function App() {
  // Modal States
  const [isArchiveOpen, setIsArchiveOpen] = useState(false);
  const [isHTPOpen, setIsHTPOpen] = useState(false);
  const [isSupportOpen, setIsSupportOpen] = useState(false);
  const [isStatsOpen, setIsStatsOpen] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  // Game States
  const [piece, setPiece] = useState(null);
  const [guesses, setGuesses] = useState([]);
  const [guessCount, setGuessCount] = useState(1);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showPieceAtGuess, setShowPieceAtGuess] = useState(null);
  const [lightMode, setLightMode] = useState(defaultOptions.lightMode);
  const [collapsedGuesses, setCollapsedGuesses] = useState(false);
  const [differenceInDays, setDifferenceInDays] = useState(null);

  const closeModals = useCallback(() => {
    setIsArchiveOpen(false);
    setIsHTPOpen(false);
    setIsStatsOpen(false);
    setIsOptionsOpen(false);
    setIsSupportOpen(false);
  }, []);

  const closeModalsAndOpen = useCallback((next) => {
    closeModals();
    next(true);
  }, [closeModals]);

  const onOpenArchive = useCallback(() => closeModalsAndOpen(setIsArchiveOpen), [closeModalsAndOpen]);
  const onOpenHTP = useCallback(() => closeModalsAndOpen(setIsHTPOpen), [closeModalsAndOpen]);
  const onOpenSupport = useCallback(() => closeModalsAndOpen(setIsSupportOpen), [closeModalsAndOpen]);
  const onOpenStats = useCallback(() => closeModalsAndOpen(setIsStatsOpen), [closeModalsAndOpen]);
  const onOpenOptions = useCallback(() => closeModalsAndOpen(setIsOptionsOpen), [closeModalsAndOpen]);

  const onLightModeChange = useCallback((value) => setLightMode(value), []);
  const onCollapseChange = useCallback((value, e) => {
    if (e && e.type === 'keydown' && e.key !== 'Enter') return;
    setCollapsedGuesses(value || !collapsedGuesses);
  }, [collapsedGuesses]);

  const saveEndStats = useCallback((win, on) => {
    let old = localStorage.getItem('stats');
    if (!old) {
      old = {
        played: 0,
        wonOn: [],
        streak: 0,
        longestStreak: 0,
      };
    } else {
      old = JSON.parse(old);
    }

    const data = {
      played: old.played + 1,
      wonOn: (win ? [...old.wonOn, on] : old.wonOn),
      streak: win ? old.streak + 1 : 0,
      longestStreak: win ? Math.max(old.longestStreak, old.streak + 1) : old.longestStreak,
    };

    const storedGames = localStorage.getItem('archive');
    const games = storedGames ? JSON.parse(storedGames) : {};
    const archiveData = {
      ...games,
      [piece.number]: {
        guesses: on,
        result: win ? 'correct' : 'incorrect',
      }
    };

    localStorage.setItem('archive', JSON.stringify(archiveData));
    localStorage.setItem('stats', JSON.stringify(data));
  }, [piece]);

  const onGuess = useCallback((guess) => {
    const newGuesses = [...guesses, guess];
    setGuesses(newGuesses);

    if (piecesMatch(guess, piece)) {
      setShowAnswer(true);
      setShowPieceAtGuess(MAX_GUESSES + 1);
      saveEndStats(true, guessCount);
      onCollapseChange(true);
      sessionStorage.setItem('game', JSON.stringify({ id: piece.number, guesses: newGuesses, timestamp: Date.now() }));
      return;
    }

    if (guessCount >= MAX_GUESSES) {
      setShowAnswer(true);
      saveEndStats(false);
    }

    setGuessCount(guessCount + 1);
    sessionStorage.setItem('game', JSON.stringify({ id: piece.number, guesses: newGuesses, timestamp: Date.now() }));
  }, [guesses, guessCount, piece, onCollapseChange, saveEndStats]);

  const goToGuess = useCallback((guess) => setShowPieceAtGuess(guess), []);

  useEffect(() => {
    // game engine startup
    const startDate = '2022-05-24';
    const start = moment(startDate).tz('America/New_York').startOf('day');
    const today = moment().tz('America/New_York');
    const oneDay = (1000 * 60 * 60 * 24);
    const timeCount = Math.max(today - start, oneDay);
    const dayCount = Math.floor(timeCount / oneDay);

    const queryParameters = new URLSearchParams(window.location.search);
    const dayRequested = queryParameters.get('day');
    const todaysId = dayCount.toString().padStart(4, '0');

    let gameId = todaysId; // Default to today's game
    if (dayRequested && +dayRequested < +todaysId) { // If the day requested is before today, use that day
      gameId = dayRequested.padStart(4, '0');
    } else if (dayCount > FINAL_DAY) { // If the day requested is after the last day, use random day
      gameId = (Math.floor(Math.random() * FINAL_DAY) + 1).toString().padStart(4, '0');
    }

    const todaysPiece = DailyData.find((d) => d.number === (gameId));

    // Load data from local storage
    const storedOptions = localStorage.getItem('options');
    const storedGame = sessionStorage.getItem('game');

    const options = storedOptions ? JSON.parse(storedOptions) : defaultOptions;
    let game = storedGame
      ? JSON.parse(storedGame)
      : { id: todaysPiece.number, guesses: [], timestamp: Date.now() };

    const storedGameIsOld = moment(game.timestamp).tz('America/New_York') < moment().tz('America/New_York').startOf('day');
    let showGameEnd = false;

    if (storedGameIsOld || game.id !== todaysPiece.number || dayCount > FINAL_DAY) {
      clearCurrentGame();
      game = { id: todaysPiece.number, guesses: [], timestamp: Date.now() };
    } else {
      const lastGuess = game.guesses[game.guesses.length - 1];
      if (game.guesses.length >= MAX_GUESSES || (lastGuess && piecesMatch(lastGuess, todaysPiece))) {
        showGameEnd = true;
      }
    }

    setLightMode(options.lightMode);
    setPiece(todaysPiece);
    setShowAnswer(showGameEnd);
    setShowPieceAtGuess(showGameEnd ? MAX_GUESSES + 1 : null);
    setCollapsedGuesses(showGameEnd);
    setGuessCount(
      game.guesses.length > 0
        ? (game.guesses.length + (piecesMatch(game.guesses[game.guesses.length - 1], todaysPiece) ? 0 : 1))
        : 1
    );
    setGuesses(game.guesses);
    setDifferenceInDays(dayCount);
    sessionStorage.setItem('game', JSON.stringify({ id: todaysPiece.number, guesses: game.guesses, timestamp: Date.now() }));
  }, []);

  if (!piece) {
    // Loading data still
    return null;
  }

  return (
    <div className={`App ${lightMode ? 'light-mode' : ''} ${showAnswer ? 'game-over' : ''}`}>
      <header className="App-header">
        <Navigation
          onOpenArchive={onOpenArchive}
          onOpenHTP={onOpenHTP}
          onOpenSupport={onOpenSupport}
          onOpenStats={onOpenStats}
          onOpenOptions={onOpenOptions}
        />
      </header>
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ImageDisplay
                piece={piece}
                guessCount={guessCount}
                showAnswer={showAnswer}
                showPieceAtGuess={showPieceAtGuess}
              />
              <GuessIndicator count={guessCount} isAnswerShown={showAnswer} goToGuess={goToGuess} />
              {showAnswer && <CorrectAnswerDisplay piece={piece} finalGuess={guesses[guesses.length - 1]} />}
              {!showAnswer
                ? <AnswerForm onSubmit={onGuess} allPieces={selectOptions} />
                : <NextGameIndicator guesses={guesses} answer={piece} />}
              <GuessList
                guesses={guesses}
                answer={piece}
                showAnswer={showAnswer}
                collapsed={collapsedGuesses}
                onCollapseChange={onCollapseChange}
              />
            </div>
          </div>
        </div>
        <div className="love-paintle">
          <IconLoveTiny />
          <span className="paintle-text">Paintle?</span>
          <a href="https://ko-fi.com/paintle" target="_blank" rel="noopener noreferrer">Buy me a coffee</a>
        </div>
      </main>
      <ArchiveModal lightMode={lightMode} isOpen={isArchiveOpen} onClose={closeModals} differenceInDays={differenceInDays} />
      <HowToPlayModal lightMode={lightMode} isOpen={isHTPOpen} onClose={closeModals} />
      <SupportModal lightMode={lightMode} isOpen={isSupportOpen} onClose={closeModals} />
      <StatisticsModal lightMode={lightMode} isOpen={isStatsOpen} onClose={closeModals} />
      <OptionsModal lightMode={lightMode} isOpen={isOptionsOpen} onClose={closeModals} onLightModeChange={onLightModeChange} />
    </div>
  );
}

export default App;
