import React from 'react';
import moment from 'moment-timezone';
import { ReactComponent as BtnIconTwitter } from '../Images/btn-icon-twitter.svg';
import { ReactComponent as BtnIconCopy } from '../Images/btn-icon-copy.svg';
import { piecesMatch } from '../Utils';

function NextGameIndicator({ guesses, answer }) {
  const [heartBeat, setHeartBeat] = React.useState(false);
  const [copied, setCopied] = React.useState(false);

  function getNextMidnightTime() {
    const midnight = moment().tz('America/New_York').endOf('day');
    return midnight;
  }

  function getTimeToMidnightFromNowInMS() {
    return getNextMidnightTime() - moment().tz('America/New_York');
  }

  function getTimeToMidnightFromNowInMinutes() {
    return Math.floor(getTimeToMidnightFromNowInMS() / 1000 / 60);
  }

  function getTimeToMidnightFromNowInHours() {
    return Math.floor(getTimeToMidnightFromNowInMS() / 1000 / 60 / 60);
  }

  function getTimeLeft() {
    const hoursLeft = getTimeToMidnightFromNowInHours();
    const minutesLeft = getTimeToMidnightFromNowInMinutes() - (hoursLeft * 60);
    let timeLeft = `${minutesLeft} minutes`;
    if (hoursLeft > 0) {
      timeLeft = `${hoursLeft} hours and ${timeLeft}`;
    }
    return timeLeft;
  }

  function buildShareText() {
    const indexes = Array.from({ length: 5 - 0 + 1 }, (_, i) => 0 + i);
    const shareText = indexes.reduce((acc, index) => {
      if (guesses[index]) {
        return `${acc} ${piecesMatch(guesses[index], answer) ? '🟩' : '🟥'}`;
      }
      return `${acc} ⬛`;
    }, `PAINTLE #${answer.number}`);
    return `${shareText}🖼️\nhttps://paintle.app`;
  }

  function copyToClipboard(e) {
    if (e.type === 'keydown' && e.key !== 'Enter') return;

    navigator.clipboard.writeText(buildShareText());
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }

  function socialShare(e) {
    if (e.type === 'keydown' && e.key !== 'Enter') return;

    const left = (window.screen.width / 2) - (500 / 2);
    const top = (window.screen.height / 2) - (600 / 2);

    window.open(
      `https://twitter.com/intent/tweet/?text=${encodeURIComponent(buildShareText())}`,
      '',
      `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=${500},height=${600},top=${top},left=${left}`
    );
  }

  React.useEffect(() => {
    setTimeout(() => {
      setHeartBeat(!heartBeat);
    }, 1000 * 10); // 10 seconds
  }, [heartBeat]);

  return (
    <>
      <div className="paintle-share" id="paintle-share">
        <p>Share your results</p>
        <div className="paintle-share-btns">
          <div role="button" tabIndex={0} onKeyDown={socialShare} onClick={socialShare} className="btn btn-share twitter">
            <div className="btn-content">
              <BtnIconTwitter />
              <div>Share to Twitter</div>
            </div>
          </div>

          <div
            className="btn btn-share"
            id="btn-copy-results"
            role="button"
            tabIndex={0}
            onKeyDown={copyToClipboard}
            onClick={copyToClipboard}
          >
            <div className="btn-content">
              <BtnIconCopy />
              <div className="relative">
                <div className={`text-copy-results ${copied ? 'text-fade' : ''}`}>Copy Results</div>
                <div className={`text-copied ${copied ? '' : 'text-fade'}`}>Copied!</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="next-game-indicator">
        <p>
          Next game starts in
          {' '}
          <strong>{heartBeat ? getTimeLeft() : getTimeLeft()}</strong>
        </p>
      </div>
    </>
  );
}

export default NextGameIndicator;
