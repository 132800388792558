import React from 'react';
import { Modal } from 'react-bootstrap';

function StatisticsModal({ lightMode, isOpen, onClose }) {
  const old = JSON.parse(localStorage.getItem('stats'));
  const [stats] = React.useState(old || {
    played: 0,
    wonOn: [],
    streak: 0,
    longestStreak: 0,
  });

  const distribution = stats.wonOn.reduce((acc, cur) => {
    acc[cur - 1] = (acc[cur - 1] || 0) + 1;
    return acc;
  }, [0, 0, 0, 0, 0, 0]);
  const biggestDistrubution = Math.max(...distribution);

  return (
    <Modal show={isOpen} onHide={onClose} contentClassName={lightMode ? 'light-mode' : ''}>
      <Modal.Header closeButton>
        <Modal.Title>Stats</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="statistics">
          <div className="stat-headers">
            <div className="stat-header">
              <div className="stat-header-title">Played</div>
              <div className="stat-header-value">{stats.played}</div>
            </div>
            <div className="stat-header">
              <div className="stat-header-title">Win %</div>
              <div className="stat-header-value">
                {stats.played === 0 ? 0 : `${((stats.wonOn.length / stats.played).toPrecision(2) * 100)}%`}
              </div>
            </div>
            <div className="stat-header">
              <div className="stat-header-title">Streak</div>
              <div className="stat-header-value">{stats.streak}</div>
            </div>
            <div className="stat-header">
              <div className="stat-header-title">Longest Streak</div>
              <div className="stat-header-value">{stats.longestStreak}</div>
            </div>
          </div>
          <div className="stat-graph">
            <div className="stat-graph-header">How many guesses does it take you?</div>
            <div className="stat-graph-bar">
              <div className="stat-graph-bar-label">1 Guess</div>
              <div className="stat-value-container">
                <div className="stat-graph-bar-progress-container">
                  <div
                    className="stat-graph-bar-progress"
                    style={{ width: `${stats.wonOn.length === 0 ? 0 : (distribution[0] / biggestDistrubution) * 100}%` }}
                  />
                </div>
                <div className="stat-graph-bar-value">{distribution[0]}</div>
              </div>
            </div>

            <div className="stat-graph-bar">
              <div className="stat-graph-bar-label">2 Guesses</div>
              <div className="stat-value-container">
                <div className="stat-graph-bar-progress-container">
                  <div
                    className="stat-graph-bar-progress"
                    style={{ width: `${stats.wonOn.length === 0 ? 0 : (distribution[1] / biggestDistrubution) * 100}%` }}
                  />
                </div>
                <div className="stat-graph-bar-value">{distribution[1]}</div>
              </div>
            </div>

            <div className="stat-graph-bar">
              <div className="stat-graph-bar-label">3 Guesses</div>
              <div className="stat-value-container">
                <div className="stat-graph-bar-progress-container">
                  <div
                    className="stat-graph-bar-progress"
                    style={{ width: `${stats.wonOn.length === 0 ? 0 : (distribution[2] / biggestDistrubution) * 100}%` }}
                  />
                </div>
                <div className="stat-graph-bar-value">{distribution[2]}</div>
              </div>
            </div>

            <div className="stat-graph-bar">
              <div className="stat-graph-bar-label">4 Guesses</div>
              <div className="stat-value-container">
                <div className="stat-graph-bar-progress-container">
                  <div
                    className="stat-graph-bar-progress"
                    style={{ width: `${stats.wonOn.length === 0 ? 0 : (distribution[3] / biggestDistrubution) * 100}%` }}
                  />
                </div>
                <div className="stat-graph-bar-value">{distribution[3]}</div>
              </div>
            </div>

            <div className="stat-graph-bar">
              <div className="stat-graph-bar-label">5 Guesses</div>
              <div className="stat-value-container">
                <div className="stat-graph-bar-progress-container">
                  <div
                    className="stat-graph-bar-progress"
                    style={{ width: `${stats.wonOn.length === 0 ? 0 : (distribution[4] / biggestDistrubution) * 100}%` }}
                  />
                </div>
                <div className="stat-graph-bar-value">{distribution[4]}</div>
              </div>
            </div>

            <div className="stat-graph-bar">
              <div className="stat-graph-bar-label">6 Guesses</div>
              <div className="stat-value-container">
                <div className="stat-graph-bar-progress-container">
                  <div
                    className="stat-graph-bar-progress"
                    style={{ width: `${stats.wonOn.length === 0 ? 0 : (distribution[5] / biggestDistrubution) * 100}%` }}
                  />
                </div>
                <div className="stat-graph-bar-value">{distribution[5]}</div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default StatisticsModal;
