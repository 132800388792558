import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

function buildDayArray(days) {
  const perChunk = 7; // items per chunk

  return days.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);
    const newResults = [...resultArray];

    if (!newResults[chunkIndex]) {
      newResults[chunkIndex] = []; // start a new chunk
    }

    newResults[chunkIndex].push(item);

    return newResults;
  }, []);
}

function ArchiveModal({ differenceInDays, lightMode, isOpen, onClose }) {
  const [days, setDays] = useState([]);
  const [oldGames, setOldGames] = useState({});

  const getArchive = useCallback((day) => day && oldGames[day.padStart(4, '0')], [oldGames]);

  useEffect(() => {
    const dayCount = parseInt(differenceInDays, 10);

    const dayList = [];
    for (let i = dayCount; i > 0; i--) {
      const dayId = i.toString();
      dayList.push(dayId);
    }

    setDays(buildDayArray(dayList));
  }, [differenceInDays]);

  useEffect(() => {
    const storedGames = localStorage.getItem('archive');
    const games = storedGames ? JSON.parse(storedGames) : {};
    setOldGames(games);
  }, []);

  return (
    <Modal show={isOpen} onHide={onClose} contentClassName={lightMode ? 'light-mode' : ''}>
      <Modal.Header closeButton>
        <Modal.Title>Archive</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="archive-description">
          Play any day from our archive. Days you have played will show if you guessed
          {' '}
          <span className="correct">correctly</span>
          {' '}
          or
          {' '}
          <span className="incorrect">incorrectly</span>
          .
        </p>
        <div className="chunk">
          {days.map((chunk) => (
            chunk.map((day) => (
              <a
                className={getArchive(day) && `completed-${getArchive(day) ? getArchive(day).result : ''}`}
                key={day}
                href={`?day=${day}`}
              >
                {day}
              </a>
            ))
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ArchiveModal;
