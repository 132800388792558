import React from 'react';
import Select from 'react-select';
import { ReactComponent as IconSearch } from '../Images/icon-search.svg';

function CustomOption({ innerProps, innerRef, value }) {
  return (
    <div
      ref={innerRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...innerProps}
      className="artwork-option"
    >
      <div className="option-title">{value.title}</div>
      <div className="option-artist-year">
        <div className="option-artist">{value.artist}</div>
        <div className="option-year">{value.year}</div>
      </div>
    </div>
  );
}

function AnswerForm({ onSubmit, allPieces }) {
  const [value, setValue] = React.useState(null);

  const _onSubmit = () => {
    onSubmit(value.value);
    setValue(null);
  };

  return (
    <div className="answer-form">
      <div className="guess-input-wrapper">
        <IconSearch />
        <Select
          options={allPieces}
          components={{ Option: CustomOption }}
          placeholder="Guess the artwork"
          className="guess-input"
          value={value}
          onChange={(v) => setValue(v)}
          theme={(theme) => ({
            ...theme,
            borderRadius: 6,
            borderColor: '#0DBC57',
            colors: {
              ...theme.colors,
              primary25: '#c7d0ff',
              primary50: '#B3DBB4',
              primary: '#4c60c7',
            }
          })}
        />
      </div>
      <input disabled={value === null} value="Guess" className="guess-submit" onClick={_onSubmit} type="submit" />
    </div>
  );
}

export default AnswerForm;
